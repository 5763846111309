import React, { useCallback, useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { exportPriceStockData, translateProductAutomatically } from "../../../actions/pim";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { IMarketplace, Role } from "../../../interfaces";
import { Modal } from "../../ui/modal/Modal";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ICountryM } from "../../routes/country-management/CountryManagement";
import useCountries from "../../../hooks/useCountries";
import useSanitizedMarketplaces from "../../../hooks/useSanitizedMarketplaces";
import { toggleProductStatusInBulk } from "../../../actions/v2/product";

export const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    color: "#fff",
    maxWidth: 200,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
    background: "#454545",
    zIndex: 100000000000,
  },
}));

interface Props {
  products: any;
  selectedProducts: string[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<string[]>>;
  batch: boolean;
  setBatch: React.Dispatch<React.SetStateAction<boolean>>;
  productsFrom: string;
  setOpenWindow?: React.Dispatch<React.SetStateAction<boolean>>;
  allSelectedFlag?: boolean;
  setAllSelectedFlag?: React.Dispatch<React.SetStateAction<boolean>>;
}

const BatchDropDown: React.FC<Props> = ({
  products,
  selectedProducts,
  setSelectedProducts,
  batch,
  setBatch,
  productsFrom,
  setOpenWindow,
  allSelectedFlag,
  setAllSelectedFlag,
}) => {
  const { location } = useHistory();
  const countries = useCountries();
  const [toggledAllMplc, setToggledAllMplc] = useState<Record<string, boolean>>(
    {}
  );

  const [modal, setModal] = useState<boolean>(false);
  const [modal1, setModal1] = useState<boolean>(false);
  const [modal2, setModal2] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const { data: marketplaces } = useSanitizedMarketplaces();

  useEffect(() => {
    setSelectedProducts([]);
    setAllSelectedFlag?.(false);
    setModal(false);
    setModal1(false);
    setModal2(false);
    setBatch(false);
  }, [location.pathname]);

  const handleClick =
    (id: string, upd: boolean) => (event: React.MouseEvent) => {
      setToggledAllMplc((prev: any) => ({
        ...prev,
        [id]: toggledAllMplc[id] === upd ? null : upd,
      }));
    };

  const handleChangeAll = useCallback(
    (status: boolean) => (event: React.MouseEvent) => {
      setToggledAllMplc(
        (marketplaces || []).reduce((prev, curr) => {
          prev[curr._id] = status;
          return prev;
        }, {} as Record<string, boolean>)
      );
    },
    [marketplaces]
  );

  const onSaveHandler = async (e: React.MouseEvent) => {
    e.preventDefault();
    setLoading(true);

    try {
      await toggleProductStatusInBulk(
        toggledAllMplc,
        allSelectedFlag ? undefined : selectedProducts
      );
      toast.success("Products successfully saved!");

      setModal1(false);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong! Please, reload the page");
    }

    setLoading(false);
  };

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
    setToggledAllMplc({});
  };
  const onModalClose1 = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal1(false);
  };
  const onModalClose2 = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal2(false);
  };

  return (
    <>
      <AnimatePresence>
        {!!selectedProducts.length && (
          <div
            style={{ position: "sticky", top: "60px", zIndex: "101" }}
            className="width100 animation-block"
          >
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              style={{ overflow: "hidden" }}
              transition={{ duration: 0.5 }}
            >
              <div
                style={{ display: batch ? "none" : "flex" }}
                className="inv-dropdown-cont justify-between"
              >
                <div className="in-row align-center">
                  {batch ? (
                    <div className="inv-dropdown-text">
                      <span>Select Products to create on Marketplaces.</span>
                      <b>{selectedProducts.length} selected products.</b>
                    </div>
                  ) : (
                    <div className="inv-dropdown-text bold">
                      {selectedProducts.length} selected products.
                    </div>
                  )}
                  <div
                    style={{ color: "#55718A", fontWeight: "400" }}
                    className="pointer fw1 ft1 ml1"
                    onClick={() => {
                      setSelectedProducts(
                        products?.data?.map((x: any) => x.sku) || []
                      );
                      setAllSelectedFlag?.(true);
                    }}
                  >
                    Select all
                  </div>
                  <div className="pointer fw1 ft1 ml1">|</div>
                  <div
                    style={{ color: "#55718A", fontWeight: "400" }}
                    className="pointer fw1 ft1 ml1"
                    onClick={() => {
                      setAllSelectedFlag?.(false);
                      setSelectedProducts([]);
                    }}
                  >
                    Clear
                  </div>
                </div>
                <div className="in-row">
                  {productsFrom === "inventory" ? (
                    <>
                      <div
                        className="req-popup-button in-row align-center ml3"
                        onClick={() =>
                          exportPriceStockData(
                            allSelectedFlag ? [] : selectedProducts
                          ).then(() =>
                            toast.success(
                              "Success! Check your mailbox, it should be there in seconds."
                            )
                          )
                        }
                      >
                        <div>Export</div>
                      </div>
                    </>
                  ) : (
                    <>
                      <RoleRequired role={Role.admin} strict>
                        <div
                          className="req-popup-button in-row align-center"
                          onClick={() =>
                            translateProductAutomatically(
                              allSelectedFlag ? [] : selectedProducts
                            ).then(() =>
                              toast.success(
                                "Success! Check your mailbox, it should be there in seconds."
                              )
                            )
                          }
                        >
                          <div>Translate</div>
                        </div>

                        <div onClick={() => setBatch(true)} className="req-popup-button in-row align-center ml3">
                          <div>Export</div>
                        </div>
                      </RoleRequired>

                      <div
                        className="req-popup-button in-row align-center ml3"
                        onClick={() => {
                          setModal(true);
                        }}
                      >
                        <div>Manage Marketplaces</div>
                      </div>

                      {/* <RoleRequired role={Role.user} strict>
                        <div
                          className="req-popup-button in-row align-center ml3"
                          onClick={() => {
                            setModal2(true);
                          }}
                        >
                          <div>Remove from my catalog</div>
                        </div>
                      </RoleRequired> */}
                    </>
                  )}
                  {/* <RoleRequired role={Role.admin}>
										{productsFrom === "inventory" && (
											<div
												className="req-popup-button in-row align-center ml3"
												onClick={() => setOpenWindow?.(true)}
											>
												<div>Active/Deactive on Platforms</div>
											</div>
										)}
										<div className="req-popup-button in-row align-center ml3">
											<div onClick={() => setBatch(true)}>Add to batch</div>
										</div>
									</RoleRequired> */}
                </div>
              </div>
              <div
                style={{ display: batch ? "flex" : "none" }}
                className="inv-dropdown-cont justify-between"
              >
                <div className="in-row align-center">
                  <div className="inv-dropdown-text">
                    Select products to create on Marketplaces.{" "}
                    <b>{selectedProducts.length} selected products.</b>
                  </div>
                  <div
                    className="pointer underline fw1 ft1 ml1"
                    onClick={() => setSelectedProducts([])}
                  >
                    Clear
                  </div>
                </div>
                <div className="in-row align-center">
                  <div
                    className="inv-dropdown-cancel"
                    onClick={() => setBatch(false)}
                  >
                    Cancel
                  </div>
                  <div className="in-row align-center ml3">
                    <NavLink
                      className="inv-dropdown-upload"
                      to="/batch-products"
                    >
                      <img src="/icons/upload-batch.svg" alt="" />
                      Export to Marketplaces
                    </NavLink>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <div
            style={{ maxWidth: "560px", width: "560px" }}
            className="table-modal-form-cont p4"
          >
            <div className="table-modal-title-box m0">
              <div className="import-title">
                Manage {selectedProducts.length} selected product platforms:
                <HtmlTooltip
                  className="pointer"
                  title="Disabled products maintain zero stock for the platforms, that is, it will not be possible to sell disabled products"
                  placement="bottom-start"
                >
                  <img className="ml1" src="/icons/tooltip-black.svg" alt="" />
                </HtmlTooltip>
              </div>
            </div>
            <div className="table-modal-border"></div>
            {/* <div className="width100 in-row align-center">
							{loadMarketplaces ? (
								<CircularPreloader />
							) : (
								<div className="flex justify-between mb3">
									<FilterSelect
										onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
											setCurrentConfig((conf: IPaginatedRequest) => ({
												...conf,
												country: e.target.value,
											}))
										}
										value={currentConfig?.country}
										data={[
											"All countries",
											..._.uniq(_.map(countries, (item: any) => item.name)),
										]}
									/>
									<FilterSelect
										onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
											setCurrentConfig((conf: IPaginatedRequest) => ({
												...conf,
												platformName: e.target.value,
											}))
										}
										value={currentConfig?.platformName}
										data={[
											"All platforms",
											..._.uniq(_.map(availableZeoosNames, (x: string) => x)),
										]}
									/>
								</div>
							)}
						</div> */}
            <div>
              <table
                style={{ fontSize: "16px" }}
                className="mapping-modal-table gestao-title-text"
              >
                <thead
                  style={{ backgroundColor: "#5A5A5A; !important" }}
                  className="in-column justify-center"
                >
                  <tr
                    style={{
                      width: "100%",
                      borderBottom: "1px solid #e1e1e1",
                      margin: "auto !important",
                      background: "#F2F2F2",
                    }}
                    className="mapping-modal-results-title justify-between m0 pt2 pb2"
                  >
                    <th className="modal-en-dis-table-platforms">
                      {marketplaces?.length} available platforms
                    </th>
                    <th className="modal-en-dis-table-status">Status</th>
                  </tr>
                </thead>

                <tbody
                  style={{ maxHeight: "300px", overflow: "auto" }}
                  className="in-column justify-start"
                >
                  {marketplaces?.map(
                    (marketplace: IMarketplace, index: number) => (
                      <tr
                        key={index}
                        style={{
                          width: "100%",
                          borderBottom: "1px solid #e1e1e1",
                          margin: "auto !important",
                        }}
                        className="mapping-modal-result-box justify-between pt1 pb1 pointer platform-list"
                      >
                        <td className="modal-en-dis-table-platforms">
                          <img
                            className="mr2"
                            width="32px"
                            src={
                              countries?.find(
                                (country: ICountryM) =>
                                  country.name === marketplace.country
                              )?.iconUrl
                            }
                            alt=""
                          />
                          {marketplace.zeoosName}
                        </td>
                        <td className="modal-en-dis-table-status">
                          <div className="in-row">
                            <div
                              className="in-row mr5 pointer"
                              onClick={handleClick(marketplace._id, false)}
                            >
                              <img
                                className="mr1"
                                width="20px"
                                height="20px"
                                src={`${toggledAllMplc[marketplace._id] === false
                                  ? "/icons/reject-icon-active.svg"
                                  : "/icons/reject-icon.svg"
                                  }`}
                                alt=""
                              />
                              <div
                                className={`bold ${toggledAllMplc[marketplace._id] === false
                                  ? "red"
                                  : "grey"
                                  }`}
                              >
                                {toggledAllMplc[marketplace._id] === false
                                  ? "Disabled"
                                  : "Disable"}
                              </div>
                            </div>
                            <div
                              className="in-row pointer"
                              onClick={handleClick(marketplace._id, true)}
                            >
                              <img
                                className="mr1"
                                width="20px"
                                height="20px"
                                src={`${toggledAllMplc[marketplace._id] === true
                                  ? "/icons/accept-icon-active.svg"
                                  : "/icons/accept-icon.svg"
                                  }`}
                                alt=""
                              />
                              <div
                                className={`bold ${toggledAllMplc[marketplace._id] === true
                                  ? "green"
                                  : "grey"
                                  }`}
                              >
                                {toggledAllMplc[marketplace._id] === true
                                  ? "Enabled"
                                  : "Enable"}
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
            <div className="table-modal-border"></div>
            <div
              style={{ marginBottom: "0" }}
              className="table-modal-form-button-box width100"
            >
              <button
                className="download-button pointer download-button-text"
                onClick={onModalClose}
              >
                Cancel
              </button>
              <div className="in-row justify-end align-center">
                <button
                  className="download-button pointer download-button-text"
                  onClick={handleChangeAll(true)}
                >
                  Enable all
                </button>
                <button
                  className="download-button pointer ml1 mr1 download-button-text"
                  onClick={handleChangeAll(false)}
                >
                  Disable all
                </button>
                <button
                  onClick={() => setModal1(true)}
                  className="table-modal-form-button initial"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modal1 && (
        <Modal onModalClose={onModalClose1} isOpened={modal1}>
          <div
            style={{ maxWidth: "600px" }}
            className="table-modal-form-cont p4"
          >
            <div className="updateForm__container">
              <div className="vendor-mapping-modal-text">
                <strong>
                  Are you sure you want to save all changes for the selected
                  products?
                </strong>
              </div>
              <div className="table-modal-border"></div>
              <div className="table-modal-form-button-box width100 m0">
                <button
                  className="sel-modal-form-button-cancel"
                  onClick={onModalClose1}
                >
                  Cancel
                </button>
                <button
                  className="table-modal-form-button initial pointer"
                  onClick={onSaveHandler}
                  disabled={loading}
                >
                  Yes, save all
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      {modal2 && (
        <Modal onModalClose={onModalClose2} isOpened={modal2}>
          <div
            style={{ maxWidth: "450px" }}
            className="table-modal-form-cont p4"
          >
            <div className="updateForm__container">
              <div className="vendor-mapping-modal-text">
                Sorry, you cannot remove from your catalog:
                <br />
                Product name X
                <br />
                Product name Y
                <br />
                <br />
                <strong>
                  Do you want to remove the other selected products?
                </strong>
              </div>
              <div className="table-modal-border"></div>
              <div className="table-modal-form-button-box width100 m0">
                <button
                  className="sel-modal-form-button-cancel"
                  onClick={onModalClose2}
                >
                  No, keep all
                </button>
                <div className="table-modal-form-button initial pointer">
                  YES, remove the others
                </div>
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default BatchDropDown;
