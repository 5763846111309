import React, { useCallback, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import { queryClient } from "../../../App";
import { releaseSeller } from "../../../../actions/onboardingSeller";

interface Props {
  queryKey: string;
  sellerId: string;
}

const ReleaseModal: React.FC<Props> = ({ queryKey, sellerId }) => {
  const [modal, setModal] = useState<boolean>(false);

  const refetchPlansConfig = {
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
			queryClient.invalidateQueries("/notifications");
      toast.success(
        "Pricing plan has been selected. Please wait confirmation email"
      );
    },
    onError: () => {
      toast.error("Something went wrong!");
    },
  };

  const createMutation = useMutation(
    () => releaseSeller(sellerId),
    refetchPlansConfig
  );

  const handleClick = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();

      await createMutation.mutateAsync();
      setModal(false);
    },
    [setModal, createMutation]
  );


  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <>
      <button onClick={() => {
        setModal(true);
      }} className="sel-set-actions-modal-check">
        <img className="mr1" src="/icons/sel-green-check.svg" alt="" />
        Release
      </button>

      {modal && (
        <Modal onModalClose={onModalClose} isOpened={modal}>
          <>
            <div style={{ maxWidth: "420px" }} className="table-modal-form-cont p4">
              <div className="updateForm__container">
                <div className="vendor-mapping-modal-text">
                  Closing this process will release product management to the seller.
                  <br />
                  <br />
                  <strong>Are you sure you want to end the onboarding process for seller seller name</strong>?
                </div>
                <div className="table-modal-border"></div>
                <div className="table-modal-form-button-box width100 m0">
                  <button
                    className="sel-modal-form-button-cancel"
                    onClick={onModalClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="table-modal-form-button"
                    onClick={handleClick}
                  >
                    RELEASE
                  </button>
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};

export default ReleaseModal;
