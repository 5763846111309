import React, { useCallback, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import TabContext from "@mui/lab/TabContext";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import TabList from "@mui/lab/TabList";
import useUser from "../../../../hooks/useUser";
import { IProductGestao, Role } from "../../../../interfaces";
import {
	allMarketplaces,
	getSellersMarketplace,
	integratedMarketplaces,
	isSelling,
	rankingZeoosWinner,
	stockTotal,
} from "./TableGestao";
import { convertPrice } from "./UpdateGestao";
import TooltipIcon from "../../logistic/TooltipIcon";
import { HtmlTooltip } from "../../../ui/batch-dropdown/BatchDropDown";
import { ModalOffer, OfferGroup, OfferProduct } from "types/offer";
import { usePlatformOfferStat } from "../../../../actions/v2/offer";
import useSanitizedMarketplaces from "../../../../hooks/useSanitizedMarketplaces";

interface Props {
	product: OfferGroup;
	// setModal1: (data: boolean) => void;
	// setProduct: (item: any) => void;
	// setMarketplace: (marketplace: any) => void;
	// setSelectedWinner: (winner: any) => void;
	// featured: number;
	// setSelectedSeller: (seller: number) => void;
	// setModalUpdate: (data: boolean) => void;
	openPriceUpdateModal?: (offer: ModalOffer) => void;
	close: () => void;
}

const GestaoGridModal: React.FC<Props> = ({
	product,
	openPriceUpdateModal,
	close
}) => {
	const { user } = useUser();
	const marketplaces = useSanitizedMarketplaces();
	// const [modal, setModal] = useState<boolean>(false);
	const [value, setValue] = useState<"integrated" | "all">("integrated");

	const { data } = usePlatformOfferStat(product.sku, value);

	// const isWortenUser = useMemo(
	// 	() => user?.group?._id === "630c87437a3c0a000381f941",
	// 	[user]
	// );

	const onModalClose = useCallback((e: React.MouseEvent) => {
		e.stopPropagation();
		close();
	}, []);

	const handleChange = useCallback((event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue as any);
	}, []);

	// const buyboxMarketplaces = (item: IProductGestao) => {
	// 	let list: any[] = [];
	// 	Object.keys(item?.marketplaces || {}).forEach((zeoosName: string) => {
	// 		if (item?.marketplaces?.[zeoosName]?.buybox?.ranking === 1) {
	// 			list.push({
	// 				...item?.marketplaces?.[zeoosName],
	// 				zeoosName,
	// 			});
	// 		}
	// 	});
	// 	return list;
	// };

	const isAdmin = user.role === Role.admin;
  const isHub = user.seller?.accountType === "Hub";
	// const seller = isAdmin ? undefined : user.seller?.id;
	// const integrated = integratedMarketplaces(product);
	// const buybox = buyboxMarketplaces(product);
	// const all = allMarketplaces(product);

	return (
		<Modal onModalClose={onModalClose} isOpened>
			<>
				<div
					style={{ maxWidth: "640px", minWidth: "480px" }}
					className="table-modal-form-cont p4"
				>
					<div className="table-modal-title-box width100 pb1 m0">
						<div className="inv-title-modal1-text">{product.name}</div>
					</div>
					<div className="in-row align-center justify-start width100">
						<div
							style={{ width: "122px", height: "120px" }}
							className="int-set-match-logo"
						>
							<img
								width="120px"
								height="120px"
								className="pb1"
								src={product.image}
								alt=""
							/>
						</div>
						<div className="ml4">
							<div className="in-row align-center ustify-start width100 gestao-title-text mb1">
								<b className="pr2">SKU: </b>
								{product.sku}
							</div>
							<div className="in-row align-center ustify-start width100 gestao-title-text mb1">
								<b className="pr2">EAN: </b>
								<div className="gestao-ean-text">{product.ean}</div>
							</div>
							{/* <div className="in-row align-center ustify-start width100 gestao-title-text mb1">
										<b className="pr2">Stock: </b>
										<div className="gestao-ean-text">{product.stock}</div>
									</div> */}
						</div>
					</div>

					<TabContext value={value}>
						<TabList
							className="inv-mod-tabs"
							style={{ borderBottom: "1px solid #E1E1E1" }}
							onChange={handleChange}
							aria-label="lab API tabs example"
						>
							<Tab
								style={{ maxWidth: "100px !important" }}
								className="ges-tab-sync ges-tab-sync-active ml1"
								label={`Integrated (${product.integratedPlatformsCount})`}
								value="integrated"
							/>
							{/* <Tab className="ges-tab-sync ges-tab-sync-active ml1"
                                    label={`Buybox (${buybox.length})`} value="1" /> */}
							<Tab
								className="ges-tab-sync ges-tab-sync-active ml1"
								// label={`All (${all.length})`}
								label={`All (${marketplaces.data?.length})`}
								value="all"
							/>
						</TabList>
						<TabPanel className="m0 p0" value="integrated">
							<table
								style={{ fontSize: "14px" }}
								className="mapping-modal-table gestao-title-text"
							>
								<thead>
									<tr className="mapping-modal-results-title justify-between m0 pt2 pb2">
										<th
											style={{ flex: "0 1 35%", width: "120px" }}
											className="inv-modal1-table-seller"
										>
											Platform
										</th>
										{isAdmin && (
											<th className="inv-modal1-table-column">Sellers</th>
										)}
										<th
											style={{ flex: "0 1 30%", width: "120px" }}
											className="inv-modal1-table-column"
										>
											Price
										</th>
										{!isAdmin && !isHub && (
											<th className="inv-modal1-table-column">Ranking</th>
										)}
									</tr>
								</thead>
								<tbody>
									{/* {integrated.map((m: any, index: number) => { */}
									{data?.map((stat, index: number) => {
										// const winner = rankingZeoosWinner(m, seller);
										// const opacity = !isSelling(m) ? "opacity5" : "";
										const opacity = stat.price ? "" : "opacity5";
										const deactivated = !stat.active;
										return (
											<tr
												className="mapping-modal-result-box justify-between pt1 pb1 pointer mass-import-option"
												key={index}
												onClick={() => {
													// TODO:

													openPriceUpdateModal?.({
														ean: product.ean,
														sku: product.sku,
														image: product.image,
														name: product.name,
														platformId: stat._id,
														price: stat.price,
													});

													// if (winner?.price) {
													// 	setModal1(true);
													// } else {
													// 	setModalUpdate(true);
													// 	setSelectedSeller(seller);
													// }
													// setProduct(product);
													// setMarketplace(m);
													// setSelectedWinner(winner);
												}}
											>
												<td
													style={{ flex: "0 1 35%", width: "120px" }}
													className="inv-modal1-table-seller"
													title={stat.name}
												>
													{stat.name}
												</td>
												{isAdmin && (
													<td className="inv-modal1-table-column">
														{stat.offersCount}
													</td>
												)}
												<td
													style={{ flex: "0 1 30%", width: "120px" }}
													className={`inv-modal1-table-column text-end ${opacity}`}
												>
													{!stat.price ? (
														<div className="blue">add</div>
													) : opacity ? (
														deactivated ? (
															"deactivated"
														) : (
															"not selling"
														)
													) : (
														`${convertPrice(
                              stat.promotionalPrice?.pvpFinal || 
															stat.price?.pvpFinal || 0
														)} €`
													)}
													{/* {isAdmin && stat?.ranking === 1 && (
														<img
															className="ml2"
															src="/icons/inv-modal1-rank.svg"
															alt=""
														/>
													)} */}
												</td>
												{!isAdmin && !isHub && (
													<td className="inv-modal1-table-column">
														<div className="gestao-cost-text align-center justify-start">
															{stat.ranking === 1 ? (
																<img
																	className="ml2"
																	src="/icons/ranking-modal-yellow.svg"
																	alt=""
																	title="1º on Zeoos"
																/>
															) : stat?.ranking ? (
																`${stat.ranking}º`
															) : (
																""
															)}
															{/* {stat?.ranking === 1 ? (
																<img
																	className="ml2"
																	src="/icons/inv-modal1-rank.svg"
																	alt=""
																/>
															) : (
																""
															)} */}
														</div>
													</td>
												)}
											</tr>
										);
									})}
								</tbody>
							</table>
						</TabPanel>

						{/* <TabPanel className="m0 p0" value="1">
									<table
										style={{ fontSize: "14px" }}
										className="mapping-modal-table gestao-title-text"
									>
										<thead>
											<tr className="mapping-modal-results-title justify-between m0 pt2 pb2">
												<th
													style={{ flex: "0 1 35%", width: "120px" }}
													className="inv-modal1-table-seller"
												>
													Platform
												</th>
												{isAdmin && (
													<th className="inv-modal1-table-column">Sellers</th>
												)}
												<th
													style={{ flex: "0 1 30%", width: "120px" }}
													className="inv-modal1-table-column"
												>
													Price
												</th>
												{!isAdmin && (
													<th className="inv-modal1-table-column">Ranking</th>
												)}
											</tr>
										</thead>
										<tbody>
											{buybox.map((m: any, index: number) => {
												const winner = rankingZeoosWinner(m, seller);
												return (
													<tr
														className="mapping-modal-result-box justify-between pt1 pb1 pointer mass-import-option"
														key={index}
														onClick={() => {
															setModal1(true);
															setProduct(product);
															setMarketplace(m);
															setSelectedWinner(winner);
														}}
													>
														<td
															style={{ flex: "0 1 35%", width: "120px" }}
															className="inv-modal1-table-seller"
															title={m.zeoosName}
														>
															{m.zeoosName}
														</td>
														{isAdmin && (
															<td className="inv-modal1-table-column">
																{getSellersMarketplace(m)}
															</td>
														)}
														<td
															style={{ flex: "0 1 30%", width: "120px" }}
															className="inv-modal1-table-column text-end"
														>
															{`${convertPrice(
																winner?.price?.pvpFinal || 0
															)} €`}
															{isAdmin && m.buybox?.ranking === 1 && (
																<img
																	className="ml2"
																	src="/icons/inv-modal1-rank.svg"
																	alt=""
																/>
															)}
														</td>
														{!isAdmin && (
															<td className="inv-modal1-table-column">
																<div className="gestao-cost-text align-center justify-start">
																	{winner.ranking === 1 ? (
																		<img
																			className="ml2"
																			src="/icons/ranking-modal-yellow.svg"
																			alt=""
																			title="1º on Zeoos"
																		/>
																	) : winner?.ranking ? (
																		`${winner.ranking}º`
																	) : (
																		""
																	)}
																	{m.buybox?.ranking === 1 ? (
																		<img
																			className="ml2"
																			src="/icons/inv-modal1-rank.svg"
																			alt=""
																		/>
																	) : (
																		""
																	)}
																</div>
															</td>
														)}
													</tr>
												);
											})}
										</tbody>
									</table>
								</TabPanel> */}

						<TabPanel className="m0 p0" value="all">
							<table
								style={{ fontSize: "14px" }}
								className="mapping-modal-table gestao-title-text"
							>
								<thead>
									<tr className="mapping-modal-results-title justify-between m0 pt2 pb2">
										<th
											style={{ flex: "0 1 35%", width: "120px" }}
											className="inv-modal1-table-seller"
										>
											Platform
										</th>
										{isAdmin && (
											<th className="inv-modal1-table-column">Sellers</th>
										)}
										<th
											style={{ flex: "0 1 30%", width: "120px" }}
											className="inv-modal1-table-column"
										>
											Price
										</th>
										{!isAdmin && !isHub && (
											<th className="inv-modal1-table-column">Ranking</th>
										)}
									</tr>
								</thead>
								<tbody>
									{/* {all.map((m: any, index: number) => { */}
									{data?.map(stat => {
										// const winner = rankingZeoosWinner(m, seller);
										const cssIntegrated = stat.integrated
											? ""
											: "not-integrated";
										const opacity =
											!stat.price || !stat.integrated ? "opacity5" : "";
										const deactivated = !stat.active;
										const isActive = !!stat.price;
										return (
											<tr
												className={`mapping-modal-result-box justify-between pt1 pb1 pointer platform-list ${cssIntegrated} ${!isActive && "not-integrated"
													}`}
												key={stat._id}
												onClick={() => {
													openPriceUpdateModal?.({
														ean: product.ean,
														sku: product.sku,
														image: product.image,
														name: product.name,
														platformId: stat._id,
														price: stat.price,
													});
													// setModal1(true);
													// setProduct(product);
													// setMarketplace(m);
													// setSelectedWinner(winner);
												}}
											>
												<td
													style={{ flex: "0 1 35%", width: "120px" }}
													className="inv-modal1-table-seller in-row items-center"
													title={stat.name}
												>
													<div>{stat.name}</div>
												</td>
												{isAdmin && (
													<td className="inv-modal1-table-column">
														{stat.offersCount}
													</td>
												)}
												<td
													style={{ flex: "0 1 30%", width: "120px" }}
													className={`inv-modal1-table-column text-end ${opacity}`}
												>
													{!cssIntegrated
														? stat.price
															? `${convertPrice(
																stat.promotionalPrice?.pvpFinal || 
                                stat.price?.pvpFinal || 0
															)} €`
															: deactivated
																? "deactivated"
																: "not selling"
														: "not integrated"}
													{/* {stat?.ranking === 1 ? (
														<img
															className="ml2"
															src="/icons/inv-modal1-rank.svg"
															alt=""
															title="Buybox"
														/>
													) : (
														""
													)} */}
												</td>
												{!isAdmin && !isHub && (
													<td className="inv-modal1-table-column">
														<div className="gestao-cost-text align-center justify-start">
															{stat.ranking === 1 ? (
																!cssIntegrated ? (
																	<img
																		className="ml2"
																		src="/icons/ranking-modal-yellow.svg"
																		alt=""
																		title="1º on Zeoos"
																	/>
																) : (
																	""
																)
															) : stat?.ranking ? (
																`${stat.ranking}º`
															) : (
																""
															)}
															{/* {stat?.ranking === 1 ? (
																<img
																	className="ml2"
																	src="/icons/inv-modal1-rank.svg"
																	alt=""
																/>
															) : (
																""
															)} */}
														</div>
													</td>
												)}
											</tr>
										);
									})}
								</tbody>
							</table>
						</TabPanel>
					</TabContext>

					<div className="in-row width100 align-center justify-center">
						<div onClick={onModalClose} className="grid-modal-close-btn">
							Close
						</div>
					</div>
				</div>
			</>
		</Modal>
	);
};

export default GestaoGridModal;
