import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
} from "react";
import RoleRequired from "../../utils/role-required/RoleRequired";
import { Role } from "../../../interfaces";
import { getMarketplaceProductImage } from "../../../actions/pim";
import { IProductGestao } from "../../../interfaces";
import Tooltip from "@mui/material/Tooltip";
import ConditionalLink from "../../utils/conditional-link";
import useUser from "../../../hooks/useUser";
import SubHeader from "../catalog/sub-header";
import { Modal } from "components";
import { toggleProduct } from "../../../actions/vendor";
import { useHistory } from "react-router-dom";
import CatalogIntegratedChannelsModal from "./CatalogIntegratedChannelsModal";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import { Product, ProductRequest } from "types/product";
import Pagination from "../../ui/pagination/Pagination";
import { useProductExistsInMyCatalog } from "../../../actions/v2/product";
import useIsVisible from "hooks";
import { useCategoryByLevel } from "../../../actions/v2/category";

interface Props {
  products: any;
  setCurrentConfig: any;
  currentConfig: ProductRequest;
  selectedProducts: string[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<string[]>>;
  label: string;
  isLoading: boolean;
  subHeader?: boolean;
}

export const PimMainTable: React.FC<Props> = ({
  products,
  setCurrentConfig,
  currentConfig,
  selectedProducts,
  setSelectedProducts,
  label,
  isLoading,
  subHeader = true,
}) => {
  const { user } = useUser() as any;
  const [hide, setHide] = useState(false);

  const history = useHistory();
  const isMyCatalog = useMemo(
    () => history.location.pathname.startsWith("/my"),
    [history.location]
  );

  const handleSelectAll = useCallback(() => {
    setSelectedProducts((prev) =>
      !prev.length ? products?.data.map((x: any) => x.sku) : []
    );
  }, [products?.data, setSelectedProducts]);

  const handleSelectOne = useCallback(
    (item: IProductGestao) => () => {
      setSelectedProducts((prev) => {
        if (prev.find((x) => x === item.sku)) {
          return prev.filter((x) => x !== item.sku);
        }
        return [...prev, item.sku];
      });
    },
    [setSelectedProducts]
  );

  const toggleHide = () => {
    setHide(!hide);
  };

  return (
    <>
      {subHeader && (
        <SubHeader
          label={label}
          setCurrentConfig={setCurrentConfig}
          currentConfig={currentConfig}
        />
      )}

      <div className="table-main-title-cont">
        <div className="table-main-title-search-value-cont">
          <div className="table-search-box ml2">
            <form
              className="table-search-form"
              onSubmit={(e) => e.preventDefault()}
            >
              <input
                className="table-search-input"
                placeholder="Search product or sku"
                value={currentConfig.search}
                onChange={(e) =>
                  setCurrentConfig({
                    search: e.target.value,
                  })
                }
                autoFocus
              />
              <img
                className="table-search-image"
                src="/icons/search.svg"
                width="25px;"
                alt=""
              />
            </form>
          </div>
          <div className="table-value">
            {["gv@vinuus.com", "pj@vinuus.com"].includes(user.email) && (
              <RoleRequired role={Role.admin}>
                <img
                  className="refresh-image"
                  src="/icons/refresh.svg"
                  width="20px;"
                  alt=""
                />
              </RoleRequired>
            )}
            {isLoading ? (
              <CircularPreloader />
            ) : (
              <span className="table-main-title-text">
                {products?.total} PRODUCTS
              </span>
            )}
          </div>
        </div>

        <div className="marketplace-pagination">
          <div className="mr2 in-row align-center">
            {hide ? (
              <>
                <img
                  onClick={toggleHide}
                  className="mr1 pointer"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                  src="/icons/pim-main-tab1.svg"
                  alt=""
                />
                <img
                  onClick={toggleHide}
                  className="mr1 pointer"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                  src="/icons/pim-main-tab2active.svg"
                  alt=""
                />
              </>
            ) : (
              <>
                <img
                  onClick={toggleHide}
                  className="mr1 pointer"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                  src="/icons/pim-main-tab1active.svg"
                  alt=""
                />
                <img
                  onClick={toggleHide}
                  className="pointer"
                  style={{
                    width: "30px",
                    height: "30px",
                  }}
                  src="/icons/pim-main-tab2.svg"
                  alt=""
                />
              </>
            )}
          </div>
          <Pagination
            lastPage={products?.lastPage || 0}
            setConfig={setCurrentConfig}
            config={currentConfig}
          />
        </div>
      </div>

      {hide ? (
        <div
          style={{
            display: "block",
            height: "max-content",
            marginBottom: "20px",
          }}
          className="table-overflow"
        >
          <table
            style={{
              position: "sticky",
              zIndex: 101,
              top: "2px",
              width: "100%",
            }}
          >
            <tbody>
              {/* {selectedProducts.length ? (
										<tr
											style={{
												marginTop: "-5px",
												width: "100%",
											}}
											className="table-results-title"
										>
											<th
												className={`${
													user?.role !== Role.admin && "border10"
												} pim-selected-products pointer`}
												onClick={handleSelectAll}
											>
												<img
													className="mr2"
													src={
														selectedProducts.length
															? "/icons/carbon_checkbox.svg"
															: "/icons/check-box.svg"
													}
													alt=""
												/>
												<span>{selectedProducts.length} selected</span>
											</th>
											<RoleRequired role={Role.admin}>
												<th
													className="pim-batch pointer"
													onClick={() => {
														setBatch(true);
														toast.success("Products added to batch");
													}}
												>
													<span>Add to batch</span>
												</th>
											</RoleRequired>
										</tr>
									) : ( */}
              <tr
                style={{ marginTop: "-5px", width: "100%" }}
                className="table-results-title"
              >
                <th className="ml3 mr3">
                  <img
                    className="pointer"
                    src={
                      selectedProducts.length
                        ? "/icons/carbon_checkbox.svg"
                        : "/icons/check-box.svg"
                    }
                    alt=""
                    onClick={handleSelectAll}
                  />
                </th>
                <th className="gestao-image"></th>
                <th className="pim-main-sku">Sku</th>
                <th className="pim-main-ean">EAN</th>
                <th className="pim-main-title">Title</th>
                <th className="pim-main-producer">Brand</th>
                <th className="pim-main-producer">Preview</th>
              </tr>
              {/* )} */}
            </tbody>
          </table>
          <div>
            <table className="table-results p0">
              <tbody>
                {products?.data.map((item: Product) => (
                  <tr
                    key={item._id}
                    className="pim-table-result-box gestao-sku-text"
                  >
                    <td
                      className="ml3 mr3"
                      onClick={handleSelectOne(item as any)}
                    >
                      <img
                        className="pointer"
                        src={
                          !selectedProducts.find((x) => x === item.sku)
                            ? "/icons/check-box.svg"
                            : "/icons/check-box-checked.svg"
                        }
                        alt=""
                      />
                    </td>
                    <ConditionalLink
                      condition={user.role === Role.admin}
                      to={`/productInfo/${item.sku}`}
                      key={`${item.sku}-${item._id}`}
                      className="in-row"
                    >
                      <td className="gestao-image">
                        <img
                          src={getMarketplaceProductImage(item)}
                          alt=""
                          className="gestao-table-image"
                        />
                      </td>
                    </ConditionalLink>
                    <td className="pim-main-sku">{item.sku}</td>
                    <td className="pim-main-ean">{item.ean}</td>
                    <td className="pim-main-title">{item.name}</td>
                    <td className="pim-main-producer">
                      {item.brand ||
                        item?.details?.brand ||
                        item?.details?.["product-brand"]}
                    </td>
                    <td className="pim-main-producer">
                      {
                        <a
                          className="underline in-row align-center"
                          target={"_blank"}
                          style={{ color: "#2B5EC5" }}
                          rel="noreferrer"
                          href={`${process.env.REACT_APP_PREVIEW_URL}/pt/${item.sku}`}
                        >
                          <img
                            className="mr2"
                            src="/icons/preview-link-blue.svg"
                            alt=""
                          />
                          Preview
                        </a>
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div
          style={{ display: "flex", gap: "6px" }}
          className="mt2 mb2 width100 wrap justify-start aligm-start"
        >
          {products?.data.map((item: Product) => (
            <ProductBox
              key={`${item._id}-${item.ean}-${item.sku}`}
              item={item}
              canAdd={!isMyCatalog}
              onSelect={handleSelectOne(item as any)}
              selected={!!selectedProducts.find((x) => x === item.sku)}
            />
          ))}
        </div>
      )}
    </>
  );
};

const ProductBox: React.FC<{
  item: Product;
  canAdd?: boolean;
  selected?: boolean;
  onSelect: any;
}> = ({ item, canAdd, selected, onSelect }) => {
  const [integratedChannelsModal, setIntegratedChannelsModal] =
    useState<boolean>(false);
  const { user } = useUser();
  const [openCategorySublevel, setOpenCategorySublevel] = useState(false);

  const levels = useCategoryByLevel(item.categoryCode);

  return (
    <div className="product-main-box">
      <div
        className={`width100 align-center ${
          canAdd && user.role === Role.admin
            ? "justify-between "
            : "justify-between"
        }`}
      >
        <div className="in-row justify-center items-center" style={{ gap: 10 }}>
          <img
            className="pointer"
            src={
              selected ? "/icons/check-box-checked.svg" : "/icons/check-box.svg"
            }
            alt=""
            onClick={onSelect}
          />

          {canAdd && <AddToCatalogControl item={item} />}
        </div>

        <Tooltip className="pointer" title="Preview" placement="left">
          <a
            className="underline"
            target={"_blank"}
            rel="noreferrer"
            href={`${process.env.REACT_APP_PREVIEW_URL}/pt/${item.sku}`}
          >
            <img src="/icons/preview-link-blue.svg" alt="" />
          </a>
        </Tooltip>
      </div>
      <ConditionalLink
        condition={user.role === Role.admin}
        to={`/productInfo/${item.sku}`}
        className="in-row"
      >
        <div className="width100 justify-center">
          <img
            className="product-main-box-image"
            src={getMarketplaceProductImage(item)}
            alt=""
          />
        </div>
      </ConditionalLink>
      <div className="in-column justify-start align-start width100 mheight100px">
        <div className="product-main-box-title-cont">
          <div className="in-column align-start">
            <div>SKU:</div>
            <div className="bold">{item.sku}</div>
          </div>
          <div className="reverse-pim-main-box-title">
            <div>EAN:</div>
            <div className="bold">{item.ean}</div>
          </div>
        </div>

        <div style={{ fontSize: "13px", color: "#5A5A5A" }}>{item.name}</div>
        {!!levels.length && (
          <div
            onMouseLeave={() => setOpenCategorySublevel(false)}
            onMouseEnter={() => {
              setOpenCategorySublevel(true);
            }}
            className="product-sublevel mt2"
          >
            <img
              className=""
              src={"/icons/sublevel-1.svg"}
              width={14}
              height={14}
              alt=""
            />
            <p className="pl2 product-sublevel1-text ">
              {levels?.[levels.length - 1]}
            </p>
            {openCategorySublevel && (
              <div className="product-hovered-sublevels ">
                <p
                  style={{ fontWeight: levels?.length === 1 ? "700" : "400" }}
                  className="product-sublevel1-text2 "
                >
                  {levels?.[0]}
                </p>
                {levels?.[1] && (
                  <div className="product-sublevel mt1">
                    <img
                      className=""
                      src={"/icons/sublevel-2.svg"}
                      width={14}
                      height={14}
                      alt=""
                    />
                    <p
                      style={{
                        fontWeight: levels?.length === 2 ? "700" : "400",
                      }}
                      className="pl2 product-sublevel1-text2 "
                    >
                      {levels?.[1]}
                    </p>
                  </div>
                )}
                {levels?.[2] && (
                  <div className="product-sublevel ml3 mt1">
                    <img
                      className=""
                      src={"/icons/sublevel-2.svg"}
                      width={14}
                      height={14}
                      alt=""
                    />
                    <p
                      style={{ fontWeight: "700" }}
                      className="pl2 product-sublevel1-text2 "
                    >
                      {levels?.[2]}
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <div
          onClick={() => {
            setIntegratedChannelsModal(true);
          }}
          className="catalog-box-channels-btn"
        >
          {item.integratedPlatformsCount} integrated sale channels
        </div>

        {integratedChannelsModal && (
          <CatalogIntegratedChannelsModal
            product={item}
            modal={integratedChannelsModal}
            setModal={setIntegratedChannelsModal}
            integratedChannels={[]}
          />
        )}
      </div>
    </div>
  );
};

const AddToCatalogControl: React.FC<{ item: Product }> = ({ item }) => {
  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useIsVisible(ref);
  const { data: initialStatus } = useProductExistsInMyCatalog(item.sku, {
    enabled: isVisible,
  });

  const [isHovering, setIsHovering] = useState(false);
  const [modal, setModal] = useState<boolean>(false);

  const [status, setStatus] = useState(false);

  useEffect(() => {
    if (initialStatus === undefined) return;

    setStatus(initialStatus);
  }, [initialStatus]);

  const handleMouseOver = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsHovering(true);
  };

  const handleMouseOut = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsHovering(false);
  };

  const onModalClose = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModal(false);
  };

  return (
    <RoleRequired role={Role.user} strict>
      <div ref={ref}>
        <div onMouseOut={handleMouseOut} className="in-row align-center">
          <div
            onMouseOver={handleMouseOver}
            onClick={() => {
              setModal(true);
            }}
          >
            <div>
              {!isHovering && (
                <div className="in-row align-center">
                  {status ? (
                    <div className="in-row pointer align-center">
                      <img src="/icons/tick-grey.svg" alt="" />
                      <span
                        style={{ paddingLeft: "5px" }}
                        className="email-text"
                      >
                        Added
                      </span>
                    </div>
                  ) : (
                    <img
                      className="pointer"
                      src="/icons/grey-plus.svg"
                      alt=""
                    />
                  )}
                </div>
              )}
            </div>
            {isHovering && (
              <div className="in-row pointer align-center">
                {status ? (
                  <>
                    <img src="/icons/red-remove.svg" alt="" />
                    <span style={{ color: "#DD2E44" }} className="email-text">
                      Remove
                    </span>
                  </>
                ) : (
                  <>
                    <img src="/icons/blue-plus.svg" alt="" />
                    <span
                      style={{ color: "#2B5EC5", paddingLeft: "5px" }}
                      className="email-text"
                    >
                      Add
                    </span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>

        {modal && (
          <Modal onModalClose={onModalClose} isOpened={modal}>
            <div
              style={{ maxWidth: "420px" }}
              className="table-modal-form-cont p4"
            >
              <span
                style={{ fontWeight: "normal", fontSize: "16px" }}
                className="import-title in-column text-center"
              >
                Are you sure you want to {status ? "remove" : "add"}{" "}
                <span className="bold">{item.name}</span>{" "}
                {status ? "from" : "into"} your catalog?
              </span>
              <div className="table-modal-border"></div>
              <div
                style={{ marginBottom: "0" }}
                className="table-modal-form-button-box width100"
              >
                <button
                  className="sel-modal-form-button-cancel"
                  onClick={onModalClose}
                >
                  Cancel
                </button>
                <button
                  onClick={async (e) => {
                    await toggleProduct(item.sku);
                    setStatus((prev) => !prev);
                    onModalClose(e);
                  }}
                  className="table-modal-form-button initial"
                >
                  YES, {status ? "remove" : "add"} this product
                </button>
              </div>
            </div>
          </Modal>
        )}
      </div>
    </RoleRequired>
  );
};
