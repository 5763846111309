import React, { useContext, useEffect, useState } from "react";
import "../users-management/users-management.css";
import "./vendors.css";
import * as _ from "lodash";
import Preloader from "../../ui/preloader/Preloader";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import { VendorActivity } from "./VendorActivity";
import { updateVendor, createVendor } from "../../../actions/vendor";
import { TooltipText } from "../tables/gestao/Tooltip";
import { VendorRate } from "./VendorRate";
import { CreateEditVendor } from "./CreateEditVendor";
import { Paginator } from "../../ui/pagination/Paginator";
import { CountriesContext } from "../../context";
import { ICountryM } from "../country-management/CountryManagement";
import useVendors from "../../../hooks/useVendors";
import { IVendor } from "../../../interfaces";
import { compact } from "lodash";
import { Link } from "react-router-dom";
import { VendorActivityCat } from "./VendorActivityCat";
// import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
import { VendorActivityCountry } from "./VendorActivityCountry";
import SellersNav from "../../ui/sellers-nav/SellersNav";
import SellerCreateEditModal from "./SellerCreateEditModal";
import useSanitizedMarketplaces from "../../../hooks/useSanitizedMarketplaces";

export const countProducts = (vendor: IVendor) => {
  let total = 0;
  vendor?.categories?.map((c: any) => (total += c.productCount));
  return total;
};

export default function VendorsManagement() {
  const countries = useContext(CountriesContext) as ICountryM[];

  const vendors = useVendors();
  const marketplaces = useSanitizedMarketplaces();
  const [selectedVendor, setSelectedVendor] = useState<IVendor>();
  const [modal, setModal] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  const [results, setResults] = useState<IVendor[]>([]);
  const [limit, setLimit] = useState<number>(10);
  const [status, setStatus] = useState<string>("All Seller Status");
  const [country, setCountry] = useState<string>("All countries");
  const [paginatedData, setPaginatedData] = useState<IVendor[]>([]);
  const [action, setAction] = useState<string>("");
  const [openVendorId, setOpenVendorId] = useState<string>();
  const [openVendorId2, setOpenVendorId2] = useState<string>();

  useEffect(() => {
    if (!vendors.data) {
      return;
    }

    const results = compact(
      (vendors.data as IVendor[]).filter((item) => {
        if (search === "" && item === undefined) {
          return item;
        } else if (
          Object.keys(item).filter(
            (x) =>
              typeof (item as any)[x] === "string" &&
              (item as any)[x].toLowerCase().includes(search.toLowerCase())
          )?.length > 0
        ) {
          return item;
        }

        return null;
      })
    );

    setStatus("All Seller Status");
    setCountry("All countries");
    setResults(results);
    setPaginatedData(results.slice(0, limit));
    // eslint-disable-next-line
  }, [search, vendors.data]);

  useEffect(() => {
    if (!vendors.data) {
      return;
    }

    const _filteredResults =
      status === "All Seller Status"
        ? (vendors.data as IVendor[])
        : (vendors.data as IVendor[])?.filter((x) => {
          if (status === "No status") {
            return typeof x["isActive"] === "undefined";
          } else {
            const _status = status === "Active" ? true : false;
            return x.isActive === _status;
          }
        });
    const filteredResults =
      country === "All countries"
        ? _filteredResults
        : _filteredResults.filter((x) => x.country === country);
    setResults(filteredResults);
    setPaginatedData(filteredResults.slice(0, limit));
  }, [status, country, limit, vendors.data]);

  useEffect(() => {
    setStatus("All Seller Status");
    setCountry("All countries");
  }, [limit]);

  const statusesArray = (array: IVendor[]) => {
    return [
      "All Seller Status",
      ..._.uniq(
        _.map(array, (item: IVendor) => {
          if (item.isActive === undefined) {
            return "No status";
          } else {
            return item.isActive ? "Active" : "Inactive";
          }
        })
      ),
    ];
  };

  const filteredVendorsCounter = (results: IVendor[], filtered: string) => {
    return `${results.length} ${results.length > 1 ? "sellers" : "seller"
      } of ${filtered}`;
  };

  // const findACountryImg = (country: string) => {
  //   if (!country || country === "") {
  //     return "https://vinuus-portal.s3.eu-west-3.amazonaws.com/production/assets/no-country.svg";
  //   } else {
  //     return countries.find((x: ICountryM) => x.name === country)?.iconUrl;
  //   }
  // };

  const handleAccordClick = (id: any) => {
    if (openVendorId === id) setOpenVendorId("");
    if (openVendorId !== id) setOpenVendorId(id);
  };

  // const handleAccordClick2 = (id: any, cat: any) => {
  //   const newId = `${id}-${cat}`;
  //   if (openVendorId2 === newId) setOpenVendorId2("");
  //   if (openVendorId2 !== newId) setOpenVendorId2(newId);
  // };

  const countCountries = (vendor: any) => {
    const countries = vendor?.categories?.map((cat: any) =>
      cat.countries?.map((c: any) => {
        if (c.active) return c.name;
        return null;
      })
    );
    return _.uniq(_.flatten(countries)).filter((c: any) => c).length;
  };

  // interface IVendorCountry {
  //   vendor: any;
  //   country: any;
  //   category: any;
  // }

  // const VendorCountry: React.FC<IVendorCountry> = ({
  //   vendor,
  //   country,
  //   category,
  // }) => {
  //   const filteredCountries = category.countries.filter(
  //     (c: any) => c.name === country.name
  //   );

  //   if (filteredCountries.length === 0) {
  //     filteredCountries.push({
  //       deliveryType: "fulfillment",
  //       name: country.name,
  //       active: false,
  //       zeoosRate: null,
  //     });
  //   }

  //   return (
  //     <>
  //       {filteredCountries.map((c: any, i: number) => (
  //         <tr
  //           style={{ height: "50px" }}
  //           key={i}
  //           className="table-result-box border0 m0 border-bottom width100"
  //         >
  //           <td className="seller-cat-country">
  //             <img src={findACountryImg(country.name)} alt="" width="28px" />
  //             <div className="email-text pl1">{country.name}</div>
  //           </td>
  //           <td className="seller-cat">{c.deliveryType}</td>
  //           <td className="seller-cat">
  //             {c.zeoosRate ? `${c.zeoosRate}%` : ""}
  //           </td>
  //           <td className="seller-cat">
  //             <VendorActivityCountry
  //               mappedVendor={vendor}
  //               category={category.scheme}
  //               country={c}
  //               onSuccess={vendors.invalidate}
  //               handleAccordClick={handleAccordClick}
  //             />
  //           </td>
  //           <td className="seller-cat">
  //             <VendorRate
  //               mappedVendor={vendor}
  //               category={category.scheme}
  //               country={country.name}
  //             />
  //           </td>
  //         </tr>
  //       ))}
  //     </>
  //   );
  // };

  return (
    <>
      {modal ? (
        !selectedVendor ? (
          <SellerCreateEditModal
            rqKey={vendors.key}
            setModal={setModal}
            label="Create Seller"
            tab="sellers"
            create={createVendor}
          />
        ) : (
          <SellerCreateEditModal
            rqKey={vendors.key}
            setModal={setModal}
            seller={selectedVendor}
            label={"Edit Seller"}
            tab="onboarding"
          />
        )
      ) : (
        <div className="main-body">
          <div className="main-container">
            {!vendors.isLoading ? (
              <div className="s-users-management-container">
                {vendors.data ? (
                  <div className="table-main-cont">
                    <div className="table-title-cont p0 mt4 mb4">
                      <div className="mplc-filter-cont width100">
                        <div className="">
                          <SellersNav />
                        </div>
                        <div className="in-row align-center justify-end width100">
                          <div className="mplc-filter justify-end">
                            <div className="filter-text">Filter by:</div>
                            <div className="">
                              <select
                                className="main-select-bi mwidth150 ml1"
                                value={status}
                                onChange={(e) => {
                                  e.preventDefault();
                                  setStatus("All Seller Status");
                                  setStatus(e.target.value);
                                }}
                              >
                                {statusesArray(vendors.data).map(
                                  (status: string, index: number) => (
                                    <option
                                      className="acompan-option"
                                      key={index}
                                    >
                                      {status}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                            {/* <div className="">
                            <select
                              className="main-select-bi mwidth150"
                              value={country}
                              onChange={(e) => {
                                e.preventDefault();
                                setCountry("All countries");
                                setCountry(e.target.value);
                              }}
                            >
                              {countriesArray(vendors.data).map(
                                (country: string, index: number) => (
                                  <option className="acompan-option" key={index}>
                                    {country}
                                  </option>
                                )
                              )}
                            </select>
                          </div> */}
                          </div>
                          <div
                            className="tax-title-button pointer mr2"
                            onClick={() => {
                              setModal(true);
                              setAction("create");
                              setSelectedVendor(undefined);
                            }}
                          >
                            <img src="/icons/pim-plus.svg" alt="" />
                            <div className="pim-avaliacoes-title-button-text">
                              ADD new seller
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="table-main-title-cont">
                      <div className="table-main-title-search-value-cont">
                        <div className="search-box">
                          <div className="search_form">
                            <div className="table-search-box">
                              <form
                                className="table-search-form"
                                onSubmit={(e) => e.preventDefault()}
                              >
                                <input
                                  className="table-search-input"
                                  placeholder="Search vendor"
                                  onChange={(e) => setSearch(e.target.value)}
                                />
                                <img
                                  className="table-search-image"
                                  src="/icons/search.svg"
                                  width="25px;"
                                  alt=""
                                />
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="table-main-title-text pl2">
                          {status !== "All Seller Status" &&
                            filteredVendorsCounter(results, status)}
                          {country !== "All countries" &&
                            filteredVendorsCounter(results, country)}
                          {status === "All Seller Status" &&
                            country === "All countries" &&
                            filteredVendorsCounter(
                              search === "" ? vendors.data : results,
                              "all"
                            )}
                        </div>
                      </div>
                      <div className="marketplace-pagination">
                        <Paginator
                          data={
                            search !== "" ||
                              status !== "All Seller Status" ||
                              country !== "All countries"
                              ? results
                              : vendors.data
                          }
                          rowsPerPage={limit}
                          setPaginatedData={setPaginatedData}
                          setLimit={setLimit}
                          limit={limit}
                        />
                      </div>
                    </div>
                    <div
                      style={{ height: "max-content", marginBottom: "20px" }}
                      className="table-overflow"
                    >
                      <table
                        style={{
                          zIndex: 101,

                          width: "100%",
                        }}
                      >
                        <tr
                          style={{ marginTop: "-5px", width: "100%" }}
                          className="table-results-title"
                        >
                          <th className="vendors-accordion"></th>
                          <th className="vendors-title">Vendor</th>
                          <th className="vendors-country">Countries</th>
                          <th className="users-status">Status</th>
                          <th className="vendors-contact">Seller Data</th>
                          <th className="vendors-contact">
                            Platforms
                          </th>
                          {/* <th className="vendors-products">Products</th> */}
                        </tr>
                      </table>
                      <table className="table-results">
                        <tbody>
                          {paginatedData.map((vendor: any, index: number) => (
                            <React.Fragment key={index}>
                              <Accordion
                                className="m0 p0 width100"
                                expanded={vendor.id === openVendorId}
                              >
                                <AccordionSummary
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                  className="width100 p0 m0div"
                                  onClick={() => {
                                    handleAccordClick(vendor.id);
                                  }}
                                >
                                  <tr className="table-result-box border0 m0 height100px width100">
                                    <td className="vendors-accordion">
                                      {/* <img
                                        src={
                                          vendor.id === openVendorId
                                            ? "/icons/vendors-arrow-up.svg"
                                            : "/icons/vendors-arrow-down.svg"
                                        }
                                        alt=""
                                      /> */}
                                    </td>
                                    <td className="vendors-title">
                                      <Link
                                        className="in-row align-center"
                                        to={`/sellers/${vendor.id}/products`}
                                      >
                                        {/* <div className="vendors-title-image align-center">
                                        <img
                                          src={vendor.vendorIcon}
                                          width="70px"
                                          alt=""
                                        />
                                      </div> */}
                                        <div className="email-text pl4">
                                          <TooltipText
                                            text={
                                              typeof vendor.name !== "string"
                                                ? ""
                                                : vendor.name
                                            }
                                            items={10}
                                            separator={" "}
                                          />
                                        </div>
                                      </Link>
                                    </td>
                                    <td className="vendors-country vendors-product-text">
                                      {countCountries(vendor)} countries
                                    </td>
                                    <VendorActivity
                                      mappedVendor={vendor}
                                      onSuccess={vendors.invalidate}
                                      handleAccordClick={handleAccordClick}
                                    />

                                    <td className="vendors-contact">
                                      <img
                                        onClick={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          setModal(true);
                                          setAction("edit");
                                          setSelectedVendor(vendor);
                                        }}
                                        src="/icons/sellers-contact.svg"
                                        alt=""
                                      />
                                    </td>
                                    <td className="vendors-contact">
                                      <Link
                                        className="in-row align-center"
                                        to={`/sellers/${vendor.id}/platforms`}
                                      >
                                        <img
                                          src="/icons/gala_layer.svg"
                                          alt=""
                                        />
                                        <p className="vendors-product-text ml1">
                                          {vendor?.activeMarketplaces?.length || 0}/
                                          {marketplaces?.data?.length}
                                        </p>
                                      </Link>
                                    </td>
                                    {/* <td className="vendors-products">
                                      <Link
                                        className="in-row align-center"
                                        to={`/sellers/${vendor.id}/products`}
                                      >
                                        <img
                                          src="/icons/sellers-product.svg"
                                          alt=""
                                        />
                                        <p className="vendors-product-text ml1">
                                          {vendor.productsCount}
                                        </p>
                                      </Link>
                                    </td> */}
                                  </tr>
                                </AccordionSummary>
                                <AccordionDetails className="width100 p0 m0div">
                                  {vendor?.categories
                                    ?.filter((cat: any) => cat?.count > 0)
                                    .map((cat: any, i: number) => (
                                      <Accordion
                                        className="m0 p0 width100"
                                        key={i}
                                        expanded={
                                          `${vendor.id}-${cat.label}` ===
                                          openVendorId2
                                        }
                                      >
                                        <AccordionSummary
                                          aria-controls="panel1a-content"
                                          id={`seller_${vendor.id}-${cat.label}`}
                                          className="width100 p0 m0div"
                                        // onClick={() => {
                                        //   handleAccordClick2(
                                        //     vendor.id,
                                        //     cat.label
                                        //   );
                                        // }}
                                        >
                                          <table className="table-results height30 mheightauto">
                                            <tr
                                              style={{ height: "50px" }}
                                              className="table-results-title"
                                            >
                                              <td className="vendors-accordion ml4">
                                                {/* <img
                                                  className="ml4"
                                                  src={
                                                    vendor.id === openVendorId2
                                                      ? "/icons/vendors-arrow-up.svg"
                                                      : "/icons/vendors-arrow-down.svg"
                                                  }
                                                  alt=""
                                                /> */}
                                              </td>
                                              <td className="seller-title-cat">
                                                {cat.label}
                                              </td>
                                              {/* <VendorActivityCat
                                                mappedVendor={vendor}
                                                category={cat.label}
                                                onSuccess={vendors.invalidate}
                                                handleAccordClick={
                                                  handleAccordClick
                                                }
                                              /> */}
                                              <td className="vendors-contact"></td>
                                              <td className="vendors-products">
                                                <Link
                                                  className="in-row align-center"
                                                  to={`/sellers/${vendor.id}/products`}
                                                >
                                                  <img
                                                    src="/icons/sellers-product.svg"
                                                    alt=""
                                                  />
                                                  <p className="vendors-product-text ml1">
                                                    {cat.count}
                                                  </p>
                                                </Link>
                                              </td>
                                            </tr>
                                          </table>
                                        </AccordionSummary>
                                        {/* <AccordionDetails className="width100 p0 m0div">
                                          <table className="table-results mheightauto">
                                            <thead>
                                              <tr className="table-result-box border0 width100 m0 height30 width100">
                                                <th className="seller-cat-country">
                                                  Country
                                                </th>
                                                <th className="seller-cat">
                                                  Delivery Type
                                                </th>
                                                <th className="seller-cat">
                                                  Zeoos Rate
                                                </th>
                                                <th className="seller-cat">
                                                  Country Status
                                                </th>
                                                <th className="seller-cat">
                                                  Settings
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {_.sortBy(countries, "name")?.map(
                                                (country: any, i: number) => (
                                                  <VendorCountry
                                                    key={i}
                                                    vendor={vendor}
                                                    country={country}
                                                    category={cat}
                                                  />
                                                )
                                              )}
                                            </tbody>
                                          </table>
                                        </AccordionDetails> */}
                                      </Accordion>
                                    ))}
                                </AccordionDetails>
                              </Accordion>
                            </React.Fragment>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : (
                  <Preloader />
                )}
              </div>
            ) : (
              <Preloader />
            )}
          </div>
        </div>
      )}
    </>
  );
}
