import "./my-catalogue.css";
import "../payment/payment.css";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Modal } from "../../../ui/modal/Modal";
import { useDropzone } from "react-dropzone";
import {
	downloadExampleFile,
	initializeOnboardingProductsImport,
} from "../../../../actions/onboardingSeller";
import WaitingArea from "./WaitingArea";
import { IProductsCounter } from "../../../../interfaces";
import YouTube, { YouTubeProps } from 'react-youtube';

interface Props {
	isLoading: boolean;
	setIsLoading: (isLoading: React.SetStateAction<boolean>) => void;
	setProductsData: (data: React.SetStateAction<IProductsCounter>) => void;
	setValue: (value: React.SetStateAction<string>) => void;
}

const MyCatalogue: React.FC<Props> = ({
	isLoading,
	setIsLoading,
	setProductsData,
	setValue,
}) => {
	const [importModal, setImportModal] = useState<boolean>(false);

	const [modal, setModal] = useState<boolean>(false);

	const onModalClose = (e: React.MouseEvent) => {
        e.stopPropagation();
        setModal(false);
    };

	const onPlayerReady: YouTubeProps['onReady'] = (event) => {
		// access to player in all event handlers via event.target
		// event.target.pauseVideo();
	  }
	
	  const opts: YouTubeProps['opts'] = {
		height: '400',
		width: '640',
		playerVars: {
		  // https://developers.google.com/youtube/player_parameters
		  autoplay: 1,
		},
	  };

	const downloadFile = async (e: React.MouseEvent) => {
		e.preventDefault();

		try {
			await downloadExampleFile();
			toast.success("Success");
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}
	};

	return (
		<>
			{isLoading ? (
				<WaitingArea mode="catalog" />
			) : (
				<div style={{ maxWidth: "100%" }} className="catalog-instruction-cont">
					<div className="catalog-instruction-steps">
						<div className="catalog-instruction-steps-title">
							Let's start by uploading your catalog
						</div>
						<div className="catalog-instruction-step">
							1. Download the standart .xlsx spreadsheet.
						</div>
						<div className="download-button pointer mb3" onClick={downloadFile}>
							<img src="/icons/download-file.svg" alt="" />
							<div className="download-button-text">Download standard file</div>
						</div>
						<div className="catalog-instruction-step">
							2. Fill in the spreadsheet with the EAN of your new products. You
							can enter your SKUs for reference.
						</div>
						<div className="catalog-instruction-step">
							3. Click in Import products button to upload your .xlsx file to
							assemble your catalogue.
						</div>
						<div
							className="import-button pointer"
							onClick={() => setImportModal(true)}
						>
							<img src="/icons/import-products.svg" alt="" />
							<div className="import-button-text">Import products</div>
						</div>
						<div className="in-row align-center mt5">
							<img src="/icons/warning-alert.svg" alt="" />
							<div className="warning-text ml2">
								Note that you can only sell new products.
							</div>
						</div>
					</div>
					<div onClick={() => {
                                    setModal(true);
                                }} className="catalog-watch pointer">
						<div style={{ padding: "10px", height: "auto" }} className="catalog-watch-area">
							{/* <div className="in-row align-center justify-end">
								<div className="catalog-watch-area-academy">Zeoos academy</div>
							</div>
							<div style={{ width: "auto", marginTop: "30px" }} className="catalog-watch-area-watchbtn-cont">
								<img src="/icons/play-fill.svg" alt="" />
								<div>watch</div>
							</div>
							<div className="catalog-watch-additional">
								<div className="catalog-watch-area-step">step 1</div>
								<div className="catalog-watch-area-mycatalog">my catalog</div>
							</div> */}
							<img src="/icons/onboarding-step1.png" alt="" />
							<div style={{ width: "100px", height: "50px", top: "40%", right: "40%", left: "40%", bottom: "40%" }} className="absolute catalog-watch-area-watchbtn-cont">
								<img src="/icons/play-fill.svg" alt="" />
								<b>watch</b>
							</div>
						</div>
					</div>
					{importModal && (
						<ImportModal
							modal={importModal}
							setModal={setImportModal}
							setIsLoading={setIsLoading}
							setProductsData={setProductsData}
							setValue={setValue}
						/>
					)}
					{modal && (
                <Modal onModalClose={onModalClose} isOpened={modal}>
					<YouTube videoId="_Fgy-mk8zBs" opts={opts} onReady={onPlayerReady} />
				</Modal>
			)}
				</div>
			)}
		</>
	);
};

interface ImportModalProps {
	modal: boolean;
	setModal: (modal: React.SetStateAction<boolean>) => void;
	setIsLoading: (isLoading: React.SetStateAction<boolean>) => void;
	setProductsData: (data: React.SetStateAction<any>) => void;
	setValue: (value: React.SetStateAction<string>) => void;
}

const ImportModal: React.FC<ImportModalProps> = ({
	modal,
	setModal,
	setIsLoading,
	setProductsData,
	setValue,
}) => {
	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({
			accept: [".xlsx"],
			onDropRejected: () => {
				toast.error('File type rejected. Only ".xlsx" files are allowed.');
			},
		});

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();

			try {
				setIsLoading(true);
				const data = await initializeOnboardingProductsImport(acceptedFiles[0]);
				setProductsData(data);
				toast.success("Success");
				setValue("2");
				setIsLoading(false);
			} catch (error) {
				console.error(error);
				toast.error("Something went wrong. Please, verify your file's format");
				setIsLoading(false);
			}

			setModal(false);
		},
		[acceptedFiles, setModal, setIsLoading, setProductsData]
	);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="pc-modal-cont">
				<div className="pc-modal-close pointer" onClick={() => setModal(false)}>
					<img src="/icons/delete-x.svg" alt="" />
				</div>
				<div className="pc-modal-title-box">
					<div className="pc-modal-title-text">Import products</div>
				</div>
				<div className="table-modal-border mb1"></div>
				<div className="pc-modal-dropzone pointer" {...getRootProps()}>
					{/* @ts-ignore */}
					<input {...getInputProps()} />
					{isDragActive ? (
						<h4>Drop the file here</h4>
					) : (
						<h2 className="pc-modal-dropzone-text">
							{acceptedFiles.length > 0
								? `Click 'Import' to finish upload ${acceptedFiles[0].name}`
								: "Drop the file here"}
						</h2>
					)}
				</div>
				<div className="table-modal-border mb3"></div>
				<div className="table-modal-form-button-box width100">
					<button
						className="table-modal-form-button-cancel"
						onClick={onModalClose}
					>
						Cancel
					</button>
					<button
						className="table-modal-form-button"
						onClick={onSubmit}
						disabled={!acceptedFiles.length}
					>
						Import
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default MyCatalogue;
