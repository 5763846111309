import React, { useContext } from "react";
import {
  Route,
  Redirect,
  withRouter,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";
import { isValidPermission, TPermission } from "../../../actions/user";
import { UserContext } from "../../context";
import { Role } from "../../../interfaces";
import useUser from "../../../hooks/useUser";

interface Props extends RouteComponentProps {
  permission?: TPermission;
  roles?: Role[];
}

export type TCustomRouteProps = Props & RouteProps;

const AdminRoute: React.FC<TCustomRouteProps & React.PropsWithChildren> = ({
  children,
  permission,
  roles,
  ...props
}) => {
  const { user } = useUser();

  return (
    <Route
      {...props}
      render={({ location }) =>
        user?.role === Role.admin ||
          (permission && isValidPermission(permission, user.permissions)) ||
          (!!roles?.length && roles.includes(user.role)) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/permission-denied",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export default withRouter(AdminRoute);
