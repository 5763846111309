import React, { useCallback, useState } from "react";
import { Modal } from "../../../ui/modal/Modal";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import {
	backToSelectSellingPlatforms,
	downloadPriceStockData,
	initializeOnboardingPricesImport,
} from "../../../../actions/onboardingSeller";
import useUser from "../../../../hooks/useUser";
import YouTube, { YouTubeProps } from "react-youtube";

interface Props {
	setValue: (value: React.SetStateAction<string>) => void;
}

const MyOffers: React.FC<Props> = ({ setValue }) => {
	const [modal, setModal] = useState<boolean>(false);
	const [importModal, setImportModal] = useState<boolean>(false);
	const [labelBtn, setLabelBtn] = useState<string>("PVP");
	const [modal1, setModal1] = useState<boolean>(false);
	const [backModal, setBackModal] = useState<boolean>(false);
	const { invalidate } = useUser();

	const onPlayerReady: YouTubeProps["onReady"] = (event) => {
		// access to player in all event handlers via event.target
		// event.target.pauseVideo();
	};

	const opts: YouTubeProps["opts"] = {
		height: "400",
		width: "640",
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 1,
		},
	};
	const onModalClose1 = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal1(false);
	};

	const downloadFile = async (e: React.MouseEvent) => {
		e.preventDefault();

		try {
			await downloadPriceStockData("cost");
			toast.success("Your spreadsheet will be sent by email!", { autoClose: false });
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}
	};

	const onBackHandler = async (e: React.MouseEvent) => {
		e.preventDefault();

		try {
			await backToSelectSellingPlatforms();
			toast.success("Success");
			await invalidate();
			setValue("2");
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}

		setBackModal(false);
	};

	const selectValue = () => {
		const select = document.getElementsByClassName(
			"main-select-bi"
		)[0] as HTMLSelectElement;
		return select?.options[select.selectedIndex].value;
	};

	const selectLabel = () => {
		const select = document.getElementsByClassName(
			"main-select-bi"
		)[0] as HTMLSelectElement;
		return select?.options[select.selectedIndex].label;
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<>
			<div style={{ padding: "40px" }} className="onb-box">
				<div className="in-row width100 align-start justify-between">
					<div className="in-column width100 align-start justify-start">
						<span className="onboarding-steps-title-cont bold">
							Now just create your competitive offers:
						</span>
						{/* <div
							style={{ height: "auto" }}
							className="freight-body text-start mt4"
						>
							You can send the spreadsheet with the{" "}
							<b className=" underline">PVP</b> or your{" "}
							<b className="underline">Purchase Price</b> so we can generate
							your offers.
						</div>
						<div
							style={{ height: "auto" }}
							className="freight-body text-start mt4"
						>
							<b>1.</b> Select which value you want to use to create your
							offers:
						</div>
						<select
							className="main-select-bi mwidth150 mt1"
							onChange={() => setLabelBtn(selectLabel())}
						>
							<option className="compa-option" value="pvp">
								PVP
							</option>
							<option className="compa-option" value="cost">
								Purchase Price
							</option>
						</select> */}
						<div
							style={{ height: "auto" }}
							className="freight-body text-start mt4"
						>
							{/* <b>2.</b> Download your spreadsheet with our competitiveness
							suggestions and fill in the <strong>{selectLabel()}</strong> of
							your products in the chosen sales channels. */}
							<b>1.</b> Get your spreadsheet and fill in the
							<strong> cost prices (PP)</strong> of
							your products in the chosen sales channels.
						</div>
						<div
							style={{ background: "#fff", border: "1px solid #55718a" }}
							className="tax-title-button pointer mt1 mb4 mr2"
							onClick={downloadFile}
						>
							<img src="/icons/file-download.svg" alt="" />
							<div
								style={{ color: "#55718a" }}
								className="pim-avaliacoes-title-button-text initial"
							>
								Get your model spreadsheet
							</div>
						</div>
						<div
							style={{ height: "auto" }}
							className="freight-body text-start mt4"
						>
							<b>2.</b> Send us your completed spreadsheet so we can create your
							offers and analyse their competitiveness.
						</div>
						<div className="tax-title-button pointer mt1 mb4 mr2">
							<img src="/icons/file-download-white.svg" alt="" />
							<div
								className="pim-avaliacoes-title-button-text initial"
								onClick={() => setImportModal(true)}
							>
								Send your file
							</div>
						</div>
						<div
							style={{
								background: "#fff",
								border: "1px solid #55718a",
								marginTop: "40px",
							}}
							className="tax-title-button pointer mt1 mr2"
						>
							<img src="/icons/billing-arrow-back.svg" alt="" />
							<div
								style={{ color: "#55718a" }}
								className="pim-avaliacoes-title-button-text initial"
								onClick={() => setBackModal(true)}
							>
								Back
							</div>
						</div>
					</div>
					<div
						onClick={() => {
							setModal1(true);
						}}
						className="catalog-watch pointer width50"
					>
						<div
							style={{ padding: "10px", height: "auto" }}
							className="catalog-watch-area"
						>
							{/* <div className="in-row align-center justify-end">
								<div className="catalog-watch-area-academy">Zeoos academy</div>
							</div>
							<div
								style={{ width: "auto", marginTop: "30px" }}
								className="catalog-watch-area-watchbtn-cont"
							>
								<img src="/icons/play-fill.svg" alt="" />
								<div>watch</div>
							</div>
							<div className="catalog-watch-additional">
								<div className="catalog-watch-area-step">step 3</div>
								<div className="catalog-watch-area-mycatalog">my offers</div>
							</div> */}
							<img src="/icons/onboarding-step3.png" alt="" />
							<div style={{ width: "100px", height: "50px", top: "40%", right: "40%", left: "40%", bottom: "40%" }} className="absolute catalog-watch-area-watchbtn-cont">
								<img src="/icons/play-fill.svg" alt="" />
								<b>watch</b>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <div onClick={() => {
            setModal(true);
          }} className="width100 align-center pointer justify-center mt4 mb4">
            <img src="/icons/skip-start.svg" alt="" />
            <span style={{ color: "#55718A", fontWeight: "500" }} className="ml1 email-text">skip guided start</span>
          </div> */}
			{importModal && (
				<ImportModal modal={importModal} setModal={setImportModal} />
			)}
			{backModal && (
				<Modal onModalClose={() => setBackModal(false)} isOpened={backModal}>
					<div
						style={{ maxWidth: "420px" }}
						className="table-modal-form-cont p4"
					>
						<div className="updateForm__container">
							<div className="onb-modal-text">
								<strong className="pb1">
									Are you sure you want to return to Step 2?
								</strong>
								<br />
								if you do this, all progress on this step will be lost
							</div>
							<div className="table-modal-border"></div>
							<div
								style={{ textAlign: "center" }}
								className="table-modal-form-button-box width100 m0"
							>
								<div
									onClick={() => setBackModal(false)}
									style={{ background: "#fff", border: "1px solid #55718a" }}
									className="tax-title-button pointer mt1 mr2"
								>
									<div
										style={{ color: "#55718a" }}
										className="pim-avaliacoes-title-button-text initial"
									>
										No
									</div>
								</div>
								<div
									style={{ background: "#fff", border: "1px solid #55718a" }}
									className="tax-title-button pointer mt1 mr2"
								>
									<div
										style={{ color: "#55718a" }}
										className="pim-avaliacoes-title-button-text initial"
										onClick={onBackHandler}
									>
										Yes, I agree
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<div style={{ width: "400px" }} className="table-modal-form-cont p4">
						<div className="updateForm__container">
							<div className="vendor-mapping-modal-text">
								<b>Are you sure you want to skip the guided start?</b>
								<br />
								Either way, you can still choose your sales channels and create
								your offers later.
							</div>
							<div className="table-modal-border"></div>
							<div className="table-modal-form-button-box width100 m0">
								<div
									onClick={onModalClose}
									className="download-button pointer mb3"
								>
									<div className="download-button-text">NO, keep the guide</div>
								</div>
								<NavLink to="/catalog/integrated" className="download-button pointer mb3">
									<div className="download-button-text">
										YES, I want to skip
									</div>
								</NavLink>
							</div>
						</div>
					</div>
				</Modal>
			)}
			{modal1 && (
				<Modal onModalClose={onModalClose1} isOpened={modal1}>
					<YouTube videoId="OwE2OixkNZc" opts={opts} onReady={onPlayerReady} />
				</Modal>
			)}
		</>
	);
};

interface ImportModalProps {
	modal: boolean;
	setModal: (modal: React.SetStateAction<boolean>) => void;
}

const ImportModal: React.FC<ImportModalProps> = ({ modal, setModal }) => {
	const [loading, setLoading] = useState(false);
	const { invalidate } = useUser();
	const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
		useDropzone({
			accept: [".xlsx"],
			onDropRejected: () => {
				toast.error('File type rejected. Only ".xlsx" files are allowed.');
			},
		});

	const onSubmit = useCallback(
		async (e: React.FormEvent) => {
			e.preventDefault();
			setLoading(true);

			try {
				await initializeOnboardingPricesImport(acceptedFiles[0]);
				invalidate();
				setModal(false);
			} catch (error) {
				console.error(error);
				toast.error("Something went wrong. Please, check you file once again");
			}

			setLoading(false);
		},
		[acceptedFiles, setModal, invalidate]
	);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<Modal onModalClose={onModalClose} isOpened={modal}>
			<div className="pc-modal-cont">
				<div className="pc-modal-close pointer" onClick={() => setModal(false)}>
					<img src="/icons/delete-x.svg" alt="" />
				</div>
				<div className="pc-modal-title-box">
					<div className="pc-modal-title-text">Import prices</div>
				</div>
				<div className="table-modal-border mb1"></div>
				<div className="pc-modal-dropzone pointer" {...getRootProps()}>
					{/* @ts-ignore */}
					<input {...getInputProps()} />
					{isDragActive ? (
						<h4>Drop the file here</h4>
					) : (
						<h2 className="pc-modal-dropzone-text">
							{acceptedFiles.length > 0
								? `Click 'Import' to finish upload ${acceptedFiles[0].name}`
								: "Drop the file here"}
						</h2>
					)}
				</div>
				<div className="table-modal-border mb3"></div>
				<div className="table-modal-form-button-box width100">
					<button
						className="table-modal-form-button-cancel"
						onClick={onModalClose}
					>
						Cancel
					</button>

					<button
						className="table-modal-form-button"
						onClick={onSubmit}
						disabled={!acceptedFiles.length || loading}
					>
						Import
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default MyOffers;
