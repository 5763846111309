import React, { useDeferredValue, useEffect, useMemo, useState } from "react";
import { PimMainTable } from "./PimMainTable";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { CreateProductModal } from "./CreateProductModal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import {
  Link,
  NavLink,
  RouteComponentProps,
  useHistory,
  withRouter,
} from "react-router-dom";
import {
  getOpenSchemeTabs,
  getOpenTabs,
  getSchemes,
  removeOpenTab,
  removeOpenSchemeTab,
} from "../../../actions/pim";
import { useQuery } from "react-query";
import { CreateSchemeForm } from "./scheme/CreateScheme";
import { CatalogTab, Role } from "../../../interfaces";
import RoleRequired from "../../utils/role-required/RoleRequired";
import useUser from "../../../hooks/useUser";
import { useAtom } from "jotai";
import { selectedProductsAtom } from "../tables/gestao/Gestao";
import { IPaginatedRequest, IPaginatedResponse } from "../../../interfaces";
import BatchDropDown from "../../ui/batch-dropdown/BatchDropDown";
import CardButtons from "../catalog/card-buttons/CardButtons";
import { UnregisteredTable } from "../catalog/unregistered-table/UnregisteredTable";
import ProductImportUploadedReportModal from "../../ui/product-import-uploaded-report-modal";
import OfferImportUploadedReportModal from "../../ui/offer-import-uploaded-report-modal";
import CircularPreloader from "../../ui/circular-preloader/CircularPreloader";
import { useParamsState } from "hooks";
import { useProducts } from "../../../actions/v2/product";
import { ProductRequest } from "types/product";
import useActiveVendors from "../../../hooks/useActiveVendors";

export type PimProductResponse = IPaginatedResponse<
  any[],
  {
    all: { sku: string; }[];
  }
>;

// Note: this components is used for both:
// /catalog and /my/catalog routes
const PimMain: React.FC<RouteComponentProps<{ tab: string; }>> = ({
  match,
  location,
}) => {
  const [allSelectedFlag, setAllSelectedFlag] = useState(false);
  const [batch, setBatch] = useState<boolean>(false);

  const isMyCatalog = useMemo(
    () => location.pathname.startsWith("/my"),
    [location]
  );

  const tab = useMemo(
    () => (match.params?.tab || "integrated") as CatalogTab,
    [match]
  );
  const { user } = useUser();
  const history = useHistory();

  useEffect(() => {
    if (isMyCatalog && user.role === Role.admin) {
      history.push(`/catalog/${tab}`);
    }
  }, [user, isMyCatalog, tab, history]);

  const [selectedProducts, setSelectedProducts] = useAtom(selectedProductsAtom);
  const [currentConfig, setCurrentConfig] = useParamsState<ProductRequest>({
    perPage: 10,
    page: 1,
    type: tab as any,
  });
  const debouncedConfig = useDeferredValue(currentConfig);

  const { isLoading, data: products } = useProducts({
    ...debouncedConfig,
    ...(isMyCatalog ? { seller: user?.seller?._id || "myseller" } : {}),
  });




  const tabHTML = useMemo(() => {
    switch (tab) {
      case "integrated":
      case "non-integrated":
        return (
          <PimMainTable
            label={`${tab} Products`}
            products={products!}
            setCurrentConfig={setCurrentConfig}
            currentConfig={currentConfig}
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            isLoading={isLoading}
          />
        );
      case "unregistered":
        return <UnregisteredTable />;
    }
  }, [
    tab,
    currentConfig,
    products,
    selectedProducts,
    setSelectedProducts,
    isLoading,
    setCurrentConfig,
  ]);

  return (
    <div className="main-body">
      <div className="main-container">
        <RoleRequired>
          <PIMHeader
            setCurrentConfig={setCurrentConfig}
            currentConfig={currentConfig}
          />
        </RoleRequired>
        <BatchDropDown
          products={products!}
          selectedProducts={selectedProducts}
          setSelectedProducts={setSelectedProducts}
          batch={batch}
          setBatch={setBatch}
          productsFrom={location.pathname}
          allSelectedFlag={allSelectedFlag}
          setAllSelectedFlag={setAllSelectedFlag}
        />

        <div className="table-main-cont pt4">
          <CardButtons isMy={isMyCatalog} setCurrentConfig={setCurrentConfig} />

          {tabHTML}
        </div>
      </div>

      <RoleRequired role={Role.user} strict>
        <ProductImportUploadedReportModal />
        <OfferImportUploadedReportModal />
      </RoleRequired>
    </div>
  );
};

const PIMHeader: React.FC<{
  setCurrentConfig: any;
  currentConfig: IPaginatedRequest;
}> = ({ setCurrentConfig, currentConfig }) => {
  const { user } = useUser();

  const [modal, setModal] = useState<boolean>(false);
  const [createProductModal, setCreateProductModal] = useState(false);

  const { data: schemes } = useQuery("schemes", () => getSchemes(), {
    enabled: user.role === Role.admin,
    refetchOnWindowFocus: false,
  });
  const vendors = useActiveVendors();

  const onChangeCatalog = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();

    if (e.target.value === "All Seller Catalogs") {
      setCurrentConfig({ seller: "all" });
    } else if (e.target.value === "Zeoos Catalog") {
      setCurrentConfig({ seller: "" });
    } else {
      setCurrentConfig({
        seller: vendors.data?.find((x: any) => x.name === e.target.value)._id,
      });
    }
  };

  return (
    <>
      <div className="pim-main-tabs">
        <PimOpenTabs />
      </div>

      <div
        className="main-table-title-cont m0"
        style={{ paddingLeft: "25px", paddingRight: "40px" }}
      >
        <div className="mplc-filter">
          <button
            onClick={() => {
              setCreateProductModal(true);
            }}
            className="pim-title-button pim-green"
          >
            Create Product
          </button>

          {createProductModal && (
            <CreateProductModal
              modal={createProductModal}
              setModal={setCreateProductModal}
            />
          )}
          {/* <Link to="/pim-management" className="filter-text">
            Schemes
          </Link>
          <div className="ml2">
            <FormControl>
              <InputLabel
                className="pim-main-scheme-select-title"
                id="demo-simple-select-label"
              >
                Select scheme
              </InputLabel>
              <Select
                className="pim-main-schemes-select"
                id="select"
                value={""}
              >
                {schemes?.map((scheme: any, index: number) => (
                  <NavLink to={`/pim/scheme/${scheme.name}`} key={index}>
                    <MenuItem>{scheme.name}</MenuItem>
                  </NavLink>
                ))}
                <MenuItem onClick={() => setModal(true)}>+ New Scheme</MenuItem>
              </Select>
            </FormControl>
          </div>
          {modal && (
            <CreateSchemeForm
              modal={modal}
              setModal={setModal}
              onModalClose={(e: React.MouseEvent) => {
                e.stopPropagation();
                setModal(false);
              }}
            />
          )} */}
        </div>

        <div className="in-row align-center">
          <div className="align-center">
            <span className="filter-text mr1">Catalog:</span>
            {!vendors.isLoading ? (
              <div className="main-filter-select-container width200px">
                <select
                  className="main-filter-select"
                  onChange={(e) => onChangeCatalog(e)}
                  value={
                    currentConfig.seller === "all"
                      ? "All Seller Catalogs"
                      : vendors?.data?.find(
                        (x: any) => x._id === currentConfig.seller
                      )?.name || "Zeoos Catalog"
                  }
                >
                  {[
                    "All Seller Catalogs",
                    "Zeoos Catalog",
                    ...vendors?.data?.map((vendor: any) => vendor.name),
                  ]?.map((item: string, index: number) => (
                    <option key={index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <CircularPreloader />
            )}
          </div>

          {/* {batch && (
            <div className="in-row align-center">
              <div className="pim-batch-figure">
                <img
                  width="45px"
                  height="45px"
                  src="/icons/comment-cloud.svg"
                  alt=""
                />
                <span className="pim-batch-text">
                  {selectedProducts.length}
                </span>
              </div>
              <NavLink to="/batch-products?from=products">
                <div className="upload-batch-button in-row align-center ml1">
                  <img
                    className="mr1"
                    width="15px"
                    height="15px"
                    src="/icons/upload.svg"
                    alt=""
                  />
                  <div>Upload batch</div>
                </div>
              </NavLink>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export const PimOpenTabs: React.FC<any> = () => {
  const [tabs, setTabs] = useState([] as string[]);
  const [schemeTabs, setSchemeTabs] = useState([] as string[]);

  const history = useHistory();

  useEffect(() => {
    let count = 0;
    const handle = setInterval(() => {
      if (++count === 3) {
        clearInterval(handle);
      }

      setTabs(getOpenTabs());
      setSchemeTabs(getOpenSchemeTabs());
    }, 100);
  }, [history.location]);

  return (
    <Box sx={{ maxWidth: 2560, background: "#55718A" }}>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable auto tabs example"
        value={0}
      >
        <Tab
          style={{
            padding: "0 10px",
            minHeight: "50px",
            minWidth: "auto",
            display: "flex",
            alignItems: "center",
          }}
          iconPosition="end"
          className={`pim-main-tab ${history.location.pathname.startsWith("/catalog")
              ? "pim-main-tab-active"
              : ""
            }`}
          label="Products"
          to="/catalog/integrated"
          component={Link}
        />
        {tabs.map((sku: string) => {
          const productPath = `/productInfo/${sku}`;
          return (
            <Tab
              key={sku}
              style={{
                padding: "0px 10px 0px 25px",
                minHeight: "50px",
                minWidth: "auto",
                display: "flex",
                alignItems: "center",
              }}
              icon={
                <CancelRoundedIcon
                  className="pointer"
                  style={{ width: "20px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    removeOpenTab(sku);

                    const newTabs = tabs.filter((x) => x !== sku);
                    setTabs(newTabs);

                    if (history.location.pathname === productPath) {
                      history.push("/catalog/integrated");
                    }
                  }}
                />
              }
              iconPosition="end"
              className={`pim-main-tab ${history.location.pathname === productPath
                  ? "pim-main-tab-active"
                  : ""
                }`}
              label={sku}
              to={productPath}
              component={Link}
            />
          );
        })}
        {schemeTabs.map((id: string, index: number) => {
          const schemePath = `/pim/scheme/${id}`;
          return (
            <Tab
              style={{
                padding: "0px 10px 0px 25px",
                minHeight: "50px",
                minWidth: "auto",
                display: "flex",
                alignItems: "center",
              }}
              icon={
                <CancelRoundedIcon
                  className="pointer"
                  style={{ width: "20px" }}
                  onClick={(e) => {
                    e.preventDefault();
                    removeOpenSchemeTab(id);

                    const newTabs = schemeTabs.filter((x) => x !== id);
                    setSchemeTabs(newTabs);

                    if (history.location.pathname === schemePath) {
                      history.push("/catalog/integrated");
                    }
                  }}
                />
              }
              iconPosition="end"
              className={`pim-main-tab ${history.location.pathname === schemePath
                  ? "pim-main-tab-active"
                  : ""
                }`}
              label={id}
              to={schemePath}
              component={Link}
              key={index}
            />
          );
        })}
      </Tabs>
    </Box>
  );
};

export default withRouter(PimMain);
