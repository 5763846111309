import { useClickOutside } from "@mantine/hooks";
import { Modal } from "components";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Link, NavLink, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { downloadExampleFile } from "../../../../actions/onboardingSeller";
import { importNewProducts } from "../../../../actions/user";
import {
  exportProductResultsByLanguage,
  getSellersCatalog,
} from "../../../../actions/v2/product";
import useUser from "../../../../hooks/useUser";
import ConsultsEans from "../../../ui/consults-eans";
import FilterCategory from "../../../ui/filters/FilterCategory";
import { ExportAllProductInfo } from "../../pim/ExportAllProductInfo";
import { MultiLingualCreation } from "../../pim/MultiLingualCreation";
import { CatalogFilter, CatalogFilterProps } from "../filter";
import { Input } from "@mui/material";
import RoleRequired from "../../../utils/role-required/RoleRequired";
import { Role } from "../../../../interfaces";
import LanguageSelect from "../../../ui/language-select";

interface Props extends Partial<CatalogFilterProps> {
  label: string;
  children?: React.PropsWithChildren["children"];
}

const SubHeader: React.FC<Props> = ({
  label,
  currentConfig,
  setCurrentConfig,
  children,
}) => {
  const [modal, setModal] = useState<boolean>(false);
  const [isViewFilterCategory, setIsViewFilterCategory] = useState(false);
  const [filterName, setFilterName] = useState<string | undefined>(undefined);
  const [clearFilter, setClearFilter] = useState(false);
  const [consultEansModal, setConsultEansModal] = useState(false);
  const [createProductModal, setCreateProductModal] = useState(false);
  const [showExportForm, setShowExportForm] = useState(false);
  const [isExportingCatalog, setIsExportingCatalog] = useState(false);
  const [openExportModal, setOpenExportModal] = useState(false)
  const [selectedExportLang, setSelectedExportLang] = useState('pt')

  const history = useHistory();
  const [open, setOpen] = useState<boolean>(false);
  const dropdownRef = useClickOutside(() => {
    if (open) {
      setOpen(false);
    }
  });

  const { user } = useUser();
  const seller = user?.seller?._id;

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    history.push("/catalogs-upload-files-in-batch", {
      files: Array.from(event.target.files!),
    });
  };

  const onCatalogExport = async () => {
    if (isExportingCatalog) return;
    setIsExportingCatalog(true);
    try {
      await getSellersCatalog();
      toast.success("Catalog Exported Successfully, Please check you mail!");
    } catch (error) {
      console.log("Error exporting catalog", error);
      toast.error("An error occured, Please try again");
    } finally {
      setIsExportingCatalog(false);
    }
  };

  const onResultExport = () => {
    exportProductResultsByLanguage(seller, selectedExportLang)
    .then(() =>{
      toast.success(
        "Success! Check your mailbox in some minutes."
      )
      setOpenExportModal(false)
    }
    )
    .catch(() => toast.error("Something went wrong."));
  }

  return (
    <>
      <div
        style={{ paddingLeft: "0", paddingRight: "0" }}
        className="table-title-cont "
      >
        <div className="mplc-filter-cont mt1" style={{ display: "block" }}>
          <div
            className="gestao-select-cont justify-between"
            style={{ minHeight: "64px", alignItems: "center" }}
          >
            <span
              className="pim-language-text pl4"
              style={{ textTransform: "capitalize" }}
            >
              {label}
            </span>
            <div className="in-row align-center">
              {children}

              <div style={{ display: "flex" }}>
                {currentConfig && setCurrentConfig && (
                  <>
                    <FilterCategory
                      isViewFilter={isViewFilterCategory}
                      setIsViewFilter={setIsViewFilterCategory}
                      setCurrentConfig={setCurrentConfig}
                      setFilterName={setFilterName}
                      onClearFilter={clearFilter}
                    />

                    <CatalogFilter
                      currentConfig={currentConfig}
                      setCurrentConfig={setCurrentConfig}
                    />
                  </>
                )}
              </div>
              <div className="app-drop-down-container ml2" ref={dropdownRef}>
                <button
                  style={{ border: "1px solid #000", width: "120px" }}
                  className="main-select-bi no-shadow p1"
                  onClick={() => setOpen((p) => !p)}
                >
                  <img src="/icons/not-set-dotts.svg" alt="" />
                  <span className="email-text">More</span>
                  <img width="14px" src="/icons/down-arrow.png" alt="" />
                </button>

                {open && (
                  <ul
                    style={{
                      marginLeft: "-80px",
                      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.50)",
                      borderRadius: "5px",
                    }}
                  >
                    {/* <li
                      style={{ padding: "10px 5px", width: "200px" }}
                      className="in-row align-center not-menuitem-cont"
                    >
                      <span
                        className="not-menuitem-text"
                        onClick={() => setShowExportForm(true)}
                      >
                        Export products
                      </span>
                    </li> */}
                    <li
                      onClick={() => setOpenExportModal(true)}
                      style={{ padding: "10px 5px", width: "200px" }}
                      className="in-row align-center not-menuitem-cont"
                    >
                      <span className="not-menuitem-text">
                        Export page results
                      </span>
                    </li>

                    <RoleRequired role={Role.user} strict>
                      <li
                        onClick={onCatalogExport}
                        style={{
                          padding: "10px 5px",
                          width: "200px",
                        }}
                        className="in-row align-center not-menuitem-cont"
                      >
                        <span className="not-menuitem-text">
                          {isExportingCatalog
                            ? "Exporting..."
                            : "Export Catalog"}
                        </span>
                      </li>
                      <li
                        onClick={() => {
                          setModal(true);
                        }}
                        style={{
                          padding: "10px 5px",
                          width: "200px",
                        }}
                        className="in-row align-center not-menuitem-cont"
                      >
                        <span className="not-menuitem-text">
                          Add products to my catalog
                        </span>
                      </li>
                      <NavLink
                        className="width100"
                        to={`/my/catalog/reports?seller=${user.seller?._id}`}
                      >
                        <li
                          style={{
                            padding: "10px 5px",
                            width: "200px",
                          }}
                          className="in-row align-center not-menuitem-cont"
                        >
                          <span className="not-menuitem-text">
                            Catalog import reports
                          </span>
                        </li>
                      </NavLink>
                    </RoleRequired>

                    <RoleRequired>
                      <li
                        style={{ padding: "10px 5px", width: "200px" }}
                        className="in-row align-center not-menuitem-cont"
                      >
                        <span
                          className="not-menuitem-text"
                          onClick={() => setConsultEansModal(true)}
                        >
                          Consult EANs
                        </span>
                      </li>
                      <li
                        style={{ padding: "10px 5px", width: "200px" }}
                        className="in-row align-center not-menuitem-cont"
                      >
                        <span
                          className="not-menuitem-text"
                          onClick={() => setCreateProductModal(true)}
                        >
                          Translate via file
                        </span>
                      </li>
                      <li
                        style={{ padding: "10px 5px", width: "200px" }}
                        className="in-row align-center not-menuitem-cont"
                      >
                        <Link to="/batch-list">
                          <span className="not-menuitem-text">
                            Product export
                          </span>
                        </Link>
                      </li>
                      <li
                        style={{ padding: "10px 5px", width: "200px" }}
                        className="in-row align-center not-menuitem-cont"
                      >
                        <label className="not-menuitem-text">
                          Upload files in batch
                          <input
                            type="file"
                            multiple
                            onChange={handleFileChange}
                          />
                        </label>
                      </li>
                    </RoleRequired>
                  </ul>
                )}
              </div>
            </div>
          </div>
          {filterName && (
            <div
              style={{
                fontSize: "12px",
                display: "flex",
                alignItems: "center",
                marginBottom: "11px",
                marginLeft: "15px",
                color: "#9E9E9E",
              }}
            >
              <span>{filterName}</span>
              <button
                onClick={() => setClearFilter(!clearFilter)}
                style={{
                  background: "transparent",
                  boxShadow: "none",
                  gap: "5px",
                  color: "#ACACAC",
                  flexDirection: "row",
                  fontSize: "12px",
                }}
              >
                <img src="/icons/cross.svg" alt="cross icon" />
                Clear
              </button>
            </div>
          )}
        </div>
      </div>
      {createProductModal && (
        <MultiLingualCreation
          modal={createProductModal}
          setModal={setCreateProductModal}
        />
      )}

      {openExportModal && <Modal isOpened={openExportModal} onModalClose={() => setOpenExportModal(false)}>
        <div style={{ color: "black", padding: 10 }}>
          <p style={{ marginBottom: 12 }}>Export Page Results</p>
          <p style={{ textAlign: "start" }}>Select a language:</p>
          <LanguageSelect value={selectedExportLang} setValue={(lang) => setSelectedExportLang(lang)} />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <button onClick={() => setOpenExportModal(false)} className="table-modal-form-button-cancel">Cancel</button>
            <button
              onClick={onResultExport}
              className="file-batch-save-button"
            >
              Export
            </button>
          </div>
        </div>
      </Modal>
}
      {consultEansModal && (
        <ConsultsEans modal={consultEansModal} setModal={setConsultEansModal} />
      )}

      {showExportForm && (
        <ExportAllProductInfo
          modal={showExportForm}
          setModal={setShowExportForm}
        />
      )}

      <ImportNewProductsModal modal={modal} setModal={setModal} />
    </>
  );
};

export const ImportNewProductsModal: React.FC<{
  modal: boolean;
  setModal: (s: boolean) => void;
}> = ({ modal, setModal }) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>(null);
  const [files, setFiles] = useState<File[]>([]);

  const reset = useCallback(() => {
    setFiles([]);
    setData(null);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: [".xlsx"],
    onDropRejected: () => {
      toast.error('File type rejected. Only ".xlsx" files are allowed.');
    },
    onDropAccepted: (files) => {
      setFiles(files);
    },
  });

  const onSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);

      try {
        setData({ total: await importNewProducts(files[0]) });
        setFiles([]);
      } catch (error) {
        console.error(error);
        toast.error("Something went wrong. Please, verify your file's format");
      }

      setLoading(false);
    },
    [files]
  );

  useEffect(reset, [modal]);

  if (!modal) {
    return <></>;
  }

  return (
    <Modal
      onModalClose={(e: React.MouseEvent) => {
        e.stopPropagation();
        setModal(false);
      }}
      isOpened={modal}
    >
      <form className="table-modal-form-cont p4" onSubmit={onSubmit}>
        <div className="table-modal-title-box m0">
          <div className="import-title">Import new products</div>
        </div>
        <div className="table-modal-border" />

        {data?.total ? (
          <>
            <div className="import-title">
              {data.total} products have been detected.
            </div>
            <div className="email-text pt4 pb4">
              You will be notified when your products are imported to our
              system.
            </div>
          </>
        ) : (
          <>
            <span className="mt2 in-row width100 align-center">
              <b className="mr1">Upload the file</b>
              (Allowed file format .xlsx)
            </span>

            <div
              className="width100 pointer import-reviews-dropzone justify-start"
              style={{
                padding: "5px 10px",
                width: "400px",
                height: "150px",
                background: "",
                borderWidth: "1px",
                borderColor: "#c2c2c2",
                borderStyle: isDragActive ? "solid" : "dashed",
              }}
              {...getRootProps()}
            >
              {/* @ts-ignore */}
              <input {...getInputProps()} />
              {isDragActive ? (
                <h4>Drop the file here</h4>
              ) : (
                <span style={{ textAlign: "start" }}>
                  {!!files.length &&
                    `The file "${files[0].name}" has been selected.`}
                </span>
              )}
            </div>

            <div
              onClick={downloadExampleFile}
              className="bold mt2 in-row width100 align-center"
              style={{ color: "#55718a" }}
            >
              <div style={{ fontSize: "12px" }} className="pointer">
                Download Template Spreadsheet
              </div>
            </div>
          </>
        )}

        <div className="table-modal-border" />

        <div
          style={{ marginBottom: "0" }}
          className="table-modal-form-button-box width100"
        >
          <button
            className={`sel-modal-form-button-cancel ${
              data ? "width100 justify-center" : ""
            }}`}
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              setModal(false);
            }}
          >
            Cancel
          </button>

          {!data && (
            <button
              className="table-modal-form-button initial"
              type="submit"
              disabled={loading}
            >
              Import
            </button>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default SubHeader;
