import React, { useEffect, useState } from "react";
import LogoutWrap from "../../utils/logout-wrap/LogoutWrap";
import "./onboardingsteps.css";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { withStyles } from "@material-ui/core/styles";
import WhereSelling from "./where-selling/WhereSelling";
import MyOffers from "./my-offers/MyOffers";
import MyCatalogue from "./my-catalog/MyCatalogue";
import { Status } from "./Onboarding";
import useUser from "../../../hooks/useUser";
import WaitingArea from "./my-catalog/WaitingArea";

const AntTab = withStyles({
	root: {
		overflow: "auto !important",
		fontWeight: "bold",
	},
	label: {
		color: "#ACACAC",
		fontSize: "11px",
		textTransform: "uppercase",
	},
	selected: {
		color: "#F07300 !important",
	},
})(Tab);

const OnboardingSteps = () => {
	const { user } = useUser();

	const [value, setValue] = useState("1");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [productsData, setProductsData] = useState<any>();

	const handleChange = (event: React.SyntheticEvent, newValue: string) => {
		setValue(newValue);
	};

	useEffect(() => {
		if (user.seller?.importProductsStatus !== Status.accepted) {
			setValue("1");
		} else if (user.seller?.platformsStatus !== Status.accepted) {
			setValue("2");
		} else if (user.seller?.offersStatus !== Status.accepted) {
			setValue("3");
		} else {
			setValue("4");
		}
	}, [
		user.seller?.importProductsStatus,
		user.seller?.platformsStatus,
		user.seller?.offersStatus,
	]);

	return (
		<div
			style={{ background: "#e8edf2" }}
			className="onboadring-process-cont width100"
		>
			<div
				style={{ margin: "auto", background: "#fff" }}
				className="in-row pt2 pb2 pl4 pr4 align-center justify-between"
			>
				<img
					style={{ zIndex: "10000" }}
					src="/icons/zeoos-logo.svg"
					width="150px;"
					alt=""
				/>
				<LogoutWrap>
					<button
						style={{
							background: "transparent",
							boxShadow: "none",
							border: "none",
						}}
						className="logout-btn-onboarding"
					>
						<div>Log out</div>
					</button>
				</LogoutWrap>
			</div>
			<div className="contrato-bg pt4">
				{value === "1" && (
					<div className="catalogue-content-title">
						<span>
							We have up to <b style={{ color: "#F1692E" }}>11 million</b>{" "}
							registered products.
						</span>
					</div>
				)}
				<div className="onboarding-steps-title mb4">Just 3 steps to start.</div>
				<Box sx={{ width: "100%", typography: "body1", maxWidth: "880px" }}>
					<TabContext value={value}>
						<Box
							sx={{
								borderColor: "divider",
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
							}}
						>
							<TabList
								onChange={handleChange}
								aria-label="lab API tabs example"
							>
								<AntTab
									style={{ fontSize: "14px" }}
									className="no-shadow initial"
									label="&#9312; My catalog"
									value="1"
									disabled
								/>
								<img
									className="ml2 mr2"
									style={{ stroke: "#e2e2e2 !important" }}
									src="/icons/grey-arrow.svg"
									alt=""
								/>
								<AntTab
									style={{ fontSize: "14px" }}
									className="no-shadow initial"
									label="&#9313; Where selling"
									value="2"
									disabled
								/>
								<img
									className="ml2 mr2"
									style={{ stroke: "#e2e2e2 !important" }}
									src="/icons/grey-arrow.svg"
									alt=""
								/>
								<AntTab
									style={{ fontSize: "14px" }}
									className="no-shadow initial"
									label="&#9314; My offers"
									value="3"
									disabled
								/>
							</TabList>
						</Box>
						<div className="contrato-cont">
							{user.seller?.importProductsStatus !== Status.accepted && (
								<TabPanel className="width100 p3" value="1">
									<MyCatalogue
										isLoading={isLoading}
										setIsLoading={setIsLoading}
										setProductsData={setProductsData}
										setValue={setValue}
									/>
								</TabPanel>
							)}
							{user.seller?.platformsStatus !== Status.accepted && (
								<TabPanel className="width100 p3" value="2">
									<WhereSelling
										productsData={productsData}
										setValue={setValue}
									/>
								</TabPanel>
							)}
							{user.seller?.offersStatus !== Status.accepted && (
								<TabPanel className="width100 in-column" value="3">
									<MyOffers setValue={setValue} />
								</TabPanel>
							)}

							{user.seller?.offersStatus === Status.accepted && (
								<TabPanel className="width100 in-column" value="3">
									<WaitingArea mode="offer" />
								</TabPanel>
							)}
						</div>
					</TabContext>
				</Box>
			</div>
		</div>
	);
};

export default OnboardingSteps;
