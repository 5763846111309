import axios from "axios";
import { IPaginatedRequest, IProductsCounter } from "../interfaces";
import { getHeaders } from "./user";
import queryString from "query-string";
import FileDownload from "js-file-download";

export const getPaginatedOnboardingSellers = async (
  pagination: IPaginatedRequest
) => {
  const res = await axios.get(
    `/onboarding-sellers?${queryString.stringify(pagination)}`,
    getHeaders()
  );

  return res.data;
};

export async function createOnBoardingSeller(body: any) {
  await axios.post(`/onboarding-seller`, body, getHeaders());
}

export async function editOnBoardingSeller(body: any) {
  await axios.put(`/onboarding-seller/${body._id}`, body, getHeaders());
}

export async function deleteOnBoardingSeller(id: string) {
  await axios.delete(`/onboarding-seller/${id}`, getHeaders());
}

export async function setPricing(body: any) {
  await axios.put(`/onboarding-seller/pricing/${body._id}`, body, getHeaders());
}

export async function getTokenValidity(token: string) {
  const res = await axios.get(`/onboarding-seller/token-validity/${token}`);

  return res.data as string;
}

export async function createPassword(token: string, password: string) {
  await axios.post(`/onboarding-seller/create-password/${token}`, { password });
}

export async function releaseSeller(id: string) {
  await axios.post(`/onboarding-seller/${id}/release`, {}, getHeaders());
}

export async function updateBillingData(data: any) {
  await axios.post(`/onboarding-seller/billing-data`, data, getHeaders());
}

export async function subscribe(data: any) {
  const res = await axios.post(
    `/onboarding-seller/subscribe`,
    data,
    getHeaders()
  );
  return res.data;
}

export async function subscribeWithoutPlan(data: any) {
  const res = await axios.post(
    `/onboarding-seller/self/subscribe`,
    data,
    getHeaders()
  );
  return res.data;
}

export async function confirmSubscription3DS() {
  await axios.post(
    `/onboarding-seller/subscribe/confirm-3ds`,
    {},
    getHeaders()
  );
}

export async function readContract() {
  await axios.post(`/onboarding-seller/read-contract`, {}, getHeaders());
}

export async function downloadExampleFile() {
  const res = await axios.get(`/onboarding-seller/download-example-file`, {
    ...getHeaders(),
    responseType: "blob",
  });

  return FileDownload(res.data, "eans-example-file.xlsx");
}

export async function initializeOnboardingProductsImport(file: File) {
  const formData = new FormData();
  formData.append("file", file);

  const res = await axios.post(
    `/onboarding-seller/catalog-import-products`,
    formData,
    getHeaders()
  );

  return res.data as IProductsCounter;
}

export async function saveOnboardingProductsImportResults(platforms: string[]) {
  const res = await axios.post(
    `/onboarding-seller/save-import-products-results`,
    platforms,
    getHeaders()
  );

  return res.data;
}

export async function backToImportProducts() {
  const res = await axios.put(
    `/onboarding-seller/select-selling-platforms/back`,
    {},
    getHeaders()
  );

  return res.data;
}

export async function backToSelectSellingPlatforms() {
  const res = await axios.put(
    `/onboarding-seller/my-offers/back`,
    {},
    getHeaders()
  );

  return res.data;
}

export const getPaginatedUnregisteredProducts = async (
  pagination: IPaginatedRequest
) => {
  const res = await axios.get(
    `/onboarding-seller/unregistered-product-batches?${queryString.stringify(
      pagination
    )}`,
    getHeaders()
  );

  return res.data;
};

export async function sendCategorizationFile(id: string, file: File) {
  const formData = new FormData();
  formData.append("file", file);

  await axios.post(
    `/onboarding-seller/send-categorization-file/${id}`,
    formData,
    getHeaders()
  );
}

export async function downloadPriceStockData(
  mode: string = "pvp",
  reportId?: string
) {
  const res = await axios.get(
    `/marketplaceData/export${
      reportId ? `/report/${reportId}` : ""
    }?mode=${mode}`,
    {
      ...getHeaders(),
      // responseType: "blob",
    }
  );

  return res.data;
  // return FileDownload(res.data, `pricestock-${mode}.xlsx`);
}

export async function finishUnregisteredBatch(id: string) {
  const res = await axios.put(
    `/onboarding-seller/unregistered-products-batch/finish/${id}`,
    {},
    getHeaders()
  );

  return res.data;
}

export async function sendSellerFiles(id: string, files: File[]) {
  const formData = new FormData();

  for (const file of files) {
    formData.append("files", file);
  }

  await axios.post(
    `/onboarding-seller/send-seller-files/${id}`,
    formData,
    getHeaders()
  );
}

export async function downloadCategorizationFile(id: string, fileName: string) {
  const res = await axios.get(
    `/onboarding-seller/download-categorization-file/${id}`,
    {
      ...getHeaders(),
      responseType: "arraybuffer",
    }
  );

  return FileDownload(res.data, fileName);
}

export async function downloadSellerFile(
  id: string,
  fileId: string,
  fileName: string
) {
  const res = await axios.get(
    `/onboarding-seller/download-seller-file/${id}?${queryString.stringify({
      fileId,
    })}`,
    {
      ...getHeaders(),
      responseType: "arraybuffer",
    }
  );

  return FileDownload(res.data, fileName);
}

export const initializeOnboardingPricesImport = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("mode", "auto");
  const res = await axios.post(
    `/onboarding-seller/catalog-import-prices`,
    formData,
    getHeaders()
  );
  return res.data;
};
