import React, { useMemo } from "react";
import useUser from "../../../hooks/useUser";
import Contrato from "./contrato/Contrato";
import Payment from "./payment/Payment";
import OnboardingSteps from "./OnboardingSteps";

export enum Status {
	na = "Na",
	awaiting = "Awaiting acceptance",
	accepted = "Accepted",
}

const Onboarding: React.FC = () => {
	const { user } = useUser();

	const component = useMemo(() => {
		if (user.seller?.contractStatus !== Status.accepted) {
			return <Contrato />;
		}

		if (user.seller?.paymentStatus !== Status.accepted) {
			return <Payment />;
		}

		if (user.seller?.paymentStatus === Status.accepted) {
			return <OnboardingSteps />;
		}
	}, [user]);

	return (
		<main style={{ marginLeft: "0" }} className="content">
			{component}
		</main>
	);
};

export default Onboarding;
