import React, { useCallback, useEffect, useState } from "react";
import { Modal } from "../../ui/modal/Modal";
import { getMarketplaceProductImage } from "../../../actions/pim";
import { useIsAdmin } from "../../../hooks/useUser";
import { IPaginatedRequest } from "../../../interfaces";
import FilterSelect from "../../ui/select/FilterSelect";
import useCountries from "../../../hooks/useCountries";
import * as _ from 'lodash';
import { toast } from "react-toastify";
import { toggleProductStatus, useActiveProductPlatforms, useProductPlatforms, useProductStock } from "../../../actions/v2/product";
import { Product } from "types/product";
import RoleRequired from "../../utils/role-required/RoleRequired";

interface Props {
	product: Product;
	modal: boolean;
	setModal: (modal: React.SetStateAction<boolean>) => void;
	integratedChannels: string[];
}

const CatalogIntegratedChannelsModal: React.FC<Props> = ({
	product,
	modal,
	setModal,
}) => {
	const integratedChannels = useProductPlatforms(product.ean);
	const [modal1, setModal1] = useState<boolean>(false);

	const stock = useProductStock(product.sku);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<>
			<Modal onModalClose={onModalClose} isOpened={modal}>
				<div
					style={{ maxWidth: "600px", textAlign: "start" }}
					className="table-modal-form-cont width100 p4"
				>
					<div className="table-modal-title-box m0">
						<div className="import-title">{product.name}</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="in-row width100 align-center justify-start">
						<img
							width="120px"
							src={getMarketplaceProductImage(product)}
							alt=""
						/>
						<div className="in-column align-start">
							<span className="email-text">
								<b className="mr1">SKU:</b>
								{product.sku}
							</span>
							<span className="email-text">
								<b className="mr1 mt2 mb2">EAN:</b>
								{product.ean}
							</span>

							<span className="email-text">
								<b className="mr1">Stock:</b>
								{stock?.data}
							</span>
						</div>
					</div>
					<div className="integrated-channels-modal-grey-cont">
						{integratedChannels.data?.length} integrated sale channels
					</div>
					<div className="integrated-channels-modal-cont">
						{integratedChannels.data?.map(platform => (
							<div
								style={{ fontWeight: "500" }}
								className="integrated-channels-modal-box table-text mb1"
							>
								<img
									src={platform.image}
									alt=""
									width="80px"
									height="60px"
								/>
								<span className="ml2">{platform.name}</span>
                {platform.url &&
                  <a href={platform.url} target="_blank">
                    <img 
                      src="/icons/preview-link-blue.svg" 
                      className="pl1"
                      alt="" 
                      title="Extenal Link" 
                    />
                  </a>
                }
							</div>
						))}
					</div>
					<div className="table-modal-border"></div>
					<div className="in-row justify-start align-center">
						<button
							className="download-button pointer download-button-text"
							onClick={onModalClose}
						>
							Cancel
						</button>
						<button
							onClick={() => {
								setModal1(true);
							}}
							className="download-button ml2 pointer download-button-text"
						>
							Manage product platforms
						</button>
					</div>
				</div>
			</Modal>

			{modal1 && <TogglePlatformStatus
				product={product}
				modal={modal1}
				setModal={setModal1}
			/>}
		</>
	);
};

const TogglePlatformStatus: React.FC<{
	product: Product;
	modal: boolean;
	setModal: (modal: React.SetStateAction<boolean>) => void;
}> = ({ product, modal, setModal }) => {
	const integratedChannels = useActiveProductPlatforms(product.ean);

	const isAdmin = useIsAdmin();
	const [modal2, setModal2] = useState<boolean>(false);
	const [toggledAllMplc, setToggledAllMplc] = useState<Record<string, boolean>>({});
	const countries = useCountries();
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (!integratedChannels.data) {
			return;
		}

		setToggledAllMplc(integratedChannels.data.reduce((prev, curr) => {
			prev[curr.platformProductId] = !!curr.active;
			return prev;
		}, {} as Record<string, boolean>));
	}, [integratedChannels.data]);

	const [currentConfig, setCurrentConfig] = useState<IPaginatedRequest>({
		perPage: 50,
		page: 1,
		platformName: "All platforms",
		type: "All types",
		country: "All countries",
	});

	const onModalClose2 = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal2(false);
	};

	const handleChange =
		useCallback((platformProductId: string, status: boolean) => (event: React.MouseEvent) => {
			setToggledAllMplc((prev: any) => ({
				...prev,
				[platformProductId]: status,
			}));
		}, []);

	const handleChangeAll = useCallback(
		(status: boolean) => (event: React.MouseEvent) => {
			if (!integratedChannels.data) {
				return;
			}

			setToggledAllMplc(integratedChannels.data.reduce((prev, curr) => {
				prev[curr.platformProductId] = status;
				return prev;
			}, {} as Record<string, boolean>));
		},
		[integratedChannels.data]
	);

	const handleSave = useCallback(async (e: React.MouseEvent) => {
		e.preventDefault();
		setLoading(true);

		try {
			await Promise.all(
				Object.keys(toggledAllMplc).map(async (platformProductId) => {
					try {
						await toggleProductStatus(platformProductId, toggledAllMplc[platformProductId]);
					} catch (error) {
						console.error(error);
					}
				})
			);

			toast.success("Product successfully saved!");

			integratedChannels.invalidate();;
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong! Please, reload the page");
		}

		setLoading(false);
	}, [toggledAllMplc, integratedChannels]);

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<>
			<Modal onModalClose={onModalClose} isOpened={modal}>
				<div
					style={{ maxWidth: "600px", width: "600px" }}
					className="table-modal-form-cont p4"
				>
					<div className="in-row width100 align-center justify-start">
						<img width="120px" src={getMarketplaceProductImage(product)} alt="" />
						<div className="in-column align-start">
							<span className="email-text">
								<b className="mr1">
									{product.name}
								</b>
							</span>
							<span className="email-text">
								<b className="mr1 mt2 mb2">SKU:</b>
								{product.sku}
							</span>
							<span className="email-text">
								<b className="mr1">EAN:</b>
								{product.ean}
							</span>
						</div>
					</div>
					<div className="table-modal-border"></div>
					<div className="table-modal-title-box m0">
						<div className="import-title">
							Manage selected product platforms:
						</div>
					</div>

					<div className="width100 mt4 mb2 in-row align-center">
						<FilterSelect
							onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
								setCurrentConfig((conf: IPaginatedRequest) => ({
									...conf,
									country: e.target.value,
								}))
							}
							value={currentConfig?.country}
							data={[
								"All countries",
								..._.uniq(_.map(countries, (item: any) => item.name)),
							]}
						/>
						<FilterSelect
							onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) =>
								setCurrentConfig((conf: IPaginatedRequest) => ({
									...conf,
									platformName: e.target.value,
								}))
							}
							value={currentConfig?.platformName}
							data={[
								"All platforms",
								...integratedChannels.data?.map((item: any) => item.name) || [],
							]}
						/>
					</div>
					<div>
						<table
							style={{ fontSize: "16px" }}
							className="mapping-modal-table gestao-title-text"
						>
							<thead
								style={{ backgroundColor: "#5A5A5A; !important" }}
								className="in-column justify-center"
							>
								<tr
									style={{
										width: "100%",
										borderBottom: "1px solid #e1e1e1",
										margin: "auto !important",
										background: "#F2F2F2",
									}}
									className="mapping-modal-results-title justify-between m0 pt2 pb2"
								>
									<th className="modal-en-dis-table-platforms">
										{integratedChannels.data?.length} available platforms
									</th>
									<th className="modal-en-dis-table-status">Status</th>
								</tr>
							</thead>

							<tbody
								style={{ maxHeight: "300px", overflow: "auto" }}
								className="in-column justify-start"
							>
								{integratedChannels.data?.map(
									(marketplace) => (
										<tr
											key={marketplace.platformId}
											style={{
												width: "100%",
												borderBottom: "1px solid #e1e1e1",
												margin: "auto !important",
											}}
											className="mapping-modal-result-box justify-between pt1 pb1 platform-list"
										>
											<td className="modal-en-dis-table-platforms">
												<img
													className="mr2"
													width="32px"
													src={
														countries?.find(
															country =>
																country.name === marketplace.country
														)?.iconUrl
													}
													alt=""
												/>
												{marketplace.name}
											</td>
											<td className="modal-en-dis-table-status">
												<div className="in-row">
													<div
														className={`in-row mr5 ${isAdmin ? 'pointer' : ''}`}
														onClick={handleChange(
															marketplace.platformProductId,
															false
														)}
													>
														<img
															className="mr1"
															width="20px"
															height="20px"
															src={`${toggledAllMplc[marketplace.platformProductId] ===
																false
																? "/icons/reject-icon-active.svg"
																: "/icons/reject-icon.svg"
																}`}
															alt=""
														/>
														<div
															className={`bold ${toggledAllMplc[marketplace.platformProductId] ===
																false
																? "red"
																: "grey"
																}`}
														>
															{toggledAllMplc[marketplace.platformProductId] === false
																? "Disabled"
																: "Disable"}
														</div>
													</div>
													<div
														className={`in-row ${isAdmin ? 'pointer' : ''}`}
														onClick={handleChange(marketplace.platformProductId, true)}
													>
														<img
															className="mr1"
															width="20px"
															height="20px"
															src={`${toggledAllMplc[marketplace.platformProductId] === true
																? "/icons/accept-icon-active.svg"
																: "/icons/accept-icon.svg"
																}`}
															alt=""
														/>
														<div
															className={`bold ${toggledAllMplc[marketplace.platformProductId] === true
																? "green"
																: "grey"
																}`}
														>
															{toggledAllMplc[marketplace.platformProductId] === true
																? "Enabled"
																: "Enable"}
														</div>
													</div>
												</div>
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</div>

					<div className="table-modal-border"></div>

					<div
						style={{ marginBottom: "0" }}
						className="table-modal-form-button-box width100"
					>
						<button
							className="download-button pointer download-button-text"
							onClick={onModalClose}
						>
							Cancel
						</button>

						<RoleRequired>
							<div className="in-row justify-end align-center">
								<button
									className="download-button pointer download-button-text"
									onClick={handleChangeAll(true)}
								>
									Enable all
								</button>
								<button
									className="download-button pointer ml1 mr1 download-button-text"
									onClick={handleChangeAll(false)}
								>
									Disable all
								</button>
								<button
									onClick={() => {
										setModal2(true);
									}}
									className="table-modal-form-button initial"
								>
									Save
								</button>
							</div>
						</RoleRequired>
					</div>
				</div>
			</Modal>

			{modal2 && (
				<Modal onModalClose={onModalClose2} isOpened={modal2}>
					<div
						style={{ maxWidth: "400px" }}
						className="table-modal-form-cont p4"
					>
						<div className="updateForm__container">
							<div className="vendor-mapping-modal-text">
								<strong>
									Are you sure you want to save all changes for the selected
									products?
								</strong>
							</div>
							<div className="table-modal-border"></div>
							<div className="table-modal-form-button-box width100 m0">
								<button
									className="sel-modal-form-button-cancel"
									onClick={onModalClose2}
								>
									Cancel
								</button>
								<button
									disabled={loading}
									onClick={handleSave}
									className="table-modal-form-button initial pointer"
									style={{
										opacity: loading ? 0.5 : 1,
									}}
								>
									Yes, save all
								</button>
							</div>
						</div>
					</div>
				</Modal>
			)}
		</>
	);
};

export default CatalogIntegratedChannelsModal;
