import React, { useCallback, useEffect, useState } from "react";
import "./payment.css";
import useUser from "../../../../hooks/useUser";
import {
	subscribe,
	updateBillingData,
	confirmSubscription3DS,
	subscribeWithoutPlan,
} from "../../../../actions/onboardingSeller";
import { usePricingPlan } from "../../../../actions/stripeProduct";
import { toast } from "react-toastify";
import LogoutWrap from "../../../utils/logout-wrap/LogoutWrap";

export enum ZeoosPLan {
	jumpStart = "Jump Start",
	riseScale = "Rise & Scale",
	ultimate = "Ultimate",
}

export enum Subscription {
	monthly = "Monthly",
	yearly = "Yearly",
}

const Payment = () => {
	const { user, invalidate } = useUser();
	const [loading, setLoading] = useState(false);
	const [switchPeriod, setSwithPeriod] = useState<string>(
		user.seller.subscription
	);
	const [value, setValue] = useState<string>(user.seller.zeoosPlan);
	// const [coupon, setCoupon] = useState<string>("");
	// const isCouponValid = true;

	const [confirmed, setConfirmed] = useState(user.seller?.billingDataConfirmed);

	const [billingData, setBillingData] = useState({
		address: user.seller?.fiscalAddress.address,
		city: user.seller?.fiscalAddress.city,
		country: user.seller?.fiscalAddress.country,
		postalCode: user.seller?.fiscalAddress.postalCode,
		region: user.seller?.fiscalAddress.region,
		email: user.seller?.contactInfo.emailForBillings,
	});

	const handleConfirm = useCallback(async () => {
		if (confirmed) {
			return;
		}

		try {
			await updateBillingData(billingData);

			setConfirmed(true);

			toast.success("Data confimed. Now add your credit card");
		} catch (error) {
			toast.error("Something went wrong...");
		}
	}, [billingData, confirmed]);

	const currentPlan = usePricingPlan(user.seller?.stripePriceId);

	const [card, setCard] = useState({
		number: "",
		exp: "",
		cvc: "",
		name: "",
	});

	const handleSubscribe = useCallback(async () => {
		if (!confirmed) {
			return;
		}

		try {
			const [exp_month, exp_year] = card.exp.split("/");

			setLoading(true);
			const data = await subscribeWithoutPlan({
				number: card.number.replaceAll(" ", ""),
				cvc: card.cvc,
				exp_month,
				exp_year,
				zeoosPlan: value,
				interval: switchPeriod,
			});

			if (data.url) {
				window.location.replace(data.url);
			}

			toast.success("Perfect! Now let's finish setting up your catalog...");

			invalidate();
		} catch (error) {
			toast.error("Something went wrong...");
		} finally {
			setLoading(false);
		}
	}, [card, confirmed, invalidate]);

	useEffect(() => {
		if (!user.seller?.stripeCardId) {
			return;
		}

		async function main() {
			setLoading(true);
			try {
				await confirmSubscription3DS();
				invalidate();
			} catch (error) {
				console.error(error);
			}
			setLoading(false);
		}

		main();
	}, [user.seller?.stripeCardId]);

	const formatCardNum: any = useCallback(
		(v: string) => {
			setCard((prev) => ({
				...prev,
				number: v
					.replace(/\D/g, "")
					.replaceAll(" ", "")
					.split(/(\d{4})/)
					.filter((w: string) => w.length > 0)
					.join(" "),
			}));
		},
		[setCard]
	);

	const formatExpDate = useCallback(
		(v: string) => {
			setCard((prev) => ({
				...prev,
				exp: v
					.replaceAll("/", "")
					.split(/(\d{2})/)
					.filter((w: string) => w.length > 0)
					.join("/"),
			}));
		},
		[setCard]
	);

	return (
		<div
			style={{ background: "#e8edf2" }}
			className="onboadring-process-cont width100"
		>
			<div
				style={{ margin: "auto", background: "#fff" }}
				className="in-row pt2 pb2 pl4 pr4 align-center justify-between"
			>
				<img style={{ zIndex: "10000" }} src="/icons/zeoos-logo.svg" width="150px;" alt="" />
				<LogoutWrap>
					<button
						style={{
							background: "transparent",
							boxShadow: "none",
							border: "none",
						}}
						className="logout-btn-onboarding"
					>
						<div>Log out</div>
					</button>
				</LogoutWrap>
			</div>
			<div className="in-row items-center justify-center pr9">
				{loading ? (
					<div className="loading-area">Loading...</div>
				) : (
					<div className="payment-cont">
						<div className="payment-box">
							<span className="payment-text">Billing information</span>
							<div className="payment-container">
								<div className="in-column align-start width100 justify-center mb2">
									<span className="payment-form-text">Company Name:</span>
									<input
										className="payment-input"
										value={user.seller?.name}
										disabled
									/>
								</div>
								<div className="in-column align-start width100 justify-center mb2">
									<span className="payment-form-text">VAT number (NIF):</span>
									<input
										className="payment-input"
										value={user.seller?.contactInfo.vatNumber}
										disabled
									/>
								</div>
								<div className="in-column align-start width100 justify-center mb2">
									<span className="payment-form-text">Email for Billings:</span>
									<input
										className="payment-input"
										value={billingData.email}
										onChange={(e) =>
											setBillingData((prev) => ({
												...prev,
												email: e.target.value,
											}))
										}
									/>
								</div>
								<div className="in-row width100 align-center justify-between">
									<div className="in-column align-start width45 justify-center mb2">
										<span className="payment-form-text">Country:</span>
										<input
											className="payment-input"
											value={billingData.country}
											onChange={(e) =>
												setBillingData((prev) => ({
													...prev,
													country: e.target.value,
												}))
											}
										/>
									</div>
									<div className="in-column align-start width45 justify-center mb2">
										<span className="payment-form-text">Postal Code:</span>
										<input
											className="payment-input"
											value={billingData.postalCode}
											onChange={(e) =>
												setBillingData((prev) => ({
													...prev,
													postalCode: e.target.value,
												}))
											}
										/>
									</div>
								</div>
								<div className="in-row width100 align-center justify-between">
									<div className="in-column align-start width45 justify-center mb2">
										<span className="payment-form-text">State / Region:</span>
										<input
											className="payment-input"
											value={billingData.region}
											onChange={(e) =>
												setBillingData((prev) => ({
													...prev,
													region: e.target.value,
												}))
											}
										/>
									</div>
									<div className="in-column align-start width45 justify-center mb2">
										<span className="payment-form-text">City:</span>
										<input
											className="payment-input"
											value={billingData.city}
											onChange={(e) =>
												setBillingData((prev) => ({
													...prev,
													city: e.target.value,
												}))
											}
										/>
									</div>
								</div>
								<div className="in-column align-start width100 justify-center mb2">
									<span className="payment-form-text">Address:</span>
									<input
										className="payment-input"
										value={billingData.address}
										onChange={(e) =>
											setBillingData((prev) => ({
												...prev,
												address: e.target.value,
											}))
										}
									/>
								</div>
								<button
									onClick={handleConfirm}
									className="confirm-btn mt4"
									disabled={confirmed}
								>
									Confirm
								</button>
							</div>
						</div>

						<div className="payment-box">
							<span className="payment-text">Payment method</span>
							<div className="payment-container">
								<div className="width100 in-row align-center justify-between">
									<span className="payment-text">Credit card</span>
									<img src="/icons/payments-ways.svg" alt="" />
								</div>
								<div className="payment-border"></div>
								<div className="in-column align-start width100 justify-center mb2">
									<span className="payment-form-text">Card number:</span>
									<input
										maxLength={19}
										disabled={!confirmed}
										className="payment-input"
										value={card.number}
										onChange={(e) => formatCardNum(e.target.value)}
									/>
								</div>
								<div className="in-row width100 align-center justify-between">
									<div className="in-column align-start width45 justify-center mb2">
										<span className="payment-form-text">Expiration date:</span>
										<input
											maxLength={5}
											disabled={!confirmed}
											className="payment-input"
											placeholder="12/24"
											value={card.exp}
											onChange={(e) => formatExpDate(e.target.value)}
										/>
									</div>
									<div className="in-column align-start width45 justify-center mb2">
										<span className="payment-form-text">Security code:</span>
										<input
											maxLength={3}
											disabled={!confirmed}
											className="payment-input"
											placeholder="123"
											value={card.cvc}
											onChange={(e) =>
												setCard((prev) => ({
													...prev,
													cvc: e.target.value.replace(/\D/g, ""),
												}))
											}
										/>
									</div>
								</div>
								{/* <div className="in-column align-start width100 justify-center mb2">
								<span className="payment-form-text">
									Name of the card owner
								</span>
								<input
									disabled={!confirmed}
									className="payment-input"
									placeholder="John Doe"
									value={card.name}
									onChange={(e) =>
										setCard((prev) => ({
											...prev,
											name: e.target.value.replace(/[^A-Za-z\s!?]/g, ""),
										}))
									}
									required
								/>
							</div> */}
							</div>
						</div>

						<div className="payment-box">
							<span className="payment-text">Choose a plan</span>
							<div className="payment-container">
								<div className="payment-plans-swither">
									<div className="payment-plans-swither-cont">
										<div
											className={`payment-plans-swither-text-cont ${switchPeriod === "Monthly" &&
												"payment-plans-swither-text-active"
												}`}
											onClick={() => setSwithPeriod(Subscription.monthly)}
										>
											<div className="payment-plans-swither-text">Monthly</div>
										</div>
										<div
											className={`payment-plans-swither-text-cont ${switchPeriod === "Yearly" &&
												"payment-plans-swither-text-active"
												}`}
											onClick={() => setSwithPeriod(Subscription.yearly)}
										>
											<div className="payment-plans-swither-text">Yearly</div>
										</div>
									</div>
									<div className="ml1">
										<img
											className=""
											width={65}
											height={30}
											src="/icons/20-off.svg"
											alt=""
										/>
									</div>
								</div>
								<div className="payment-plans-options-cont">
									<SelectPlanComponent
										setValue={setValue}
										switchPeriod={switchPeriod}
										flag={ZeoosPLan.ultimate}
										value={value}
										label={ZeoosPLan.ultimate}
										price={
											switchPeriod === Subscription.yearly ? "159,00" : "199,00"
										}
										annualPrice={"1.908"}
										oldPrice={"2.388"}
									/>
									<SelectPlanComponent
										setValue={setValue}
										switchPeriod={switchPeriod}
										flag={ZeoosPLan.riseScale}
										value={value}
										label={ZeoosPLan.riseScale}
										price={
											switchPeriod === Subscription.yearly ? "79,00" : "99,00"
										}
										annualPrice={"948"}
										oldPrice={"1.188"}
									/>
									<SelectPlanComponent
										setValue={setValue}
										switchPeriod={switchPeriod}
										flag={ZeoosPLan.jumpStart}
										value={value}
										label={ZeoosPLan.jumpStart}
										price={
											switchPeriod === Subscription.yearly ? "39,00" : "49,00"
										}
										annualPrice={"368"}
										oldPrice={"488"}
									/>
								</div>
								<div className="payment-border"></div>
								{/* <div className="in-column align-start width100 justify-center mb2">
								{!coupon ? (
									<>
										<div className="payment-form-text">
											If you have a discount coupon, add it below:
										</div>
										<div className="in-row width100 height50px mt2 align-center">
											<input
												className="coupon-input width70"
												value={coupon}
												onChange={(e) => setCoupon(e.target.value)}
											/>
											<div className="width40 coupon-btn" onClick={() => {}}>
												Add coupon
											</div>
										</div>
									</>
								) : (
									<>
										<div className="width100 in-row align-center justify-between mb2">
											<div>
												<span className="coupon-text">{coupon}</span>
												<span className="coupon-added-text"> added</span>
											</div>
											<div
												className="coupon-remove-text pointer"
												onClick={() => setCoupon("")}
											>
												remove
											</div>
										</div>
										<div className="coupon-added-plan">
											<PlanComponent
												switchPeriod={switchPeriod}
												flag={""}
												value={value}
												label={"Jump Start"}
												price={"39,00"}
												annualPrice={"368"}
												oldPrice={"488"}
												coupon={true}
											/>
										</div>
									</>
								)}
							</div> */}

								<button className="confirm-btn mt4" onClick={handleSubscribe}>
									Confirm and Pay
								</button>
								{switchPeriod === Subscription.yearly && (
									<span className="mt4 payment-plans-subscription-text">
										Your subscription will automatically renew every{" "}
										{currentPlan?.interval} with a payment of{" "}
										{currentPlan?.price}. You can cancel your subscription at
										any time by contacting your account manager.
									</span>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

interface SelectPlanComponentProps {
	setValue: React.Dispatch<React.SetStateAction<string>>;
	switchPeriod: string;
	flag: string;
	value: string;
	label: string;
	price: string;
	annualPrice: string;
	oldPrice: string;
}

const SelectPlanComponent: React.FC<SelectPlanComponentProps> = ({
	setValue,
	switchPeriod,
	flag,
	value,
	label,
	price,
	annualPrice,
	oldPrice,
}) => {
	return (
		<div
			className="payment-plans-option pointer"
			onClick={() => setValue(flag)}
		>
			<div
				style={{
					border: `2px solid ${value === flag ? "#f4733c" : "#acacac"}`,
					padding: "4px",
					borderRadius: "100%",
					marginRight: "15px",
				}}
			>
				<div
					className={`billing-ball ${value === flag && "pricing-orangebg"}`}
				></div>
			</div>
			<PlanComponent
				switchPeriod={switchPeriod}
				flag={flag}
				value={value}
				label={label}
				price={price}
				annualPrice={annualPrice}
				oldPrice={oldPrice}
			/>
		</div>
	);
};

interface PlanComponentProps {
	switchPeriod: string;
	flag: string;
	value: string;
	label: string;
	price: string;
	annualPrice: string;
	oldPrice: string;
	coupon?: boolean;
}

const PlanComponent: React.FC<PlanComponentProps> = ({
	switchPeriod,
	flag,
	value,
	label,
	price,
	annualPrice,
	oldPrice,
	coupon,
}) => {
	return (
		<div className="payment-plans-plan">
			<div>
				<div
					className={`payment-plans-plan-text ${value === flag && "pricing-orange"
						} ${coupon && "coupon-plan-text"}`}
				>
					{label}
				</div>
				<div className="payment-plans-plan-subs-text">
					{switchPeriod === "Yearly"
						? "Annual subscription"
						: "(12 month loyalty)"}
				</div>
			</div>
			<div>
				<div
					className={`in-row ${value === flag && "orange-text"} ${coupon && "coupon-plan-text"
						}`}
				>
					<span className="payment-plans-plan-price mr1">{price} EUR</span>
					<span className="payment-plans-plan-period">/ month</span>
				</div>
				<div
					className={`in-row justify-end ${switchPeriod !== "Yearly" && "opacity0"
						}`}
				>
					<span className="payment-plans-plan-actual-price mr1">
						{annualPrice}€
					</span>
					<span className="payment-plans-plan-old-price">{oldPrice}€</span>
				</div>
			</div>
		</div>
	);
};

export default Payment;
