import React, { useDeferredValue, useEffect, useState } from "react";
import SettingsNav from "../../../ui/settings-nav/SettingsNav";
import { styled } from '@mui/material/styles';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
    AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { getNotificationImage } from "../Notifications";
import useUser from "../../../../hooks/useUser";
import { capitalize } from "lodash";
import { updateUser } from "../../../../actions/user";
import { toast } from "react-toastify";

export const TYPES = [
    "ORDERS",
    "PRICE_AND_STOCK_UPDATES",
    "INTEGRATED_PRODUCT",
    "IMPORTED_PRODUCT",
    "BUYBOX",
    "RANKING_ZEOOS",
    "DEFAULT_NOTIFICATION",
] as const;

const Accordion = styled((props: AccordionProps) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function getStatusLabel(s: any) {
    const allowed = [];

    if (s.platform) {
        allowed.push('Zeoos platform');
    }
    if (s.email) {
        allowed.push('Email');
    }
    // if (s.push) {
    //     allowed.push('Push');
    // }
    // if (s.sms) {
    //     allowed.push('SMS');
    // }

    return allowed.length ? allowed.join(', ') : 'None';
}

export default function NotificationsSettings() {
    const { user, invalidate } = useUser();

    const [notificationEmail, setNotificationEmail] = useState(user.notificationEmail || user.email);
    const [notificationPhone, setNotificationPhone] = useState(user.notificationPhone);

    return (
        <div className="main-body">
            <div className="main-container">
                <SettingsNav />
                <div className="table-main-cont">
                    <div style={{ fontSize: "16px" }}>Zeoos may still send you important notification alerts other than your preferred notification settings. By disabling notifications, you will no longer receive alerts, but all events will still be listed.</div>
                    <div className="mt4">
                        <span className="not-set-title">What notifications do you receive</span>
                        <div className="mt2">
                            {TYPES.map(t => (
                                <PreferenceSelector key={t} t={t} />
                            ))}
                        </div>

                        <div style={{ paddingTop: "50px", marginBottom: "30px" }}>
                            <span className="not-set-title">How do you want to receive notifications</span>
                            <div className="mt2">
                                <div className="not-set-white-cont">
                                    {/* <div className="in-row align-center mt1 mb1 justify-start">
                                        <span className="not-set-label-title">Push:</span>
                                        <div className="in-row align-center mt1 mb1 justify-start">
                                            <img src="/icons/not-set-google.svg" alt="" />
                                            <b className="ml4">Chrome push notifications</b>
                                        </div>
                                    </div>
                                    <div className="in-row align-center mt1 mb1 justify-start">
                                        <span className="not-set-label-title"></span>
                                        <FormControlLabel control={<Checkbox defaultChecked />} label="Play sound when receiving notification" />
                                    </div> */}
                                    <div className="in-row align-center mt1 mb1 justify-start">
                                        <span className="not-set-label-title">Email:</span>
                                        <input
                                            style={{ minWidth: "300px" }}
                                            placeholder="name@hotmail.com"
                                            className="main-select-bi"
                                            onChange={e => setNotificationEmail(e.target.value)}
                                            value={notificationEmail}
                                        />
                                    </div>
                                    {/* <div className="in-row align-center mt1 mb1 justify-start">
                                        <span className="not-set-label-title">SMS:</span>
                                        <input
                                            style={{ minWidth: "200px" }}
                                            placeholder="+351 211 653 380"
                                            className="main-select-bi"
                                            onChange={e => setNotificationPhone(e.target.value)}
                                            value={notificationPhone}
                                        />
                                    </div> */}
                                    <div className="in-row align-center mt1 mb1 justify-start">
                                        <span className="not-set-label-title">Language:</span>
                                        <select style={{ minWidth: "300px" }}
                                            className="main-select-bi"
                                        >
                                            <option className="compa-option" value="">
                                                English
                                            </option>
                                        </select>
                                    </div>

                                    <div className="tax-title-button pointer mr2" style={{ width: 200 }} onClick={async () => {
                                        try {
                                            await updateUser(user._id, { notificationEmail, notificationPhone } as any);
                                            invalidate();
                                            toast.success('Information has been updated!');
                                        } catch (error) {
                                            console.error(error);
                                        }
                                    }}>
                                        <div
                                            className="pim-avaliacoes-title-button-text p0"
                                        >
                                            Save
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const PreferenceSelector: React.FC<{ t: typeof TYPES[number]; }> = ({ t }) => {
    const [state, setState] = useState(false);
    const { user, invalidate } = useUser();
    const [notificationSettings, setNotificationSettings] = useState(user.notificationSettings);
    const deferred = useDeferredValue(notificationSettings);
    const [hide, setHide] = useState(false);

    const toggleHide = () => {
		setHide(!hide);
	};

    useEffect(() => {
        updateUser(user._id, { notificationSettings: deferred } as any)
            .then(invalidate)
            .catch(console.error);
    }, [user._id, deferred]);

    return (
        <Accordion
            key={t}
            style={{
                borderRadius: "5px",
                border: "1px solid #ACACAC",
                boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.25)",
                marginBottom: 10
            }}
            expanded={state}
            onChange={() => setState(s => !s)}
        >
            <AccordionSummary onClick={toggleHide} aria-controls="panel1d-content" id="panel1d-header">
                <div className="in-row width100 align-center justify-between">
                    <div className="in-row align-center email-text justify-start">
                        <img src={getNotificationImage(t as any)} alt="" />
                        <div className="in-column justify-center align-start ml2">
                            <b>{capitalize(t.replaceAll('_', ' '))}</b>
                            <span className="mt1">{getStatusLabel(notificationSettings[t])}</span>
                        </div>
                    </div>
                    {/* <img src="/icons/arrow-down-set-not.svg" alt="" /> */}
                    <img src={hide ? "/icons/vendors-arrow-up.svg" : "/icons/vendors-arrow-down.svg"} alt="" />
                </div>
            </AccordionSummary>

            <AccordionDetails className="email-text">
                <div className="in-column justify-center align-start">
                    <span className="mb4">These notifications are about incoming new orders and status changes.</span>
                    <b style={{ fontSize: "14px" }}>Where do you get these notifications</b>
                    <div className="in-row align-center mt2 mb2 justify-start">
                        <AntSwitch onChange={e => setNotificationSettings((prev: any) => ({ ...prev, [t]: { ...notificationSettings[t], platform: e.target.checked } }))} checked={notificationSettings[t].platform} />
                        <span className="ml4">Zeoos platform</span>
                    </div>
                    {/* <div className="in-row align-center mt2 mb2 justify-start">
                        <AntSwitch onChange={e => setNotificationSettings((prev: any) => ({ ...prev, [t]: { ...notificationSettings[t], push: e.target.checked } }))} checked={notificationSettings[t].push} />
                        <span className="ml4">Push</span>
                    </div> */}
                    <div className="in-row align-center mt2 mb2 justify-start">
                        <AntSwitch onChange={e => setNotificationSettings((prev: any) => ({ ...prev, [t]: { ...notificationSettings[t], email: e.target.checked } }))} checked={notificationSettings[t].email} />
                        <span className="ml4">Email</span>
                    </div>
                    {/* <div className="in-row align-center mt2 mb2 justify-start">
                        <AntSwitch onChange={e => setNotificationSettings((prev: any) => ({ ...prev, [t]: { ...notificationSettings[t], sms: e.target.checked } }))} checked={notificationSettings[t].sms} />
                        <span className="ml4">SMS</span>
                    </div> */}
                </div>
            </AccordionDetails>
        </Accordion>
    );
};
