import axios from "axios";
import { getHeaders } from "./user";
import * as T from "types/stripeProduct";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { formatValue } from "../components/routes/pricing/Pricing";

export async function getStripeProducts() {
	const res = await axios.get(`/stripe-products`, getHeaders());
	return res.data as T.StripeProduct[];
}

export function usePricingPlan(stripePriceId: string) {
  const plans = useQuery(`stripe-products`, getStripeProducts, {
    refetchOnWindowFocus: false,
  });
  const [data, setData] = useState<{
    stripeId: string;
    name: string;
    interval: T.Interval;
    price: string;
  }>();

  useEffect(() => {
    if (!plans.data) {
      return;
    }

    for (const p of plans.data) {
      const price = p.prices.find((pr) => pr.stripeId === stripePriceId);

      if (!price) {
        continue;
      }

      setData({
        name: p.name,
        interval: price.interval,
        price: `${formatValue(price.price)} ${price.currency}`,
        stripeId: p.stripeId,
      });

      break;
    }
  }, [plans.data, stripePriceId]);

  return data;
}
