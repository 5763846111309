import React, { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import Button from "@mui/material/Button";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import StepProducts from "./StepProducts";
import StepPlatforms from "./StepPlatforms";
import StepUpload from "./StepUpload";
import { useAtomValue } from "jotai";
import { selectedProductsAtom } from "./Gestao";
import { toast } from "react-toastify";
import { createBatch } from "../../../../actions/batch";
import { IMarketplace } from "../../../../interfaces";
import { useQuery } from "react-query";
import { getSanitizedMarketplaces } from "../../../../actions/marketplace";
import Preloader from "../../../ui/preloader/Preloader";
import { ID } from "shared";

const theme = createTheme({
	components: {
		MuiStepButton: {
			styleOverrides: {
				root: {
					padding: "0 10px !important",
					display: "flex !important",
					flexDirection: "row",
					alignItems: "center",
					height: "100%",
					boxShadow: "none",
					minHeight: "100%",
					maxHeight: "100%",
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				root: {
					marginTop: "0px",
					height: "100%",
					// background: "#1B1B1B !important",
					padding: "0 20px",
				},
				label: {
					display: "flex !important",
					flexDirection: "row",
				},
				iconContainer: {
					display: "none !important",
				},
			},
		},
	},
});

const steps = ["Platforms", "Confirmation"];

const BatchProducts: React.FC = () => {
	const selectedProducts = useAtomValue(selectedProductsAtom);
	const [addedPlatforms, setAddedPlatforms] = useState<string[]>([]);
	const [batchName, setBatchName] = useState<string>(ID.generateReadableID("EX"));
	const history = useHistory();

	const { isLoading, data: sanitizedMarkeplaces } = useQuery(
		"sanitizedMarkeplaces",
		() => getSanitizedMarketplaces()
	);

	const [activeStep, setActiveStep] = useState(0);
	// eslint-disable-next-line
	const [completed, setCompleted] = useState<{
		[k: number]: boolean;
	}>({});

	const allStepsCompleted = () => {
		return Object.keys(completed).length === steps.length;
	};

	const handleNext = () => {
		const newActiveStep =
			activeStep === steps.length - 1 && !allStepsCompleted()
				? steps.findIndex((step, i) => !(i in completed))
				: activeStep + 1;
		setActiveStep(newActiveStep);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleStep = (step: number) => () => {
		setActiveStep(step);
	};

	const uploadBatch = async (e: React.MouseEvent) => {
		e.preventDefault();
		if(addedPlatforms.length < 1){
			toast.error("Please select a platform")
			return
		}

		if (window.confirm(`You are about to export ${selectedProducts?.length} product${selectedProducts?.length > 1 ? "s":""} to ${addedPlatforms?.length} marketplace${addedPlatforms?.length > 1 ? "s":""}. Do you wish to continue?`)) {

		const products = selectedProducts.map(sku => ({
			sku,
		}));
		const marketplaces = addedPlatforms.map((zeoosName: string) => {
			return {
				marketplace: sanitizedMarkeplaces.find(
					(x: IMarketplace) => x.zeoosName === zeoosName
				)._id,
				products,
			};
		});

		try {
			const body = {
				name: batchName,
				products,
				marketplaces,
			};

			const id = await createBatch(body);
			toast.success("Products are being exported. The system will notify you when it's done");

			history.replace(`/batch/${id}`);
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}
	}
	};


	if (isLoading) {
		return (
			<div className="center-loader">
				<Preloader />
			</div>
		);
	}

	return (
		<div className="product-main-cont">
			<div className="product-header-cont">
				<div className="in-row align-cener height100">
					<div onClick={history.goBack} className="product-exit-btn">
						<img className="mr2" src="/icons/product-exit.svg" alt="" />
						Exit
					</div>
					<div className="in-row align-center ml2">
						<span className="product-header-text">Export Products</span>
					</div>
				</div>
				{/* <Stepper className="height100" nonLinear activeStep={activeStep}>
					{steps.map((label, index) => (
						<Step
							className="batch-pr-step"
							key={label}
							completed={completed[index]}
						>
							<ThemeProvider theme={theme}>
								<StepButton
									className="batch-pr-step-text"
									onClick={handleStep(index)}
									sx={{
										'& .MuiStepLabel-root': {
											background: activeStep === index ? '#1B1B1B' : ''
										}
									}}
								>
									{label}
								</StepButton>
								<img
									className="batch-step-vector"
									src="/icons/batch-vector.svg"
									alt=""
								/>
							</ThemeProvider>
						</Step>
					))}
				</Stepper> */}
				<div className="height100 in-row align-cente">
					{/* <React.Fragment> */}
					{/* <Button
							className="product-back-btn"
							color="inherit"
							disabled={activeStep === 0}
							onClick={handleBack}
							sx={{ mr: 1 }}
						>
							Back
						</Button> */}
					<Button
						className="product-header-button"
						onClick={uploadBatch}
						sx={{ mr: 1 }}
					>
						Confirm
					</Button>
					{/* </React.Fragment> */}
				</div>
			</div>
			{/* {activeStep === 0 && (
				<StepProducts />
			)} */}

			<StepPlatforms
				addedPlatforms={addedPlatforms}
				setAddedPlatforms={setAddedPlatforms}
			/>
			{/* {activeStep === 0 && (
				
			)}
			{activeStep === 1 && (
				<StepUpload
					addedPlatforms={addedPlatforms}
					setBatchName={setBatchName}
					batchName={batchName}
				/>
			)} */}
		</div>
	);
};

export default BatchProducts;
