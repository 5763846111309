import React, { useCallback, useEffect, useState } from "react";
// import Checkbox from "@mui/material/Checkbox";
import { Modal } from "../../../ui/modal/Modal";
import { IProductsCounter } from "../../../../interfaces";
import useCountries from "../../../../hooks/useCountries";
import { ICountryM } from "../../country-management/CountryManagement";
import * as _ from "lodash";
import { toast } from "react-toastify";
import {
	backToImportProducts,
	saveOnboardingProductsImportResults,
} from "../../../../actions/onboardingSeller";
import ImageWithFallback from "../../../ui/image-with-fallback";
import { getImagePath } from "../../../../utils";
import { NavLink } from "react-router-dom";
import { getPercentage } from "../../../ui/product-counts-stats";
import { useQuery } from "react-query";
import { getProductImportReport } from "../../../../actions/user";
import useUser from "../../../../hooks/useUser";
import YouTube, { YouTubeProps } from "react-youtube";
import ProductImportPie from "../../../ui/product-import-pie";
import { AntSwitch } from "../../../ui/ant-switch/AntSwitch";

const platformsArray = (data: any) => {
	return Object.keys(data?.platformCounter!).filter(
		(zeoosName: string) => data?.platformCounter[zeoosName].productsCount !== 0
	);
};
interface Props {
	productsData: IProductsCounter;
	setValue: (value: React.SetStateAction<string>) => void;
}

const WhereSelling: React.FC<Props> = ({ productsData, setValue }) => {
	const { user, invalidate } = useUser();
	const { data } = useQuery(
		`getProductImportReport${user.seller?.onboardingReport}`,
		() => getProductImportReport(user.seller.onboardingReport),
		{ refetchOnWindowFocus: false, initialData: productsData }
	);
	const countries = useCountries() as ICountryM[];

	const [modal, setModal] = useState<boolean>(false);
	const [modal2, setModal2] = useState<boolean>(false);
	const [selectedPlaforms, setSelectedPlaforms] = useState<string[]>([]);
	const [modal1, setModal1] = useState<boolean>(false);

	useEffect(() => {
		if (data) {
			setSelectedPlaforms(platformsArray(data));
		}
	}, [data]);

	const onPlayerReady: YouTubeProps["onReady"] = (event) => {
		// access to player in all event handlers via event.target
		// event.target.pauseVideo();
	};

	const opts: YouTubeProps["opts"] = {
		height: "400",
		width: "640",
		playerVars: {
			// https://developers.google.com/youtube/player_parameters
			autoplay: 1,
		},
	};

	const handleSelectOne = useCallback(
		(item: any) => () => {
			setSelectedPlaforms((prev) => {
				if (prev.find((x) => x === item)) {
					return prev.filter((x) => x !== item);
				}
				return [...prev, item];
			});
		},
		[setSelectedPlaforms]
	);

	if (!data) {
		return <></>;
	}

	const onModalClose1 = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal1(false);
	};
	const onModalClose2 = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal2(false);
	};

	const getCountry = (platform: string) => {
		return countries.find((c) => c.isoCode === platform.split(" ").pop())?.name;
	};

	const countriesCounter = () => {
		return _.uniq(_.map(platformsArray, (x) => getCountry(x))).length;
	};

	const onSubmit = async (e: React.MouseEvent) => {
		e.preventDefault();

		if (!selectedPlaforms.length) {
			return toast.error("Please select at least one platform");
		}

		try {
			await saveOnboardingProductsImportResults(selectedPlaforms);
			toast.success("Success");
			setValue("3");
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}

		setModal(false);
	};

	const onBackHandler = async (e: React.MouseEvent) => {
		e.preventDefault();

		try {
			await backToImportProducts();
			toast.success("Success");
			await invalidate();
			setValue("1");
		} catch (error) {
			console.error(error);
			toast.error("Something went wrong");
		}

		setModal(false);
	};

	const onModalClose = (e: React.MouseEvent) => {
		e.stopPropagation();
		setModal(false);
	};

	return (
		<>
			<div style={{ padding: "40px" }} className="onb-box">
				<div className="onb-s2-title">
					{data?.total} products were found in {platformsArray.length} sales
					channels in {countriesCounter()} countries.
				</div>
				<div
					style={{ marginTop: "40px" }}
					className="in-row align-start justify-between"
				>
					<div className="in-row align-start pb4 justify-start width100">
						<div className="width40 mr4">
							{data && <ProductImportPie {...data} />}
						</div>
						<div className="width100 onb-text2 in-column align-start justify-start">
							<span className="mb1">My catalog: {data?.total} products</span>
							<ul>
								<li className="mb1" style={{ color: "#44AF1F" }}>
									&#183; {getPercentage(data?.integrated!, data?.total!)}{" "}
									Integrated
								</li>
								<li className="mb1" style={{ color: "#9F9F9F" }}>
									&#183; {getPercentage(data?.nonIntegrated!, data?.total!)}{" "}
									Non-integrated
								</li>
								<li className="mb1" style={{ color: "#EA596E" }}>
									&#183; {getPercentage(data?.unregistered!, data?.total!)}{" "}
									Unregistred
								</li>
							</ul>
						</div>
					</div>
					<div className="onb-vertical-border"></div>
					<div className="in-column width80 onb-text align-start justify-start">
						<span className="bold mb1">How the catalog works:</span>
						<span className="">
							Products not yet integrated will enter the integration roadmap. As
							for unregistered products, you will be notified when they become
							available. For more details,{" "}
							<div
								onClick={() => {
									setModal2(true);
								}}
								style={{ color: "#55718A" }}
								className="bold pointer"
							>
								watch the video.
							</div>
						</span>
					</div>
				</div>
				<div className="table-modal-border mt4"></div>
				<div className="mt4 mb4 width100 in-column align-start">
					<div className="in-row align-start justify-between width100">
						<div className="in-column align-start justify-start width100">
							<span className="onb-text1">
								Choose the sales channels you want to create offers now:
							</span>
							<span className="onb-text">
								You can edit your preferences at any time.
							</span>
						</div>
						<div
							style={{ color: "#55718A" }}
							className="in-row width40 justify-end onb-text align-start"
						>
							<span
								className="pointer"
								onClick={() => setSelectedPlaforms(platformsArray)}
							>
								Select all
							</span>
							<span className="ml2 mr2">|</span>
							<span className="pointer" onClick={() => setSelectedPlaforms([])}>
								Unselect all
							</span>
						</div>
					</div>
					<div className="mt2 onb-s2-cont">
						{platformsArray(data).map((platform: string, index: number) => (
							<div className="onb-s2-box" key={index}>
								{/* <Checkbox
									sx={{
										color: "#999999",
										"&.Mui-checked": {
											color: "#299C00",
										},
									}}
									checked={selectedPlaforms.includes(platform)}
									onChange={handleSelectOne(platform)}
								/> */}
								<AntSwitch
									checked={selectedPlaforms.includes(platform)}
									onChange={handleSelectOne(platform)}
									name="checkedC"
									required
								/>
								<ImageWithFallback
									primarySrc={getImagePath(platform)}
									fallbackSrc={getImagePath(
										data?.platformCounter[platform].marketplaceName
									)}
									alt=""
									width="60px"
								/>
								<div className="ml2 in-column align-start">
									<span className="onb-text2">
										{data?.platformCounter[platform].productsCount} in{" "}
										{platform}
									</span>
									<span className="onb-text3">{getCountry(platform)}</span>
								</div>
							</div>
						))}
					</div>
				</div>
				<div className="table-modal-border mt4"></div>
				<div className="in-row align-center justify-start">
					<div
						onClick={() => {
							setModal(true);
						}}
						style={{ background: "#fff", border: "1px solid #55718a" }}
						className="tax-title-button pointer mt1 mt4 mr2"
					>
						<img src="/icons/billing-arrow-back.svg" alt="" width="16px;" />
						<div
							style={{ color: "#55718a" }}
							className="pim-avaliacoes-title-button-text initial"
						>
							Back
						</div>
					</div>
					<div className="tax-title-button pointer mt1 mr2 mt4">
						<div
							className="pim-avaliacoes-title-button-text initial"
							onClick={onSubmit}
						>
							Save & Next
						</div>
					</div>
				</div>
			</div>
			{/* <div
				onClick={() => {
					setModal1(true);
				}}
				className="width100 align-center pointer justify-center mt4 mb4"
			>
				<img src="/icons/skip-start.svg" alt="" />
				<span
					style={{ color: "#55718A", fontWeight: "500" }}
					className="ml1 email-text"
				>
					skip guided start
				</span>
			</div> */}
			{modal && (
				<Modal onModalClose={onModalClose} isOpened={modal}>
					<div
						style={{ maxWidth: "420px" }}
						className="table-modal-form-cont p4"
					>
						<div className="updateForm__container">
							<div className="onb-modal-text">
								<strong className="pb1">
									Are you sure you want to return to importing products?
								</strong>
								<br /> If you do this, you will need to submit your list of EANs
								again.
							</div>
							<div className="table-modal-border"></div>
							<div
								style={{ textAlign: "center" }}
								className="table-modal-form-button-box width100 m0"
							>
								<div
									onClick={onModalClose}
									style={{ background: "#fff", border: "1px solid #55718a" }}
									className="tax-title-button pointer mt1 mr2"
								>
									<div
										style={{ color: "#55718a" }}
										className="pim-avaliacoes-title-button-text initial"
									>
										No, keep this list
									</div>
								</div>
								<div
									style={{ background: "#fff", border: "1px solid #55718a" }}
									className="tax-title-button pointer mt1 mr2"
								>
									<div
										style={{ color: "#55718a" }}
										className="pim-avaliacoes-title-button-text initial"
										onClick={onBackHandler}
									>
										Yes, i’ll send a new list
									</div>
								</div>
							</div>
						</div>
					</div>
				</Modal>
			)}
			{modal1 && (
				<Modal onModalClose={onModalClose1} isOpened={modal1}>
					<div style={{ width: "400px" }} className="table-modal-form-cont p4">
						<div className="updateForm__container">
							<div className="vendor-mapping-modal-text">
								<b>Are you sure you want to return to importing products?</b>
								<br />
								If you do this, you will need to submit your list of EANs again.
							</div>
							<div className="table-modal-border"></div>
							<div className="table-modal-form-button-box width100 m0">
								<div
									onClick={onModalClose1}
									className="download-button pointer mb3"
								>
									<div className="download-button-text">NO, keep this list</div>
								</div>
								<NavLink to="/catalog/integrated" className="download-button pointer mb3">
									<div className="download-button-text">
										YES, i’ll send a new list
									</div>
								</NavLink>
							</div>
						</div>
					</div>
				</Modal>
			)}
			{modal2 && (
				<Modal onModalClose={onModalClose2} isOpened={modal2}>
					<YouTube videoId="qlQdKjdO5_Y" opts={opts} onReady={onPlayerReady} />
				</Modal>
			)}
		</>
	);
};

export default WhereSelling;
