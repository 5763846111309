import React, { useCallback, useState } from "react";
import "./contrato.css";
import Checkbox from "@mui/material/Checkbox";
import LogoutWrap from "../../../utils/logout-wrap/LogoutWrap";
import useUser from "../../../../hooks/useUser";
import { toast } from "react-toastify";
import { readContract } from "../../../../actions/onboardingSeller";

export default function Contrato() {
	const [checked, setChecked] = useState(false);
	const { invalidate } = useUser();

	const handleSubmit = useCallback(async () => {
		try {
			await readContract();
			invalidate();
		} catch (error) {
			toast.error("Something went wrong...");
		}
	}, [invalidate]);

	return (
		<div className="onboadring-process-cont">
			<div
				style={{ margin: "auto", background: "#fff" }}
				className="in-row pt2 pb2 pl4 pr4 align-center justify-between"
			>
				<img style={{zIndex: "10000" }} src="/icons/zeoos-logo.svg" width="150px;" alt="" />
				<LogoutWrap>
					<button
						style={{
							background: "transparent",
							boxShadow: "none",
							border: "none",
						}}
						className="logout-btn-onboarding"
					>
						<div>Log out</div>
					</button>
				</LogoutWrap>
			</div>
			<div className="contrato-bg pt4">
				<div className="contrato-cont">
					<div className="contrato-box">
						<img
							width="96px"
							height="28px"
							src="/icons/zeoos-logo.svg"
							alt=""
						/>
						<div className="policy-text p1">
							<br />
							<b>CONDIÇÕES GERAIS </b>
							<br />
							<br />
							<b>PREÂMBULO</b>
							<br />
							<br />
							§ Estas Condições Gerais visam regular a relação estabelecida
							entre BPMG - DRINKS AND CONSULTING, LDA, sociedade por quotas, com
							sede social em Rua Mário Castelhano, n.º 10, Queluz, freguesia de
							Barcarena, concelho de Oeiras, 2730 Queluz, matriculada na
							Conservatória do Registo Comercial sob o número único de pessoa
							coletiva 516 006 983 (doravante designada por “BPMG”) e os
							Parceiros Comerciais que aceitem aderir à Plataforma ZEOOS.
							<br />
							<br />
							A BPMG disponibiliza e opera uma plataforma eletrónica, designada
							“ZeOOs”, que na sua base é um “acelerador” de vendas, pois permite
							com apenas um registo central a imediata integração em diversos
							Marketplaces europeus, a que acrescem serviços de comunicação e
							marketing, monotorização on-line das vendas, inteligência
							artificial e serviços de logística. Além disto, a Plataforma ZeOOs
							disponibiliza, através da tecnologia incorporada, propriedade da
							BPMG, uma multiplicidade de serviços relacionados com os dados
							obtidos pela Plataforma sobre o desempenho dos produtos
							comercializados nos Marketplaces, assim como, da concorrência e as
							tendências de consumo dos Consumidores. A ZeOOs é disponibilizada
							a pessoas coletivas ou empresários em nome individual cujos
							produtos possam ser comercializados em Marketplaces localizados em
							países dentro do Espaço Económico da União Europeia.
							<br />
							<br />
							A relação entre a BPMG e os Parceiros Comerciais é regulada pelas
							Condições Gerais, bem como, pelo Contrato de Adesão e/ou outros
							documentos anexos àquele e quaisquer informações adicionais que a
							BMPG disponibilize aos comerciantes através da Plataforma. A
							utilização da ZeOOs implica o conhecimento e aceitação das
							presentes Condições Gerais e de qualquer documento adicional que
							esteja incluído na relação contratual estabelecida através da
							assinatura do Contrato de Adesão, assim como, da Documentação da
							Plataforma, as quais em conjunto regulam a relação entre BPMG e os
							Sellers, a BMPG e os Clientes Finais no âmbito da contratação
							eletrónica para utilização da ZeOOs.
							<br />
							<br />
							Através da aceitação destas Condições Gerais, os Sellers iniciam
							uma relação comercial com a BPMG, na qual subscrevem serviços
							prestados pela BPMG e aceitam vender os seus produtos à BPMG e
							fornecer os mesmos aos Consumidores. Para efeitos das presentes
							Condições Gerais, as referências feitas a “BPMG” e a “SELLERS”
							referem-se às entidades/pessoas descritas e identificadas no
							Contrato de Adesão.
							<br />
							<br />
							<b>DEFINIÇÕES</b>
							<br />
							Quando utilizadas nestas Condições Gerais, os seguintes termos
							terão os seguintes significados:
							<br />
							<br />
							<b>“Adesão”</b> significa a subscrição e aceitação dos termos e
							condições do presente Contrato, bem como, todas as regras e normas
							relativas ao uso da Plataforma ZeOOs. <br />
							<b>“Acesso”</b> significa a possibilidade de utilização da Conta
							criada com o Registo à Plataforma ZeOOs por parte dos Sellers.
							<br />
							<b>“Categoria de Serviços”</b> significa os serviços organizados e
							ordenados em diferentes tipos de serviços, designadamente em
							termos de serviços prestados e valores pagos <br />
							<b>“Contrato de Adesão”</b> significa os termos e condições
							previstas no Contrato de Adesão, que constituíram as condições
							particulares da relação contratual estabelecida entre BPMG e os
							Sellers;
							<br />
							<b>“Contrato”</b> significa o contrato que engloba as Condições
							Gerais, Particulares e os seus Anexos;
							<br />
							<b>“Contrato de Adesão”</b> significa as condições particulares do
							contrato ou o Formulário de Subscrição que poderá ser em papel ou
							eletrónico consoante a escolha da BPMG;
							<br />
							<b>“Consumidores”</b> ou <b>“Cliente Finais”</b> significam
							terceiros que adquiriram os produtos nos Markeplaces
							disponibilizados através da plataforma ZEOOS;
							<br />
							<b>“Conta”</b> significa o perfil constituído com o Registo,
							restrita ao Seller, que permitirá aos Sellers usar as
							funcionalidades da Plataforma ZeOOs, sendo esta privada e
							individual.
							<br />
							<b>“eCommerce”</b> significa comércio eletrónico. <br />
							<b>“Gestor de Conta”</b> significa um colaborador da BPMG
							responsável por ser a pessoa de contacto e gestor do serviço
							prestado ao Seller.
							<br />
							<b>“Pacote de Serviços”</b> significa o mesmo que Categoria de
							Serviços.
							<br />
							<b>“Partes”</b> significa cada uma das contraentes e os mesmos em
							conjunto, consoante o uso plural ou singular da palavra;
							<br />
							<b>“Plataforma ZeOOs”</b> significa um software com o intuito de
							“acelerar” vendas, permitindo com um único registo central a
							imediata integração com inúmeros marketplaces sediados na União
							Europeia, com multi-serviços associado, incluindo monotorização
							das venda “live”, comunicação e marketing e logística;
							<br />
							<b>“Produtos”</b> Os bens ou serviços dos Sellers, comercializados
							nos Markteplaces, integrados com a Plataforma ZeOOs;
							<br />
							<b>“Marketplaces”</b> Espaços virtuais de venda de serviços e
							produtos, integrados com a Plataforma ZeOO
							<br />
							<b>“Manual de Instruções”</b> significa o manual de instruções de
							acesso à Plataforma ZeOOs e para uso das suas funcionalidades.
							<br />
							<b>“Categoria de Serviços”</b> ou <b>“Pacotes de Serviços”</b>{" "}
							significa o tipo de adesão/pacote de serviços que o Seller pode
							aderir, conforme melhor identificados no Anexo 1 ao presente
							Contrato. <br />
							<b>“Registo”</b> significa o registo e criação de um perfil de
							utilizador na Plataforma ZeOOs.
							<br />
							<b>“Sellers”</b> significa as pessoas coletiva ou empresários em
							nome individual que disponibilizem os seus produtos para a venda
							através da Plataforma ZEOOS
							<br />
							“Software” significa a tecnologia incorporada na plataforma ZEOOS,
							cuja propriedade intelectual pertence à BPMG.
							<br />
							<b>“Subscrição”</b> significa o ato de adesão por parte dos
							Sellers a qualquer um dos Pacotes de Serviços disponibilizados
							pela BPMG, melhor identificados no Anexo 1.
							<br />
							<b>“Termos e Condições”</b> significa todas as regras que regulam
							a relação comercial estabelecida com a adesão e acesso à ZEOOS.{" "}
							<br />
							<b>“Transação”</b> significa a compra efetuada pelos Clientes
							Finais dos Produtos dos Sellers através da Plataforma.
							<br />
							Quaisquer referências no plural incluem o singular (e vice-versa),
							assim como as referências a diferente género incluem o género
							oposto. <br />
							<b>OBJECTO</b>
							<br />
							O presente Contrato tem por objeto as condições da adesão do
							Seller à Plataforma ZeOOs e a relação comercial subjacente à
							referida adesão, designadamente os serviços prestados pela
							Plataforma ZeOOs, promovida pela BPMG, e o fornecimento de bens
							pelo Seller à BPMG. <br />
							Fazem parte integrante do presente Contrato, as Condições
							Particulares e as presentes Condições Gerais.
							<br />
							O CLIENTE, por Adesão à Plataforma ZeOOs, declara ter aceitado os
							termos e condições aqui previstos.
							<br />
							As presentes condições são compostas pelos seguintes capítulos:{" "}
							<br />
							Termos e Condições da Adesão;
							<br />
							Prestação de Serviços;
							<br />
							Fornecimento de mercadorias; e<br />
							Disposições Gerais.
							<br />
							<br />
							<br />
							<b>DURAÇÃO</b>
							<br />
							<br />
							O presente Contrato tem a duração de 1 ano, renovando-se
							automaticamente por igual período, caso nenhuma das Partes
							comunique a sua revogação, com antecedência mínima de 3 (três)
							meses do termo.
							<br />
							<br />
							<br />
							<br />
							<b>CAPÍTULO I – TERMOS E CONDIÇÕES DE ADESÃO</b>
							<br />
							<br />
							<b>CONDIÇÕES DE ADESÂO</b>
							<br />
							A subscrição à Plataforma ZeOOs pressupõe sempre o conhecimento
							integral das presentes Condições Gerais e a sua aceitação pelo
							CLIENTE, através da subscrição eletrónica junto do website da
							plataforma - https://www.zeoos.com/ - ou pela assinatura das
							Condições Particulares devidamente preenchidas e assinadas pelo
							CLIENTE. <br />
							O Contrato considerar-se-á celebrado (“Data da Celebração”) após a
							celebração do Contrato de Adesão e cumpridas as obrigações de
							adesão, incluindo, a adesão eletrónica à Plataforma ZeOOs.
							<br />
							O presente contrato só é válido e eficaz após a celebração do
							Contrato de Adesão e aprovação da BPMG.
							<br />
							Sem prejuízo do estabelecido no número anterior, o presente
							contrato só se considera plenamente válido e eficaz mediante
							subscrição do Seller à Plataforma ZeOOs.
							<br />
							O Seller deverá registrar-se na Plataforma ZeOOs e aderir a um dos
							Pacotes de Serviços por forma a que a relação jurídica, aqui
							regulada, seja válida e eficaz. <br />
							A Adesão do Seller deve ser acompanhada pelos seguintes
							documentos: <br />
							Certificado permanente da sociedade ou elementos de identificação
							pessoais, quando aplicáveis;
							<br />
							Documento comprovativo dos dados bancários (IBAN, SWIFT, titular
							da conta); <br />
							Documento comprovativo de registo de IVA; <br />
							A BPMG compromete-se, após a verificação da Adesão, incluindo, a
							verificação do pagamento dos custo da adesão, a efetuar a
							aceitação da adesão do Seller à ZeOOs no prazo máximo de 10 (dez)
							dias úteis, ressalvada toda e qualquer circunstância que não lhe
							possa ser imputada, nomeadamente ato ou omissão do Seller ou
							circunstância de força maior.
							<br />
							À data da celebração, o Seller não pode ter quantias em dívida
							respeitante a contratos anteriormente celebrados com a BPMG, salvo
							se o Seller tiver invocado exceção de não cumprimento do contrato
							ou tiver reclamado ou impugnado a faturação apresentada.
							<br />
							Por efeito da celebração do contrato, o Seller declara aceitar
							expressamente as Regras de Conduta, Ética e Anticorrupção, a
							Política de Proteção de Dados Pessoais, Tratamento de Dados
							Pessoais, Segurança e Proteção de Informação, assim como, da
							Política de Confidencialidade praticadas pela BPMG e que fazem
							parte integrante das Condições Gerais.
							<br />
							<br />
							<b>ACESSO E REGISTO NA PLATAFORMA</b>
							<br />
							Verificada a adesão e aceitação por parte da BPMG, nos termos
							previstos nas Condições de Adesão – Cláusula 3.7, será endereçado
							ao Seller pela BPMG um correio eletrónico automático com a
							confirmação da adesão.
							<br />
							A subscrição à Plataforma ZeOOs, quando feita via eletrónica, fica
							sujeita à aceitação da BPMG que endereçará um e-mail de
							confirmação do Registo. <br />
							O Registo efetuado e confirmado criará uma conta (doravante
							designada por “Conta Cliente”) que é de uso restrito do Seller
							subscritor do presente Contrato.
							<br />
							Em caso de extravio, de desvio ou de uso fraudulento dos dados
							privados de acesso à Conta, deverá a BPMG ser informada de
							imediato para que esta dê início aos seus procedimentos de
							segurança, designadamente, o bloqueio imediato da Conta.
							<br />
							Caso após o extravio dos dados de acesso e antes do bloqueio dos
							mesmos por parte BPMG, tenham ocorrido alguma(s) transação(ões), o
							Seller assumirá inteira responsabilidade independentemente da
							culpa, exceto se ainda for possível anular a(s) referida(s)
							transação(ões). Os bloqueios dos dados extraviados serão efetuados
							a pedido do Seller e ocorrerá de forma automática e eletrónica.
							<br />
							<br />
							<b>FUNCIONAMENTO DA PLATAFORMA ZeOOs</b>
							<br />
							A Plataforma ZeOOs é uma plataforma eletrónica que promove a
							colocação dos Produtos em diversos Marketplaces dentro da União
							Europeia, a qual fica devidamente autorizada por razão da adesão à
							Plataforma ZeOOs. <br />
							A Plataforma ZeOOs dispõe de um desenho, imagem e funcionalidades
							que lhe são próprios, ficando o Seller sujeito àquelas e às suas
							regras de utilização.
							<br />
							O Seller reconhece que incumbe exclusivamente à BPMG definir as
							condições de exposição, visibilidade, comunicação e divulgação dos
							Produtos nos Marketplaces.
							<br />
							Quaisquer Produtos anunciados e colocados à venda através da
							Plataforma ZeOOs têm de corresponder com exatidão às informações e
							descrições feita pelo Seller.
							<br />
							O Seller responsabiliza-se por qualquer contingência, coima, ou
							litígio resultante da divulgação deficiente das informações e
							descrições exigidas pela lei, seja num contexto administrativo,
							ambiental ou do direito do consumo. <br />
							<br />
							<b>CONDIÇÕES DE UTILIZAÇÃO</b>
							<br />
							O Seller compromete-se a guardar a confidencialidade sobre os seus
							dados de acesso à Plataforma ZeOOs e a não divulgar os mesmos a
							terceiros, constituindo-se responsável pelo seu extravio e pelos
							prejuízos daí decorrentes.
							<br />
							Os dados de acesso podem ser alterados na Plataforma ZeOOs. <br />
							A BPMG pode rever os conteúdos na estrita medida para alinhar, com
							a sua imagem, qualidade, bem como para fazer sugestões de
							forma/idioma. Em todo o caso, o Seller mantém-se exclusivamente
							responsável pelos Produtos, serviços e conteúdos por si
							facultados.
							<br />
							O Seller deve: <br />
							Utilizar a Plataforma ZeOOs de forma responsável, prudente e
							cuidadosa, não devendo perturbar ou colocar em causa a
							continuidade, integridade e qualidade dos recursos e
							funcionalidades da mesma.
							<br />
							Respeitar as condições e instruções dados pelas entidades
							responsáveis pelos Marketplaces onde se encontram os Produtos à
							venda.
							<br />
							Manter a confidencialidade relativamente a todos os dados e
							informações, a que tenha acesso em virtude do acesso à Plataforma
							ZeOOs, nomeadamente, os dados pessoais dos Clientes Finais ou de
							outras pessoas singulares.
							<br />
							Prestar informações verdadeiras seja quanto à sua identidade,
							produtos ou conteúdos facultados. <br />
							Prestar toda as informações solicitadas pela BPMG que sejam
							necessárias para a boa execução do Contrato, incluindo toda a
							informação que seja obrigatória para a operacionalidade do
							Serviços e das operações de compra e venda dos produtos adquiridos
							pelos Consumidores e fornecidos pela BPMG.
							<br />
							O Seller obriga-se a não disponibilizar ou anunciar, nem vender
							produtos que viole os direitos de propriedade intelectual de
							terceiros, nomeadamente que, consubstancie uma contrafação ou
							fraude sobre mercadorias nos termos da lei aplicável.
							<br />
							Os Produtos disponibilizados ou anunciados devem ser novos ou
							recondicionados, desde que devidamente identificados perante a
							BPMG e o Consumidor Final. <br />
							Perante a violação de qualquer uma das normas presentes nesta
							Cláusula terá a BPMG o direito de suspender e ou resolver de
							imediato os serviços prestado ao Seller, nomeadamente, os serviços
							da Plataforma ZeOOs, até conclusão do processo de verificação do
							cumprimento das condições do presente Contrato. <br />
							Perante a violação de qualquer uma das regras aplicadas aos
							Produtos poderá o BPMG proceder à eliminação do anúncio de venda
							do Produto em causa, até decisão em contrário. <br />
							<br />
							<br />
							<b>CAPÍTULO II – PRESTAÇÃO DE SERVIÇOS</b>
							<br />
							<br />
							<b>DESCRIÇÃO DOS SERVIÇOS</b>
							<br />
							Pelo presente Contrato, a BPMG obriga-se a prestar os seguintes
							serviços:
							<br />
							Facultar o acesso do Seller à Plataforma ZeOOs;
							<br />
							Disponibilização dos Produtos dos CLIENTES, incluindo a disposição
							das informações e descrições;
							<br />
							Desenho e disponibilização da Ficha do CLIENTE com base na
							informação e diretrizes dadas pelo CLIENTE;
							<br />
							Desenho e disponibilização da Ficha do Produto com base na
							informação e diretrizes dadas pelo CLIENTE, em tudo o que não se
							opor ao desenho, imagem e funcionalidades do ZEOOS;
							<br />
							Fornecimento de modelos de ficheiros para o intercâmbio de fluxos
							de dados informáticos relacionados com os Produtos e as suas
							características de venda;
							<br />
							Acesso a ferramentas de administração dos Produtos, permitindo o
							CLIENTE fazer alterações a determinadas configurações,
							designadamente, preço, descrição, custos de envio, prazo de
							entrega. <br />
							Ferramenta de acompanhamento de encomendas e o seu processamento;
							<br />
							Assistência na utilização de todas as ferramentas disponibilizadas
							pela Plataforma; <br />
							Certificação e segurança dos pagamentos efetuados através da
							Plataforma;
							<br />
							Certificação, tratamento e segurança dos dados pessoais
							transmitidos pelos Clientes Finais; e <br />
							Disponibilização e redireccionamento das reclamações e pedidos de
							devolução dirigidos aos CLIENTES e os seus Produtos através de um
							Serviço ao Cliente do CLIENTE.
							<br />
							Para além dos serviços acima indicados, a BPMG presta serviços
							adicionais que doravante serão denominados “Serviços Adicionais”,
							sem prejuízo de outra denominação que BPMG possa dar devido ao
							design, imagem, ou marketing da Plataforma ZeOOs.
							<br />
							Os Serviços e Serviços Adicionais estão ordenados em níveis de
							serviços que ficam à disposição dos Sellers consoante a sua adesão
							aos mesmos. <br />
							A escolha dos Níveis de Serviço ou Pacotes de Serviços ocorre no
							ato de preenchimento do Formulário de Subscrição e devem ser
							identificados de acordo com a designação que é feita pela BPMG e
							que adiante serão expostos. <br />
							Os Serviços acima descritos serão realizados no cumprimento de
							todas as normas e obrigações impostas por lei, seja geral,
							especial ou regulamentar, recomendações ou diretrizes dadas por
							entidades e/ou autoridades governamentais, administrativas e/ou
							entidades reguladoras.
							<br />
							<br />
							<b>PACOTE DE SERVIÇOS</b>
							<br />
							<br />
							O Pacote de Serviços disponibilizado pela BPMG está identificado
							no Anexo 1 ao presente Contrato, devendo o Seller assinalar o
							Pacote de Serviços que pretende aderir.
							<br />
							<br />
							<b>PREÇO E PAGAMENTO</b>
							<br />
							O Seller é obrigado a pagar pelos serviços prestados pela BPMG, de
							acordo com as categorias de serviços e nos termos da subscrição
							mensal/anual definida para a utilização da Plataforma, no Pacote
							de Serviços, Anexo 1 ao presente Contrato.
							<br />
							Os pagamentos devem ser pontualmente realizados mediante
							transferência bancária para o IBAN indicado pela BPMG e no valor
							estabelecido para o Pacote de Serviços aderido. <br />
							A BPMG tem o direito a modificar o preço aplicado a cada um dos
							Pacotes de Serviços no fim de cada ano civil, devendo, por razão
							da sua eficácia, comunicar com a antecedência de 30 dias do início
							de um ciclo de faturação pelos serviços prestados. <br />
							A falta do pagamento, ou entrada em mora, dará à BPMG o direito de
							suspender a Conta do Seller até regularização dos valores em mora
							ou de exercer o seu direito de exceção de não cumprimento nos
							termos previstos na Lei, sem prejuízo de qualquer indemnização
							e/ou juros de mora a que tenha direito.
							<br />
							<br />
							<br />
							<br />
							<br />
							<b>CAPÍTULO III – FORNECIMENTO DE PRODUTOS</b>
							<br />
							<br />
							<b>COMERCIALIZAÇÃO DE PRODUTOS</b>
							<br />
							Pela subscrição à plataforma, o Seller obriga-se a vender à BPMG
							os Produtos adquiridos pelos Consumidores Finais nos Marketplaces,
							bem como a manter a BPMG informada sobre qualquer quebra de stock.{" "}
							<br />
							A Transação efetuada entre a BPMG e os Clientes Finais determinará
							a quantidade e o valor da transação efetuada pela BPMG e o Seller.
							<br />
							A BPMG reserva-se o direito, a seu exclusivo critério, de limitar
							a qualquer momento o acesso ao fornecimento de todo ou parte dos
							Produtos em uma ou mais categorias de Produtos disponibilizados na
							Plataforma ZeOOs.
							<br />
							O Seller aceita os termos e condições da compra e venda dos seus
							Produtos, independentemente, das suas próprias Condições Gerais de
							venda ou fornecimento padrão.
							<br />
							Pelo presente contrato, o Seller responsabiliza-se pelo
							fornecimento e entrega dos Produtos ao Cliente Final. <br />
							<br />
							<b>CONDIÇÕES DOS PRODUTOS</b>
							<br />
							Os produtos comercializados pelo Seller não devem infringir a lei
							aplicável ou quaisquer direitos de terceiros. O Seller
							compromete-se a não anunciar ou vender qualquer produto que
							infrinja os direitos de propriedade intelectual de terceiros, em
							particular qualquer produto que constitua contrafação ou fraude em
							relação às mercadorias, de acordo com a lei aplicável.
							<br />
							O Seller é o único responsável pela exatidão das informações que
							fornece para os Produtos (tais como características, condição,
							preço) e estas informações devem ser claras, verdadeiras e não
							enganadoras para o Cliente Final. <br />
							O Seller aceita sujeitar-se às indicações dadas pela BPMG a
							propósito de qualquer característica, informação ou conteúdo
							relacionado com os Produtos.
							<br />
							O Seller também se compromete a que as informações e
							fotografias/imagens que disponibilizam e associadas aos Produtos
							disponibilizados através da Plataforma ZeOOs sejam fidedignas.
							Mesmo nos casos em que as informações sobre os Produtos já se
							encontrem no website, o Seller tem a obrigação de verificar a
							exatidão dessas informações e de solicitar a sua correção em caso
							de não conformidade ou inexatidão. A BPMG reserva-se o direito de
							recusar a colocação de um Produto nos Markeplaces, em particular
							se este não for acompanhado das informações exigidas por lei,
							incluindo as taxas de imposto aplicáveis aos Produtos, e pelos
							presentes Termos e Condições.
							<br />
							<br />
							<b>ENTREGA DOS PRODUTOS</b>
							<br />
							<br />
							<br />
							A entraga dos produtos é algo no qual a responsabilidade é do
							Zeller com os cumprimentos dos prazos acordado junto com a BPMG
							<br />
							<br />
							<b>IMPOSTOS E OUTROS CUSTOS</b>
							<br />
							Salvo acordo em contrário entre as Partes, cada uma das Partes
							suportará as suas próprias despesas, custos e honorários
							(incluindo, sem limitação, honorários de advogados e auditores)
							relativos ao Serviços e Comercialização aqui contemplados. <br />
							Quaisquer impostos de qualquer tipo a ser pagos devido à execução
							e cumprimento do Contrato serão pagos pelas Partes em conformidade
							com a lei. <br />
							<br />
							<br />
							<br />
							<b>CAPÍTULO IV – DISPOSIÇÕES GERAIS</b>
							<br />
							<br />
							<b>AUTONOMIA E INDEPENDÊNCIA</b>
							<br />
							As Partes reconhecem expressa e reciprocamente que os Serviços
							serão prestados pela BPMG com total independência e autonomia
							técnica. A CLIENTE é uma entidade independente e nenhuma das
							cláusulas do presente Contrato deverá ser entendida no sentido de
							considerar a CLIENTE e/ou seus trabalhadores, como agentes e/ou
							representantes da BPMG para qualquer efeito.
							<br />
							<br />
							<b>GESTOR DE CONTACTO</b>
							<br />
							A BPMG e o Seller designarão uma pessoa de contacto (adiante
							“Pessoa de Contacto”) que servirá como ponto de contacto para as
							comunicações relacionadas com o desempenho dos serviços a que o
							Seller aderiu, nos termos definidos no presente contrato. <br />
							A Pessoa de Contacto será aquela identificada pelas partes nas
							Condições Particulares, ficando as Partes obrigadas a informar a
							contraparte o nome e os dados da nova Pessoa de Contato, em caso
							de indisponibilidade temporária ou permanente daquela identificada
							nas Condições Particulares. <br />
							<br />
							<b>PROPRIEDADE INTELECTUAL E USO DA MARCA</b>
							<br />
							O Seller autoriza a BPMG a utilizar as suas marcas e os seus
							logótipos institucionais para efeitos de comercialização dos
							produtos do Seller pela BPMG, desde que esta sujeite tal
							utilização às regras e instruções emitidas a cada momento pelo
							Seller e na falta destas sem nunca colocar em causa a boa imagem
							do Seller junto dos Consumidores Finais.
							<br />
							A BPMG não será responsável pela deterioração da imagem do Seller
							junto dos Consumidores Finais que tenha origem nas características
							e qualidades específicas do produto e/ou por defeitos dos mesmos.
							<br />
							<br />
							<b>DADOS PESSOAIS</b>
							<br />
							Caso, no âmbito do presente contrato, qualquer uma das partes
							venha a realizar operações de tratamento de dados pessoais, por
							qualquer título ou de qualquer forma aos referidos dados, as
							Partes declaram e garantem que cumprirão a legislação nacional e
							europeia aplicável, nomeadamente o Regulamento (CE) 2016/679 do
							Parlamento Europeu e do Conselho, de 27 de abril de 2016
							(doravante “RGPD”), bem como a Lei n.º 58/2019 de 8 de agosto.
							<br />
							<br />
							<b>CONFIDENCIALIDADE</b>
							<br />
							A BPMG obriga-se, ao longo do período de duração da prestação de
							serviços, bem como após a conclusão integral da mesma, a tratar
							toda e qualquer informação de que tenha conhecimento através ou em
							resultado da relação comercial com o Seller, incluindo dados,
							elementos e detalhes técnicos, comerciais e organizacionais, como
							segredos comerciais, e a mantê-los estritamente confidenciais, não
							os divulgando ou disponibilizando a terceiros, com exceção das
							informações que sejam ou venham a tornar-se públicas sem violação
							da presente cláusula. As Partes obrigam-se a utilizar as
							informações e os documentos que lhe sejam disponibilizados durante
							o período da relação comercial, somente no âmbito e para efeito do
							presente contrato. O mesmo aplicar-se-á aos resultados, dados e
							know-how criado em virtude deste acordo. <br />
							A BPMG obriga-se a proteger todos os segredos comerciais do Seller
							contra acessos não autorizados/indevidos de terceiros, mediante
							aplicação de medidas tecnológicas em linha com o estado mais
							actual da tecnologia. A BPMG obriga-se a notificar de imediato o
							Seller caso tenha conhecimento de qualquer acesso não
							autorizado/indevido de terceiros aos segredos comerciais do
							Seller, e, bem assim, em consulta com o Seller, a implementar as
							medidas necessárias para o efeito.
							<br />
							As Partes obrigam-se, após a conclusão da prestação de serviços a
							devolver toda a informação, dados, documentos, suportes
							informáticos e unidades de armazenamento recebidos para o efeito.
							<br />
							<b>FORÇA MAIOR</b>
							<br />
							Nenhuma das Partes será responsável pela mora ou incumprimento de
							qualquer das suas obrigações, se a execução das mesmas for
							retardada, limitada ou impossibilitada em consequência de situação
							de força maior.
							<br />
							Para efeitos do presente Contrato, entende-se por força maior
							qualquer ato ou evento de catástrofe natural, atos de Deus,
							motins, terrorismo, atos governamentais, ou qualquer outro evento
							de natureza semelhante que sejam alheios ao controlo das Partes,
							que sejam imprevisíveis ou sendo previsíveis, sejam inevitáveis,
							irresistíveis ou independentes da vontade das Partes apesar das
							diligências da Parte afetada para evitar. <br />
							<br />
							<b>RESPONSABILIDADE</b>
							<br />
							É de responsabilidade do cliente os seguinte SLAs:
							<br />
							<br />
							<b>ENTREGA</b>
							<br />
							<b>INDICADOR</b> <b>CICLO DE AVALIAÇÃO</b> <b>ALVO %</b>
							<br />
							Taxa de Envio atrasada por pedido dentro do cliclo de avaliação 30
							dias Menor que 3%
							<br />
							Taxa de Preparação atrasada menor que 24 horas 7 dias Menor que 2%
							<br />
							Taxa de aceitação do pedido menor que 24 horas 7 dias Menor que 2%
							<br />
							Taxa de Cancelamento por pedido dentro do cliclo de avaliação 7
							dias Menor que 2%
							<br />
							<b>ATENDIMENTO</b>
							<br />
							<br />
							<b>INDICADOR</b> <b>CICLO DE AVALIAÇÃO</b> <b>ALVO %</b>
							<br />
							Taxa de pedido com defeito por pedido dentro do cliclo de
							avaliação 60 dias Menor que 1%
							<br />
							Taxa de pedido com reclamação por pedido dentro do cliclo de
							avaliação 7 dias Menor que 1%
							<br />
							Taxa de pedidos com reembolso por pedido dentro do cliclo de
							avaliação 7 dias Menor que 1%
							<br />
							Taxa de Avaliação negativa por pedido dentro do cliclo de
							avaliação 7 dias Menor que 1%
							<br />
							Taxa de faturas com problema por pedido dentro do cliclo de
							avaliação 7 dias Menor que 4%
							<br />
							Taxa de Resposta de atendimento menor que 24 horas 7 dias Menor
							que 2%
							<br />
							<br />
							<br />
							<b>INCUMPRIMENTO</b>
							<br />
							O incumprimento irá afetar em penalizações será avaliada pela BPMG
							com obrigação de notificação ao cliente sobre a decisão tomada.
							<br />
							A decisão pode ser de punição de dia, meses o exclusão da
							plataforma.
							<br />
							<br />
							<b>INTEGRALIDADE E ALTERAÇÕES</b>
							<br />
							As presentes Condições Gerais com os respetivos anexos e as
							Condições Particulares constitui o único documento válido entre as
							Partes, revogando e prevalecendo sobre quaisquer outros contratos,
							acordos, compromissos, documentos ou comunicações orais ou
							escritas anteriores à sua assinatura, relativos ao objeto deste
							Contrato. <br />
							Qualquer modificação dos termos contratuais apenas produzirá
							efeitos jurídicos se reduzida a escrito, assinada por ambas as
							Partes e incorporada expressamente no presente Contrato.
							<br />
							<br />
							<b>CESSÂO DA POSIÇÃO CONTRATUAL</b>
							<br />
							Não é permitido ao Seller ceder ou transferir a sua posição neste
							Contrato ou os direitos e obrigações derivados do mesmo, sem
							aprovação prévia por escrito à BPMG. <br />
							Caso o Seller venha a sofrer alterações societárias (nomeadamente
							transformações, fusões ou cisões) deverá comunicá-lo por escrito à
							BPMG no prazo máximo de 5 (cinco) dias a contar da data da
							referida modificação.
							<br />
							<br />
							<b>VALIDADE</b>
							<br />
							Na eventualidade de qualquer disposição do presente Contrato vir a
							ser considerada inválida ou ineficaz, as restantes disposições
							manter-se-ão válidas e eficazes, salvo se a disposição considerada
							inválida ou ineficaz tiver sido determinante para a formação da
							vontade de qualquer uma das partes.
							<br />
							<br />
							<b>NOTIFICAÇÕES</b>
							<br />
							Quaisquer notificações ou outras comunicações realizadas entre as
							Partes ao abrigo do Contrato considerar-se-ão validamente
							efetuadas quando o forem por escrito, mediante correio eletrónico,
							para os contactos indicados nas Condições Particulares ou para
							qualquer outro endereço que tenha sido comunicado por escrito por
							qualquer das Partes à contraparte, por qualquer um dos referidos
							meios. <br />
							As notificações deverão ser entregues às pessoas e através dos
							endereços indicados infra. <br />
							<br />
							<b>FORO E LEGISLAÇÃO APLICÁVEL</b>
							<br />
							O presente Contrato reger-se-á pelas leis de Portugal, que será
							aplicada a tudo o que respeitar ao mesmo.
							<br />
							Para a resolução de qualquer litígio emergente do presente
							Contrato, as Partes convencionam o foro da Comarca de Lisboa, com
							expressa renúncia de qualquer outro.
							<br />
						</div>
					</div>
					<div className="width100 in-row align-center justify-between mt4 mb4">
						<div className="in-row policy-text align-center">
							<Checkbox
								checked={checked}
								onChange={(e) => setChecked(e.target.checked)}
								sx={{
									color: "",
									"&.Mui-checked": {
										color: "#55718A",
									},
								}}
							/>
							<b className="ml2" style={{ color: "#55718A" }}>
								I read and accept the terms
							</b>
						</div>

						<div>
							<button
								onClick={handleSubmit}
								disabled={!checked}
								style={{ background: "#55718A" }}
								className="log-in-login p2"
							>
								Accept the terms
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
