import { useEffect, useMemo } from "react";
import useUser from "../../../../hooks/useUser";
import { useInterval } from "@mantine/hooks";

const WaitingArea: React.FC<{ mode: 'catalog' | 'offer'; }> = ({ mode }) => {
	const message = useMemo(() => {
		switch (mode) {
			case "catalog":
				return "We are checking your catalog and verifying the integrated sales channels.";

			case "offer":
				return "We are creating your offers and checking the competitiveness of the sales channels.";
		}
	}, [mode]);

	const { invalidate } = useUser();
	const interval = useInterval(invalidate, 20000);

	useEffect(() => {
		interval.start();
		return interval.stop;
	}, [interval]);

	return (
		<div className="catalog-waiting-cont">
			<img src="/icons/please-wait.svg" alt="" />
			<div className="catalog-waiting-title mt3">
				<span>
					Please wait. <br />
					{message}
				</span>
			</div>
			<div className="catalog-waiting-subtitle mt3">
				This operation may take some time.
				<br />
				Don't worry, you'll be notified via email once it's finished.
			</div>
		</div>
	);
};

export default WaitingArea;