import "./users-management.css";
import React, { useState, useCallback, useMemo } from "react";
import { Modal } from "../../ui/modal/Modal";
import { Step, StepLabel, Stepper, Tooltip } from "@mui/material";
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Role } from "../../../interfaces";
import { CreateEditUser } from '../../../schemas';
import { CreateEditUserInput } from '../../../schemas';
import * as UserController from '../../../actions/user';
import { IUser } from "../../../interfaces";
import { toast } from "react-toastify";
import useUser from "../../../hooks/useUser";
import useUserGroups from "../../../hooks/useUserGroups";
import useVendors from "../../../hooks/useVendors";
import { AntSwitch } from "../../ui/ant-switch/AntSwitch";
import RoleRequired from "../../utils/role-required/RoleRequired";

interface Props {
	setModal: (m: boolean) => void;
	modal: boolean;
	user?: IUser;
	onSuccess: (user: Partial<IUser>) => void;
}

export const USER_TYPES = [
	{
		role: Role.admin,
		type: 'Admin',
		bgColor: "#FFE6B5",
	},
	{
		role: Role.sellerAdmin,
		type: 'Worten White Label User',
		bgColor: "#DBFBFF",
	},
	{
		role: Role.sellerAdmin,
		type: 'Partner',
		bgColor: "#EAFFAF",
	},
	{
		role: Role.user,
		type: 'Seller User',
		bgColor: "#FFE7FF",
	},
] as const;
type Type = typeof USER_TYPES[number]['type'];

export function getUserType(user: Pick<IUser, 'role' | 'isWortenWhiteLabel'>) {
	if (user.role === Role.sellerAdmin) {
		return user.isWortenWhiteLabel ? USER_TYPES[1] : USER_TYPES[2];
	}

	const item = USER_TYPES.find(x => x.role === user.role);

	return item;
}

type UserKey = keyof IUser;
function getDefaultValue(user: IUser, key: UserKey, value: IUser[UserKey]) {
	if (user.role === Role.sellerAdmin) {
		// @ts-ignore
		return user[key]?._id ? user[key]?._id : user[key];
	}

	return value;
}

export const CreateEditUserForm: React.FC<Props> = ({
	modal,
	setModal,
	user,
	onSuccess,
}) => {
	const groups = useUserGroups();
	const vendors = useVendors();
	const { user: currentUser } = useUser();

	const {
		watch,
		register,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors },
		reset,
	} = useForm<CreateEditUser>({
		// @ts-ignore
		resolver: zodResolver(CreateEditUserInput),
		defaultValues: {
			_id: user?._id,
			username: user?.username || '',
			email: user?.email || '',
			password: user?.password || '',
			// @ts-ignore
			role: user?.role || getDefaultValue(currentUser, 'role', USER_TYPES[0].role),
			vendorPermissions: user?.vendorPermissions || [],
			isActive: user ? user.isActive : true,
			// @ts-ignore
			group: user?.group?._id || user?.group || getDefaultValue(currentUser, 'group', ''),
			permissions: user?.permissions || [],
			// @ts-ignore
			seller: user?.seller?._id || user?.seller || '',

			// @ts-ignore
			isWortenWhiteLabel: user ? user.isWortenWhiteLabel : getDefaultValue(currentUser, 'isWortenWhiteLabel', true),
		},
	});

	const [type, setType] = useState<Type | undefined>(user ? getUserType(user)?.type : USER_TYPES[0].type);
	const [step, setStep] = useState((currentUser.role !== Role.admin && !user) ? 1 : 0);
	const [hide, setHide] = useState(false);
	const [resetPassword, setResetPassword] = useState(false);

	const isStep1Complete = useCallback(() => {
		return !(watch('role') === Role.user && !watch('seller')) && !(watch('role') === Role.sellerAdmin && !watch('group'));
	}, [watch('role'), watch('seller'), watch('group')]);

	const isStep2Complete = useCallback(() => {
		try {
			CreateEditUserInput.parse(getValues());
			return true;
		} catch (error) {
			console.error(error);
			return false;
		}
	}, [getValues, watch('email'), watch('username'), watch('password')]);

	const step1Fields = useMemo(() => (
		<div className="width100">
			<RoleRequired role={Role.admin}>
				<Select
					label="Select the user type:"
					options={[{ label: 'Select type', value: '' }, ...USER_TYPES.map(x => ({ label: x.type, value: x.type }))]}
					onChange={(e) => {
						const item = USER_TYPES.find(t => t.type === e.target.value)!;
						setValue('role', item.role);
						setType(item.type);

						// Reset previous values
						setValue('group', '');
						setValue('seller', '');
						setValue('isWortenWhiteLabel', false);

						if (item.type === 'Worten White Label User') {
							setValue('group', groups.data?.find(x => x.name === 'Worten White Label')?._id!);
							setValue('isWortenWhiteLabel', true);
						}
					}}
					value={type}
					required
				/>

				{type === 'Partner' && (
					<Select
						label="Select the partner group:"
						options={[{ label: 'Select partner group', value: '' }, ...groups.data!.map(x => ({ label: x.name, value: x._id }))]}
						onChange={(e) => {
							setValue('group', e.target.value);
						}}
						value={watch('group')}
						required
					/>
				)}

				{type === 'Seller User' && (
					<Select
						label="Select the seller this user belongs to:"
						options={[{ label: 'Select seller', value: '' }, ...vendors.data!.map((x: any) => ({ label: x.name, value: x._id }))]}
						onChange={(e) => {
							setValue('seller', e.target.value);
						}}
						value={watch('seller')}
						required
					/>
				)}
			</RoleRequired>
		</div>
	), [groups.data, setValue, type, vendors.data, watch, watch('seller'), watch('group'), watch('isWortenWhiteLabel')]);

	const step2Fields = useMemo(() => (
		<div className="width100 in-column align-start">
			<span style={{ color: '#000', textAlign: 'left' }} className="mb1">Email:</span>
			<input
				type="email"
				className="formEdit__input"
				required
				{...register('email')}
				autoComplete="new-password"
				placeholder="eg.: johndoe@zeoos.com"
			/>

			<span style={{ color: '#000', textAlign: 'left' }} className="mb1">Username:</span>
			<input
				type="text"
				className="formEdit__input"
				required
				{...register('username')}
				autoComplete="new-password"
				placeholder="eg.: John Doe"
			/>

			<span style={{ color: '#000', textAlign: 'left' }} className={"mb1" + (user ? ' pointer' : '')} onClick={() => user && setResetPassword(p => !p)}>{user ? 'Click to Reset Password' : 'Password:'}</span>

			{(!user || (user && resetPassword)) && <div className="width100 in-row align-center justify-center mb2">
				<input
					type={hide ? "text" : "password"}
					className="formEdit__input"
					style={{ marginBottom: "0px" }}
					required
					{...register('password')}
					autoComplete="new-password"
					placeholder="Minimum 8 characters"
					minLength={8}
				/>
				<img
					onClick={() => setHide(p => !p)}
					className="ml2 pointer"
					src="/icons/password-hide.svg"
					alt=""
				/>
			</div>}

			<div className="in-row align-center">
				<label htmlFor="isActive">
					<Tooltip title='Indicates if user can log in'>
						<span style={{ color: '#000', textAlign: 'left' }} className="mr3 pointer">
							Status:
						</span>
					</Tooltip>
				</label>

				<AntSwitch
					id='isActive'
					defaultChecked={watch('isActive')}
					onChange={e => setValue('isActive', e.target.checked)}
				/>
			</div>
		</div>
	), [user, hide, register, setValue, watch, resetPassword]);

	const handleClose = useCallback(() => {
		reset();
		setModal(false);
	}, [reset, setModal]);

	return (
		<Modal
			onModalClose={() => { }}
			isOpened={modal}
		>
			<form
				autoComplete="new-password"
				className="formUser"
				// @ts-ignore
				onSubmit={handleSubmit(async ({ _id, ...data }) => {
					// @ts-ignore
					data.seller = data.seller || null;
					// @ts-ignore
					data.group = data.group || null;

					try {
						if (user) {
							if (data.password && data.password.length < 8) {
								// @ts-ignore
								delete data.password;
							}

							// @ts-ignore
							await UserController.updateUser(user._id, data);
						} else {
							// @ts-ignore
							await UserController.createUser(data);
						}

						// @ts-ignore
						onSuccess(data);
						toast.success(`The user is successfully ${user ? 'updated' : 'created'}!`);
						handleClose();
					} catch (error) {
						console.error(error);
						toast.error('Whoops... Something went wrong');
					}
				})}
			>
				<div className="user-title-sticky ml3 mr3" style={{ width: 'auto' }}>
					<div className="table-modal-title-box m0">
						<div className="mapping-modal-title-text">{user ? 'Edit' : 'Create'} User</div>
					</div>
					<div className="table-modal-border"></div>
				</div>

				<RoleRequired role={Role.admin}>
					{!user && <Stepper activeStep={step} alternativeLabel>
						<Step index={0}>
							<StepLabel>Select User Type</StepLabel>
						</Step>

						<Step index={1}>
							<StepLabel>User Information</StepLabel>
						</Step>

						<Step index={2}>
							<StepLabel>Confirm</StepLabel>
						</Step>
					</Stepper>}
				</RoleRequired>

				<div className="mt4 ml3 mr3 in-column justify-between" style={{ height: user ? 524 : 412 }}>
					{user ? (
						<>
							<div className="width100">
								{step1Fields}
								{step2Fields}
							</div>

							<ButtonGroup
								cancelLabel="Cancel"
								submitLabel="Submit"
								doesSubmit
								canProceed
								onCancel={handleClose}
							/>
						</>
					) : (
						<>
							{step === 0 && (
								<>
									{step1Fields}

									<ButtonGroup
										cancelLabel="Cancel"
										submitLabel="Next"
										onCancel={handleClose}
										onSubmit={() => setStep(p => p + 1)}
										canProceed={isStep1Complete()}
									/>
								</>
							)}

							{step === 1 && (
								<>
									{step2Fields}

									{currentUser.role === Role.admin ? <ButtonGroup
										cancelLabel="Back"
										submitLabel="Next"
										onCancel={() => setStep(p => p - 1)}
										onSubmit={() => setStep(p => p + 1)}
										canProceed={isStep2Complete()}
									/> : (
										<ButtonGroup
											cancelLabel="Cancel"
											submitLabel="Submit"
											onCancel={handleClose}
											doesSubmit
											canProceed={isStep2Complete()}
										/>
									)}
								</>
							)}

							{step === 2 && (
								<>
									<div className="width100 in-column align-start mt4">
										<span style={{ color: '#000', textAlign: 'left' }} className="mb1">User Type: {type}</span>

										{type === 'Partner' && (
											<span style={{ color: '#000', textAlign: 'left' }} className="mb1">User Group: {groups.data?.find(x => x._id === watch('group'))?.name}</span>
										)}

										{type === 'Seller User' && (
											<span style={{ color: '#000', textAlign: 'left' }} className="mb1">User Seller:  {vendors.data?.find((x: any) => x._id === watch('seller'))?.name}</span>
										)}

										<span style={{ color: '#000', textAlign: 'left' }} className="mb1">Email: {watch('email')}</span>
										<span style={{ color: '#000', textAlign: 'left' }} className="mb1">Username: {watch('username')}</span>

										<Tooltip title='Copy password to clipboard'>
											<span
												onClick={() => {
													navigator.clipboard.writeText(watch('password')!);
													toast.success('Password copied to clipboard');
												}}
												style={{ color: '#000', textAlign: 'left' }}
												className="mb1 pointer"
											>
												Password: ********
											</span>
										</Tooltip>

										<span style={{ color: '#000', textAlign: 'left' }}>
											Status: {watch('isActive') ? 'Active' : 'Not Active'}
										</span>
									</div>

									<ButtonGroup
										cancelLabel="Back"
										submitLabel="Submit"
										doesSubmit
										canProceed
										onCancel={() => setStep(p => p - 1)}
									/>
								</>
							)}
						</>
					)}
				</div>
			</form>
		</Modal>
	);
};

const Select: React.FC<React.ComponentProps<'select'> & {
	options: { label: string, value: string; }[];
	label: string;
}> = ({ options, label, ...props }) => {
	return (
		<div className="width100 in-column items-start mb2">
			<span style={{ color: '#000', textAlign: 'left' }} className="mb1">
				{label}
			</span>

			<select
				className="main-select-bi width100"
				{...props}
			>
				{options.map((x, index) => (
					<option
						className="option-field-user"
						value={x.value}
						key={index}
					>
						{x.label}
					</option>
				))}
			</select>
		</div>
	);
};

const ButtonGroup: React.FC<{
	cancelLabel: string;
	submitLabel: string;
	onCancel?: React.MouseEventHandler<HTMLButtonElement>;
	onSubmit?: React.MouseEventHandler<HTMLButtonElement>;
	doesSubmit?: boolean;
	canProceed?: boolean;
}> = ({ cancelLabel, submitLabel, onCancel, onSubmit, doesSubmit, canProceed }) => {
	return (
		<div className="width100">
			<div className="table-modal-border"></div>
			<div className="width100 in-row justify-between">
				<button
					className="table-modal-form-button-cancel"
					onClick={onCancel}
				>
					{cancelLabel}
				</button>

				<button
					className="table-modal-form-button"
					type={doesSubmit ? "submit" : 'button'}
					onClick={onSubmit}
					disabled={!canProceed}
				>
					{submitLabel}
				</button>
			</div>
		</div>
	);
};

export const PermissionSelect: React.FC<{
	permissions: UserController.TPermission[];
	setPermissions: React.Dispatch<
		React.SetStateAction<UserController.TPermission[]>
	>;
	role: Role;
}> = ({ permissions, setPermissions, role }) => {
	const { user } = useUser();

	if (![Role.permissionBased, Role.sellerUser].includes(role)) {
		return <></>;
	}

	return (
		<>
			{UserController.PermissionOptions.filter(
				(p) =>
					user.role === Role.admin ||
					[
						UserController.PermissionScope.dashboard,
						// UserController.PermissionScope.platforms,
						UserController.PermissionScope.inventory,
						UserController.PermissionScope.orders,
						UserController.PermissionScope.billings,
						UserController.PermissionScope.pim,
					].includes(p.value)
			).map((scope) => (
				<div
					key={scope.key}
					className="in-row width100 mt0 pt0 pb0 align-center"
				>
					<span className="userm-edit-perm-text">{scope.label}</span>
					{Object.values(UserController.PermissionTypeOptions).map((type) => (
						<div key={type.key} className="in-row align-center">
							<label className="userm-edit-perm-type-text">{type.label}</label>
							<input
								type="checkbox"
								style={{
									width: 15,
									border: "1px solid #999999",
									height: 15,
									cursor: "pointer",
									background: "#55718A !important",
									marginRight: 20,
								}}
								checked={
									!!permissions?.find(
										(p) => p.scope === scope.value && p.type === type.value
									)
								}
								onChange={(e) =>
									setPermissions((prev) => {
										const newPermission = {
											scope: scope.value,
											type: e.target.checked && type.value,
										} as UserController.TPermission;
										const indexToEdit = permissions.findIndex(
											(p) => p.scope === newPermission.scope
										);
										if (indexToEdit !== -1) {
											return prev.map((p, index) =>
												index === indexToEdit ? newPermission : p
											);
										}
										return [...prev, newPermission];
									})
								}
							/>
						</div>
					))}
				</div>
			))}
		</>
	);
};
