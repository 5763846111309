import React, { useCallback, useContext, useEffect, useState } from "react";
import "./acompanhamento.css";
import "../tables.css";
import { ICountryM } from "../../country-management/CountryManagement";
import { CountriesContext } from "../../../context";
// import RoleRequired from "../../../utils/role-required/RoleRequired";
import { IMarketplace, IPaginatedRequest, IVendor, IZeoosOrder, Role } from "../../../../interfaces";
import { NavLink } from "react-router-dom";
import { getImagePath } from "../../../../utils";
import ImageWithFallback from "../../../ui/image-with-fallback";
import CircularPreloader from "../../../ui/circular-preloader/CircularPreloader";
import Paginator from "../../../ui/pagination";
import { AdditionalOrderFilter, OrderResponse } from "./Acompanhamento";

interface Props {
	orders: OrderResponse;
	setCurrentConfig: React.Dispatch<React.SetStateAction<AdditionalOrderFilter>>;
	currentConfig: AdditionalOrderFilter;
	loadProducts: boolean;
}

export function renderUnifiedOrderStatus(order: any) {
	const pickStatus = (statuses: string[]) => {
		for (const status of statuses) {
			if (status !== "") {
				return status;
			}
		}
		return null;
	};
	const status: any = pickStatus([
		order.status,
		order.sale_lengow_status,
		order.sale_shopify_status,
	]);
	const statusToName = (status: string) => {
		return status
			?.split("_")
			.map((s: string) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
			.join(" ");
	};
	return <div className={status}>{statusToName(status)}</div>;
}

export const TableAcompanhamento: React.FC<Props> = ({ orders, currentConfig, setCurrentConfig, loadProducts }) => {
	const countries = useContext(CountriesContext) as ICountryM[];

	const onChangePage = useCallback(
		async (config: IPaginatedRequest) => {
			setCurrentConfig((c) => {
				return {
					...c,
					...config,
				};
			});
		},
		[setCurrentConfig]
	);

	return (
		<div className="table-main-cont">
			<div className="table-main-title-cont">
				<div className="table-main-title-search-value-cont">
					<div className="table-search-box ml2">
						<form
							className="table-search-form"
							onSubmit={(e) => e.preventDefault()}
						>
							<input
								className="table-search-input"
								placeholder="Search order"
								onChange={(e) =>
									setCurrentConfig((conf) => ({
										...conf,
										search: e.target.value,
									}))
								}
								value={currentConfig.search}
							/>
							<img
								className="table-search-image"
								src="/icons/search.svg"
								width="25px;"
								alt=""
							/>
						</form>
					</div>
					<div className="table-value">
						{loadProducts ? (
							<CircularPreloader />
						) : (
							<span className="table-main-title-text">
								{orders?.total} pedidos
							</span>
						)}
					</div>
				</div>
				<div className="marketplace-pagination">
					<Paginator
						data={!loadProducts ? orders : {}}
						refetch={onChangePage as any}
					/>
				</div>
			</div>
			<div className="table-border-bottom"></div>
			<div style={{ height: "max-content" }} className="table-overflow">
				<table
					style={{ position: "sticky", zIndex: 101, top: "2px", width: "100%" }}
				>
					<tr
						style={{ marginTop: "-5px", width: "100%" }}
						className="table-results-title"
					>
						<th className="acompan-status">Status</th>
						<th className="acompan-order">Order ID</th>
						<th className="acompan-country">Platform</th>
						<th className="acompan-channel">Platform Name</th>
						<th className="acompan-date">Date</th>
						<th className="acompan-name">Customer Name</th>
						<th className="acompan-country">Country</th>
						<th className="acompan-amount">Amount</th>
					</tr>
				</table>
				<table className="table-results">
					{/* <thead>
						<tr className="table-results-title">
							<th className="acompan-status">Status</th>
							<th className="acompan-order">Order ID</th>
							<th className="acompan-country">Platform</th>
							<th className="acompan-channel">Platform Name</th>
							<th className="acompan-date">Date</th>
							<th className="acompan-name">Customer Name</th>
							<th className="acompan-country">Country</th>
							<th className="acompan-amount">Amount</th>
						</tr>
					</thead> */}
					<tbody>
						{orders?.orders?.map((item: any, index: number) => (
							<tr className="table-result-box" key={item._id}>
								<NavLink
									className="width100 in-row align-center"
									to={`/orders/${item._id}`}
								>
									<td className="acompan-status">
										{renderUnifiedOrderStatus(item)}
									</td>
									<td className="acompan-order">
										<div
											className="acompan-order-text"
											style={{ textDecoration: "none" }}
										>
											{item.order_id}
										</div>
									</td>
									<td className="acompan-country">
										<ImageWithFallback
											primarySrc={getImagePath(item.zeoosName)}
											fallbackSrc={getImagePath(item.marketplace?.toLowerCase())}
											alt=""
											width="60px"
										/>
									</td>
									<td className="acompan-channel">
										<div className="acompan-channel-text">{item.zeoosName}</div>
									</td>
									<td className="acompan-date">
										<div className="acompan-date-text">
											{item?.date?.slice(0, 10).replaceAll("-", "/")}
											{/* {moment(item.sale_date).format("MMM Do YY")} */}
										</div>
									</td>
									<td className="acompan-name">
										<div className="acompan-name-text">
											{item?.customer.name}
										</div>
									</td>
									<td className="acompan-country">
										{countries.find(
											(x: any) => item.customer?.country === x.name
										) ? (
											<img
												className="table-search-image"
												src={
													countries.find(
														(x: any) => item.customer?.country === x.name
													)?.iconUrl
												}
												width="32px;"
												alt=""
											/>
										) : (
											<span>{item.country}</span>
										)}
									</td>
									<td className="acompan-amount">
										<div className="acompan-amount-text">
											{item.price.toLocaleString("de-DE", {
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											})}{" "}
											€
										</div>
									</td>
									{/* <td className="acompan-mail">
									<img
										className="table-search-image pointer"
										src="/icons/acompan-mail.svg"
										width="32px;"
										alt=""
									/>
								</td> */}
								</NavLink>
							</tr>
						))}
					</tbody>
				</table>
			</div>
			{/* <div>
				<PaginationComponent
					data={results}
					rowsPerPage={rowsPerPage}
					setPaginatedData={setPaginatedData}
				/>
			</div> */}
		</div>
	);
};
