import axios from "axios";
import { getHeaders } from "../user";
import qs from "query-string";
import { UseQueryOptions, useQuery } from "react-query";
import { useMemo } from "react";
import { queryClient } from "../../components/App";
import { Category, PlatformRate, TaxRate } from "types/category";
import fileDownload from "js-file-download";
import type { PaginatedResponse } from "types/pagination";

async function getData<TRequest extends Record<string, any>, TResponse>(
  endpoint: string,
  query: TRequest
) {
  const res = await axios.get(
    `${endpoint}?${qs.stringify(query)}`,
    getHeaders()
  );
  return res.data as TResponse;
}

export function useData<TRequest extends Record<string, any>, TResponse>(
  endpoint: string,
  query: TRequest,
  config: any = {}
) {
  const key = useMemo(() => [endpoint, query], [endpoint, query]);
  return {
    ...useQuery(key, () => getData<TRequest, TResponse>(endpoint, query), {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      ...config,
    }),
    invalidate: () => queryClient.invalidateQueries(key),
  };
}

export function createUseDataHook<
  TRequest extends Record<string, any>,
  TResponse
>(endpoint: string) {
  return (query: TRequest, config: UseQueryOptions = {}) =>
    useData<TRequest, TResponse>(endpoint, query, config);
}

export const useCategories = createUseDataHook<
  { level?: number; code?: string },
  Category[]
>("/v2/categories");
export const useBrands = createUseDataHook<{ category?: string }, string[]>(
  "/v2/product-brands"
);

export const useBrandsNew = createUseDataHook<{}, {brand:string}[]>(
  "/v2/product/brands"
);

export const usePlatformRates = createUseDataHook<
  { level?: number; search?: string },
  { rates: PlatformRate[]; platforms: string[] }
>("/v2/categories/platform-rates");

export const useTaxRates = createUseDataHook<any, TaxRate[]>(
  "/v2/categories/tax-rates"
);

export const usePlatformRatesByMarketplaceIntegration = createUseDataHook<
  { marketplaceIntegration: string },
  PaginatedResponse<PlatformRate>
>("/v2/categories/platform-rates");

export async function getCategoryTemplate(code: string) {
  const res = await axios.get(`/v2/category/${code}/template`, {
    ...getHeaders(),
    responseType: "arraybuffer",
  });

  return fileDownload(res.data, `${code}.csv`);
}
export async function getManualTranslateTemplate(code: string) {
  const res = await axios.get(
    `/v2/category/${code}/manual_translation_template`,
    {
      ...getHeaders(),
      responseType: "arraybuffer",
    }
  );

  return fileDownload(res.data, `${code}.csv`);
}

export async function getMasterTemplate(skuIncluded: boolean = false) {
  try {
    const res = await axios.get(
      `/v2/category/master-template?skuIncluded=${skuIncluded}`,
      {
        ...getHeaders(),
        responseType: "arraybuffer",
      }
    );

    return fileDownload(res.data, `MasterTemplate.csv`);
  } catch (error: any) {
    console.log("Master Template Generation Error", error);
    throw new Error("Master Template Generation Error");
  }
}
export async function getMarketplaceTemplate(
  batchId: string,
  marketplace: string
) {
  try {
    const res = await axios.get(
      `/v2/download/batch-item/${marketplace}/${batchId}`,
      {
        ...getHeaders(),
        responseType: "blob",
      }
    );

    return fileDownload(res.data, `${batchId}-${marketplace}-template.xlsx`);
  } catch (error: any) {
    console.log("Marketplace Template Generation Error", error);
    throw new Error("Marketplace Template Generation Error");
  }
}

export function getCategoryLabel(category: Category) {
  return `[${category.code}]: ${category.name}`;
}

export function useCarrierReport(carrier: string, tracking: string) {
  return useData<any, string>(
    `/v2/carrier/${carrier}/report`,
    { tracking },
    {
      enabled: !!tracking,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      retry: false,
    }
  );
}
